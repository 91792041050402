import axios from "axios";
import {
  acLineLoss_actualPr_fail,
  acLineLoss_actualPr_request,
  acLineLoss_actualPr_sucess,
  asbuilt_document_fail,
  asbuilt_document_request,
  asbuilt_document_sucess,
  download_asbuilt_document_fail,
  download_asbuilt_document_request,
  download_asbuilt_document_sucess,
  download_projectOverview_image_fail,
  download_projectOverview_image_request,
  download_projectOverview_image_sucess,
  get_user_fail,
  GHI_GTI_data_fail,
  GHI_GTI_data_request,
  GHI_GTI_data_sucess,
  inverter_deviation_fail,
  inverter_deviation_request,
  inverter_deviation_sucess,
  inverter_efficiency_fail,
  inverter_efficiency_request,
  inverter_efficiency_sucess,
  inverter_mappingData_fail,
  inverter_mappingData_request,
  inverter_mappingData_sucess,
  inverter_specification_fail,
  inverter_specification_request,
  inverter_specification_sucess,
  net_contractual_shortfall_fail,
  net_contractual_shortfall_request,
  net_contractual_shortfall_sucess,
  net_normalised_shortfall_fail,
  net_normalised_shortfall_request,
  net_normalised_shortfall_sucess,
  new_project_detail_request,
  project_detail_fail,
  project_detail_request,
  project_detail_sucess,
  pvsyst_actual_temp_fail,
  pvsyst_actual_temp_request,
  pvsyst_actual_temp_sucess,
  pvsyst_contractual_energy_fail,
  pvsyst_contractual_energy_request,
  pvsyst_contractual_energy_sucess,
  pvsyst_GHI_normalised_fail,
  pvsyst_GHI_normalised_request,
  pvsyst_GHI_normalised_sucess,
  pvsyst_GII_normalised_fail,
  pvsyst_GII_normalised_request,
  pvsyst_GII_normalised_sucess,
  scbSmb_currentDetail_fail,
  scbSmb_currentDetail_request,
  scbSmb_currentDetail_sucess,
  scbSmb_mappingData_fail,
  scbSmb_mappingData_request,
  scbSmb_mappingData_sucess,
  scbsmb_monthly_loss_fail,
  scbsmb_monthly_loss_request,
  scbsmb_monthly_loss_reset,
  scbsmb_monthly_loss_sucess,
  user_logout_sucess,
  user_session_expired,
} from "../constants/dataConstants";
import {
  checkUserSession,
  convertMonthToNumber,
  endDayToTimeStamps,
  endMonthToTimeStamps,
  endYearToTimeStamps,
  getMonthValue,
  startDayToTimeStamps,
  startMonthToTimeStamps,
  startYearToTimeStamps,
} from "../constants/Utils";

axios.defaults.baseURL = "https://pvapm-backend.greenencopvapm.com";
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.withCredentials = true;
export const scbsmbLossMonthlyAction =
  (detail, dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;
      const { label, startDate, endDate } = getStartEndDateAndLabel(dateRange);

      let {
        inverter,
        block,
        sub_block,
        inverterType,
        scbsmb,
        scbsmbType,
        stack,
        heatmap
      } = detail;

      inverter = inverter?.split(" ")[1] || null;
      block = block?.split(" ")[1] || null;
      sub_block = sub_block?.split(" ")?.slice(-1)[0] || null;
      scbsmb = scbsmb?.split(" ")[1] || null;
      stack = stack?.split(" ")[1] || null;

      dispatch({ type: scbsmb_monthly_loss_request });

      const { data } = await axios.post(`/scbsmbLoss`, {
        inverter,
        block,
        sub_block,
        inverterType,
        scbsmb,
        scbsmbType,
        startDate,
        endDate,
        label,
        databaseStr,
        stack,
        heatmap
      });

      dispatch({
        type: scbsmb_monthly_loss_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: scbsmb_monthly_loss_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const inverterEfficiencyAction =
  (detail, dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { label, startDate, endDate } = getStartEndDateAndLabel(dateRange);
      let { inverter, block, sub_block, inverterType, stack } = detail;

      inverter = inverter?.split(" ")[1] || null;
      block = block?.split(" ")[1] || null;
      sub_block = sub_block?.split(" ")?.slice(-1)[0] || null;
      stack = stack?.split(" ")[1] || null;

      dispatch({ type: inverter_efficiency_request });

      const { data } = await axios.post(`/inverterEfficiency`, {
        inverter,
        block,
        sub_block,
        inverterType,
        startDate,
        endDate,
        label,
        databaseStr,
        stack,
      });

      dispatch({
        type: inverter_efficiency_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: inverter_efficiency_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const inverterDeviationAction =
  (detail, dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { label, startDate, endDate } = getStartEndDateAndLabel(dateRange);
      let { inverter, block, sub_block, inverterType, stack } = detail;

      inverter = inverter?.split(" ")[1] || null;
      block = block?.split(" ")[1] || null;
      sub_block = sub_block?.split(" ")?.slice(-1)[0] || null;
      stack = stack?.split(" ")[1] || null;
      dispatch({ type: inverter_deviation_request });

      const { data } = await axios.post(`/inverterDeviation`, {
        inverter,
        block,
        sub_block,
        inverterType,
        startDate,
        endDate,
        label,
        databaseStr,
        stack,
      });

      dispatch({
        type: inverter_deviation_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: inverter_deviation_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const projectDetailAction =
  (userName, projectStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;
      dispatch({ type: new_project_detail_request });
      dispatch({ type: project_detail_request });

      const { data } = await axios.post("/projectDetail", {
        userName,
        databaseStr: projectStr,
      });
      dispatch({
        type: project_detail_sucess,
        payload: data?.projectDetail || null,
      });
    } catch (error) {
      dispatch({
        type: project_detail_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const inverterSpecificationAction =
  (databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;
      dispatch({ type: inverter_specification_request });

      const { data } = await axios.post("/inverterSpecification", {
        databaseStr,
      });
      dispatch({
        type: inverter_specification_sucess,
        payload: data?.inverterSpecification || null,
      });
    } catch (error) {
      dispatch({
        type: inverter_specification_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const scbSmbCurrentDetailAction = (databaseStr) => async (dispatch) => {
  try {
    if (!checkUserSession(dispatch)) return;
    dispatch({ type: scbSmb_currentDetail_request });

    const { data } = await axios.post("/scbSmbCurrent", {
      databaseStr,
    });

    dispatch({
      type: scbSmb_currentDetail_sucess,
      payload: data?.scbSmbCurrentDetail || null,
    });
  } catch (error) {
    dispatch({
      type: scbSmb_currentDetail_fail,
      payload: error?.response?.data?.error || error.message,
    });
  }
};

export const scbSmbMappingArrayAction = (databaseStr) => async (dispatch) => {
  try {
    if (!checkUserSession(dispatch)) return;
    dispatch({ type: scbSmb_mappingData_request });

    const { data } = await axios.post("/scbSmbMappingArray", {
      databaseStr,
    });
    dispatch({
      type: scbSmb_mappingData_sucess,
      payload: data?.scbSmbMapping || null,
    });
  } catch (error) {
    dispatch({
      type: scbSmb_mappingData_fail,
      payload: error?.response?.data?.error || error.message,
    });
  }
};

export const inverterMappingArrayAction = (databaseStr) => async (dispatch) => {
  try {
    if (!checkUserSession(dispatch)) return;
    dispatch({ type: inverter_mappingData_request });

    const { data } = await axios.post("/inverterMappingArray", {
      databaseStr,
    });

    dispatch({
      type: inverter_mappingData_sucess,
      payload: data?.inverterMapping || null,
    });
  } catch (error) {
    dispatch({
      type: inverter_mappingData_fail,
      payload: error?.response?.data?.error || error.message,
    });
  }
};

export const GHIGTIAction = (dateRange, databaseStr) => async (dispatch) => {
  try {
    if (!checkUserSession(dispatch)) return;

    const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);
    dispatch({ type: GHI_GTI_data_request });

    const { data } = await axios.post(`/GHIGTI`, {
      startDate,
      endDate,
      label,
      databaseStr,
    });

    dispatch({
      type: GHI_GTI_data_sucess,
      payload: data?.result || null,
    });
  } catch (error) {
    dispatch({
      type: GHI_GTI_data_fail,
      payload: error?.response?.data?.error || error.message,
    });
  }
};

export const pvsystActualTempAction =
  (dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);

      dispatch({ type: pvsyst_actual_temp_request });

      const { data } = await axios.post(`/pvsystActualTemp`, {
        startDate,
        endDate,
        label,
        databaseStr,
      });

      dispatch({
        type: pvsyst_actual_temp_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: pvsyst_actual_temp_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const pvsystContractualEnergyAction =
  (dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);

      dispatch({ type: pvsyst_contractual_energy_request });

      const { data } = await axios.post(`/pvsystContractualEnergy`, {
        startDate,
        endDate,
        label,
        databaseStr,
      });

      dispatch({
        type: pvsyst_contractual_energy_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: pvsyst_contractual_energy_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const netNormalisedEnergyShortfallAction =
  (dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);

      dispatch({ type: net_normalised_shortfall_request });

      const { data } = await axios.post(`/netNormalisedEnergyShortfall`, {
        startDate,
        endDate,
        label,
        databaseStr,
      });

      dispatch({
        type: net_normalised_shortfall_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: net_normalised_shortfall_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const netContractualEnergyShortfallAction =
  (dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);

      dispatch({ type: net_contractual_shortfall_request });

      const { data } = await axios.post(`/netContractualEnergyShortfall`, {
        startDate,
        endDate,
        label,
        databaseStr,
      });

      dispatch({
        type: net_contractual_shortfall_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: net_contractual_shortfall_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const acLineLossActualPrAction =
  (dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);

      dispatch({ type: acLineLoss_actualPr_request });

      const { data } = await axios.post(`/acLineLossActualPr`, {
        startDate,
        endDate,
        label,
        databaseStr,
      });

      dispatch({
        type: acLineLoss_actualPr_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: acLineLoss_actualPr_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const pvsystGHINormalisedAction =
  (dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);

      dispatch({ type: pvsyst_GHI_normalised_request });

      const { data } = await axios.post(`/pvsystGHINormalised`, {
        startDate,
        endDate,
        label,
        databaseStr,
      });

      dispatch({
        type: pvsyst_GHI_normalised_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: pvsyst_GHI_normalised_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const pvsystGIINormalisedAction =
  (dateRange, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      const { startDate, endDate, label } = getStartEndDateAndLabel(dateRange);

      dispatch({ type: pvsyst_GII_normalised_request });

      const { data } = await axios.post(`/pvsystGIINormalised`, {
        startDate,
        endDate,
        label,
        databaseStr,
      });

      dispatch({
        type: pvsyst_GII_normalised_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: pvsyst_GII_normalised_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const getAsbuiltDocuments =
  (category, name, databaseStr) => async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      dispatch({ type: asbuilt_document_request });

      const { data } = await axios.post(`/asbuiltDocuments`, {
        category,
        name,
        projectName: databaseStr,
        databaseStr,
      });
      dispatch({
        type: asbuilt_document_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: asbuilt_document_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const downloadAsbuiltDocuments =
  ({ name, category, projectName, fileName, databaseStr }) =>
  async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;

      dispatch({ type: download_asbuilt_document_request });

      const { data } = await axios.post(
        `/asbuiltDocuments/download`,
        {
          name,
          category,
          projectName,
          fileName,
          databaseStr,
        },
        { responseType: "blob" }
      );
      dispatch({
        type: download_asbuilt_document_sucess,
        payload: { data: data, fileName } || null,
      });
    } catch (error) {
      dispatch({
        type: download_asbuilt_document_fail,
        payload: "Downloading file Faild",
      });
    }
  };

export const downloadProjectOverviewImgAction =
  ({ fileName, databaseStr }) =>
  async (dispatch) => {
    try {
      if (!checkUserSession(dispatch)) return;
      dispatch({ type: download_projectOverview_image_request });
      const { data } = await axios.post(
        `/projectOverviewImage/download`,
        {
          databaseStr,
          fileName,
        },
        { responseType: "blob" }
      );

      dispatch({
        type: download_projectOverview_image_sucess,
        payload: data || null,
      });
    } catch (error) {
      dispatch({
        type: download_projectOverview_image_fail,
        payload: "Image Not Found",
      });
    }
  };

const getStartEndDateAndLabel = (dateRange) => {
  let label;
  let startDate, endDate;
  if (dateRange["year"] && dateRange["endDataValue"]) {
    startDate = startYearToTimeStamps(dateRange["startDataValue"]);
    endDate = endYearToTimeStamps(dateRange["endDataValue"]);
    label = "Year";
  } else if (dateRange["month"] && dateRange["endDataValue"]) {
    startDate = startMonthToTimeStamps(
      dateRange["yearDataValue"],
      convertMonthToNumber(dateRange["startDataValue"])
    );
    endDate = endMonthToTimeStamps(
      dateRange["yearDataValue"],
      convertMonthToNumber(dateRange["endDataValue"])
    );
    label = "Month";
  } else if (dateRange["day"] && dateRange["endDataValue"]) {
    startDate = startDayToTimeStamps(
      dateRange["yearDataValue"],
      convertMonthToNumber(dateRange["monthDataValue"]),
      dateRange["startDataValue"]
    );
    endDate = endDayToTimeStamps(
      dateRange["yearDataValue"],
      convertMonthToNumber(dateRange["monthDataValue"]),
      dateRange["endDataValue"]
    );
    label = "Day";
  }

  return {
    startDate,
    endDate,
    label,
  };
};
