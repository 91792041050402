import React, { useEffect, useState } from "react";

const DiagonisticGuard = ({ children }) => {
  const [loadChild, setLoadChild] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadChild(true)
    }, 2000);

  }, []);

  // return loadChild? children:<SpinLoader/>
  return  children
};

export default DiagonisticGuard;
