import React, { useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { scbSmbCurrentDetailAction } from "../../actions/energyAction";
import FetchProjectDetailLoader from "../../components/Loaders/FetchProjectDetailLoader";

/* ###  String/SMB Details Component  ### */
const SmbDetails = ({ databaseStr }) => {
  const { scbSmbCurrentArray, scbSmbCurrentLoader, projectDetail } =
    useSelector((state) => state.projectDetail);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!scbSmbCurrentArray) {
      dispatch(scbSmbCurrentDetailAction(databaseStr));
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <h2 style={{ textAlign: "center", padding: "2vmin 0" }}>
        {projectDetail?.plantDetail?.string || "SCB"} And String Details
      </h2>
      {scbSmbCurrentLoader ? (
        <div
          style={{
            minHeight: "350px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FetchProjectDetailLoader
            title={`Fetching ${
              projectDetail?.plantDetail?.string || "SCB"
            }Current Detail`}
          />
        </div>
      ) : !scbSmbCurrentArray ? (
        <div
          style={{
            minHeight: "350px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>
            {projectDetail?.plantDetail?.string || "SCB"} And String Detail Not
            Available
          </h3>
        </div>
      ) : scbSmbCurrentArray?.length > 0 ? (
        <TableContainer
          sx={{ padding: "0vmax 2vmin", width: "100%", whiteSpace: "nowrap" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#edeaea" }}>
                <TableCell style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
              {    "Block"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Inverter
                </TableCell>
                {scbSmbCurrentArray["0"]?.stackNo && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                  >
                    Stack
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  {projectDetail?.plantDetail?.string} No
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Module (Wp)
                </TableCell>
              {  projectDetail?.plantDetail?.string!=="STRING" &&
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Total String
                </TableCell>
}
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Module/String
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                 {
                  projectDetail?.plantDetail?.string==="STRING"?"Impp of Each String":"Impp of Each String Before Y-Connector"
                 } 
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Each {projectDetail?.plantDetail?.string} Current (A)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Voltage (V)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* blockNo(pin):"Sub-Array-1"
                        inverterNo */}

              {scbSmbCurrentArray?.map((value, index, arr) => (
                <TableRow key={index}>
                  {/* {(index === 0 && <TableCell align="left" style={{ fontSize: "2vmin" }}>{value.blockNo}</TableCell>) ||
                                    (index < projectDetail?.plantDetail?.scbsmbStringArray?.length && arr[index].blockNo !== arr[index - 1].blockNo && <TableCell align="left" style={{ fontSize: "2vmin" }}>{arr[index].blockNo}</TableCell>) ||
                                    <TableCell align="left" style={{ fontSize: "2vmin" }}>
                                        <p></p>
                                    </TableCell>
                                } */}
                  <TableCell align="left" style={{ fontSize: "2vmin" }}>
                    {index > 0
                      ? arr[index]["blockNo"] != arr[index - 1]["blockNo"]
                        ? value["blockNo"]
                        : null
                      : value["blockNo"]}
                  </TableCell>
                  {/* {(index === 0 && <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.inverterNo} ({value?.inverterType})</TableCell>) ||
                                    (index < projectDetail?.plantDetail?.scbsmbStringArray?.length && arr[index].inverterNo !== arr[index - 1].inverterNo && <TableCell align="center" style={{ fontSize: "2vmin" }}>{arr[index].inverterNo} ({value?.inverterType})</TableCell>) ||
                                    <TableCell align="center" style={{ fontSize: "2vmin" }}>
                                        <p></p>
                                    </TableCell>
                                } */}
                  <TableCell align="left" style={{ fontSize: "2vmin" }}>
                    {(index > 0 &&
                      arr[index]["blockNo"] != arr[index - 1]["blockNo"]) ||
                    index == 0
                      ? `${value["inverterNo"]} (${value?.inverterType})`
                      : index > 0 &&
                        arr[index - 1]["inverterNo"] != arr[index]["inverterNo"]
                      ? `${value["inverterNo"]} (${value?.inverterType})`
                      : null}
                  </TableCell>
                  {value?.stackNo && (
                    <TableCell align="left" style={{ fontSize: "2vmin" }}>
                      {index > 0
                        ? arr[index]["stackNo"] != arr[index - 1]["stackNo"]
                          ? value["stackNo"]
                          : null
                        : value["stackNo"]}
                    </TableCell>
                  )}

                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {
                      value[
                        `${projectDetail?.plantDetail?.string?.toLowerCase()}No`
                      ]
                    }
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value.moduleWP}
                  </TableCell>
                {
                  projectDetail?.plantDetail?.string!=="STRING" &&
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value.totalString}
                  </TableCell>
                }  
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value.modulePerString}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value.imppEachString}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {
                      value[
                        `${projectDetail?.plantDetail?.string?.toLowerCase()}CurrentEach`
                      ]
                    }
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value.voltage}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            minHeight: "350px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>
            {projectDetail?.plantDetail?.string || "SCB"} And String Detail Not
            Available
          </h3>
        </div>
      )}
    </div>
  );
};

export default SmbDetails;
