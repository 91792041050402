// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speedometer .segment-value{
    font-weight: 1200 !important;
    
}`, "",{"version":3,"sources":["webpack://./src/components/speedChart.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;;AAEhC","sourcesContent":[".speedometer .segment-value{\r\n    font-weight: 1200 !important;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
