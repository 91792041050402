import React, {  useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomizeTootip from "../../components/CustomizeTootip";
import {  colors3 } from "../../colors/color";
import CustomizeLegend from "../../components/CustomizeLegend";


import Printer2 from "../../components/Printer2";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  inverterDeviationAction,
} from "../../actions/energyAction";
import "./inverterEfficiency.css";
import GraphLoader from "../../components/GraphLoader";

const InverterDeviation = ({
  selectedLebel,
  scbsmbDetail,
  databaseStr,
}) => {
  const { inverterDeviation = [], loading } = useSelector(
    (state) => state.inverterDeviation
  );
    const {  projectDetail } = useSelector((state) => state.projectDetail);
  
  const dispatch = useDispatch();
  const [tooltipAdjust, setTooltipAdjust] = useState({ x: null, y: null });

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showEfficiency, setShowEfficiency] = useState({});
  /* ###   Reference to download graph data in CSV format   ### */


  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef = useRef(null);

  const minMaxRange = useMemo(() => {
    if (inverterDeviation && inverterDeviation?.length > 0) {
      let obj = {};
      [
        ...new Set(
          inverterDeviation?.flatMap(Object.keys).filter((e) => e !== "Date") || []
        ),
      ].forEach((e) => {
      
          obj[e] = true;
        
      });
      obj["all"] = true;
      setShowEfficiency(obj);
      return {
        min: inverterDeviation?.reduce((acc, curr, index) => {
          Object.keys(curr).forEach((e) => {
            if (e !== "Date") {
              acc = Math.min(curr[e], acc);
            }
          });
          return acc;
        }, Infinity),
        max: inverterDeviation?.reduce((acc, curr, index) => {
          Object.keys(curr).forEach((e) => {
            if (e !== "Date") {
              acc = Math.max(curr[e], acc);
            }
          });
          return acc;
        }, -Infinity),
      };
    }
    return {};
  }, [inverterDeviation]);

  useEffect(() => {
    if (selectedLebel?.endDataValue) {
      dispatch(
        inverterDeviationAction(scbsmbDetail, selectedLebel, databaseStr)
      );
    }
  }, [dispatch, selectedLebel?.endDataValue]);

  useEffect(() => {
      dispatch(inverterDeviationAction(scbsmbDetail, {}, databaseStr));
  }, [scbsmbDetail]);

 

  return (
    <>
      <>
        <Grid
          container
          style={{
            minHeight: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0 0 1vmax 0",
          }}
        >
          <Grid
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1vmax",
              padding: "1vmax",
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                position: "relative",
                backgroundColor: "white",
                padding: "1vmax",
                overflow: "auto",
              }}
            >
             
              <div
                style={{
                  padding: "0.5rem 1.5rem",
                  position: "sticky",
                  left: "0",
                  right: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  zIndex: "1",
                }}
              >
                <h3>
                  Inverter Deviation (
                  {scbsmbDetail?.block && scbsmbDetail?.inverter
                    ? ((projectDetail?.plantDetail?.block_name || "BL"))+"-"+scbsmbDetail.block?.split(" ")[1] +
                    (scbsmbDetail?.sub_block? ("-"+projectDetail?.plantDetail?.sub_block_name+"-"+scbsmbDetail?.sub_block?.split(" ")?.splice(-1)):"") +
                       
                      "-INV-" +
                      (scbsmbDetail.inverter !== "All Inverter"
                        ? scbsmbDetail.inverter?.split(" ")[1]
                        : "All") +
                      (scbsmbDetail?.stack
                        ? "-STACK-" + scbsmbDetail.stack?.split(" ")[1]
                        : "")
                    : scbsmbDetail?.block
                    ? ((projectDetail?.plantDetail?.block_name || "BL")+"-")+ scbsmbDetail?.block?.split(" ")[1]+ (scbsmbDetail?.sub_block? ("-"+projectDetail?.plantDetail?.sub_block_name+"-"+scbsmbDetail?.sub_block?.split(" ")?.splice(-1)):"")  + "-INV-All"
                    : "All Inverters"}
                  ){" "}
                </h3>
                <Printer2
                 
                  
                  dataSetDetail={{data:inverterDeviation|| [],name:"Inverter Deviation",ref:graphRef,
                  refCurrent:graphRef.current?.container}}
                />
              </div>

              <div
                style={{
                  minWidth: "100%",
                  width: loading
                    ? "100%"
                    : inverterDeviation?.length > 0
                    ? inverterDeviation.length *
                        // Object.keys(inverterDeviation[0]).length*(window.innerWidth<500 && window.innerWidth>400?8:window.innerWidth<400?10:6)
                        [
                          ...new Set(
                            inverterDeviation?.flatMap(Object.keys).filter((e) => e !== "Date") || []
                          ),
                        ].length *
                        35 +
                      "px"
                    : "100%",
                }}
                className="inverter-efficiency-responsive-container"
              >
                <ResponsiveContainer
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "0",
                        left: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <GraphLoader />
                    </div>
                  ) :inverterDeviation?.length>0? (
                    <ComposedChart
                      style={{ overflow: "hidden" }}
                      onMouseMove={(e) => {
                        if (e.isTooltipActive) {
                          setTooltipAdjust({
                            x:
                              // e.chartX>e.activeCoordinate.x?e.activeCoordinate.x:
                              e.chartX > 20 ? e.chartX - 20 : e.chartX,
                            y: e.chartY > 150 ? 150 : e.chartY,
                          });
                        }
                      }}
                      barSize={30}
                      // maxBarSize={50}
                      // barGap={5}
                      data={inverterDeviation || []}
                      margin={{
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5,
                      }}
                      // barCategoryGap={"1%"}
                      ref={graphRef}
                    >
                      <CartesianGrid stroke="grey" strokeWidth={0.3} />
                      <XAxis
                        dataKey="Date"
                        fontSize={"0.8rem"}
                        fontWeight={600}
                        tickLine={false}
                        axisLine={false}
                        tickMargin={5}
                      />
                      <YAxis
                        yAxisId="left-axis"
                        fontSize={"0.8rem"}
                        fontWeight={600}
                        domain={[
                          minMaxRange.max == minMaxRange.min
                            ? minMaxRange.min - 1
                            : minMaxRange.min -
                              (minMaxRange.max - minMaxRange.min) / 10,
                          minMaxRange.min == minMaxRange.max
                            ? minMaxRange.max + 1
                            : minMaxRange.max +
                              (minMaxRange.max - minMaxRange.min) / 10,
                        ]}
                        tickLine={false}
                        tickMargin={8}
                        // interval="preserveStartEnd"
                        tickFormatter={(tick) =>
                          Math.abs(minMaxRange.max - minMaxRange.min) <= 5
                            ? tick.toFixed(2)
                            : Math.floor(tick)
                        }
                      />
                      <Tooltip
                        position={tooltipAdjust}
                        content={
                          <CustomizeTootip
                            active={false}
                            payload={[]}
                            label={""}
                            TooltipValues={
                              inverterDeviation?.length > 0
                                ? [
                                  ...new Set(
                                    inverterDeviation?.flatMap(Object.keys).filter((e) => e !== "Date") || []
                                  ),
                                ].sort()
                                : []
                            }
                            showDataKey={true}
                          />
                        }
                      />

                      <Legend
                        content={
                          <CustomizeLegend
                            active={false}
                            payload={[]}
                            LegendValues={
                              inverterDeviation?.length > 0
                                ? [
                                  ...new Set(
                                    inverterDeviation?.flatMap(Object.keys).filter((e) => e !== "Date") || []
                                  ),
                                ].sort()
                                : []
                            }
                            data={showEfficiency}
                            setData={setShowEfficiency}
                            styles={{
                              fontSize: "1rem",
                            }}
                          />
                        }
                      />

                      {inverterDeviation?.length > 0 &&
                        [
                          ...new Set(
                            inverterDeviation?.flatMap(Object.keys).filter((e) => e !== "Date") || []
                          ),
                        ].sort().map((e, index) => {
                          if (e === "Date") return null;
                          return (
                            <Bar
                              key={index}
                              hide={showEfficiency[e] ? false : true}
                              dataKey={e}
                              fill={colors3[0]}
                              yAxisId="left-axis"
                            >
                              {
                                <LabelList
                                  dataKey={e}
                                  position="top"
                                  fontSize={"0.7rem"}
                                  fontWeight={600}
                                />
                              }
                            </Bar>
                          );
                        })}
                    </ComposedChart>
                  ):
            <h3>Data Not Available</h3>

                }
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
};
export default InverterDeviation;
