import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { inverterSpecificationAction } from "../../actions/energyAction";

import FetchProjectDetailLoader from "../../components/Loaders/FetchProjectDetailLoader";

/* ###  Inverter Details Component  ### */

const InverterDetails = ({ databaseStr,plantDetail }) => {
  const { inverterSpecificationArray, inverterSpecificationLoader } =
    useSelector((state) => state.projectDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (databaseStr && !inverterSpecificationArray) {
      dispatch(inverterSpecificationAction(databaseStr));
    }
  }, [databaseStr]);
  return (
    <div style={{ width: "100%" }}>
      <h2 style={{ textAlign: "center", padding: "2vmin 0" }}>
        Inverter Details
      </h2>
      {inverterSpecificationLoader ? (
        <div
        style={{
          minHeight: "350px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FetchProjectDetailLoader title={"Fetching inverter detail"} />
        </div>
      ) : !inverterSpecificationArray ? (
        <div
          style={{
            minHeight: "350px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>Inverter Detail Not Found</h3>
        </div>
      ) : inverterSpecificationArray?.length > 0 ? (
        <TableContainer
          sx={{ padding: "0vmax 2vmin", width: "100%", whiteSpace: "nowrap" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#edeaea" }}>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                 { "Block"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Inverter
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Inverter I/P Current (A)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Power DC (KW)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  Power AC (KW)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", fontSize: "2vmin" }}
                >
                  {/* Inverter Over Loading */}
                  DC:AC Overloading
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inverterSpecificationArray?.map((value, index, arr) => (
                <TableRow key={index}>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {index > 0
                      ? arr[index]["Block No"] != arr[index - 1]["Block No"]
                        ? value["Block No"]
                        : null
                      : value["Block No"]}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value["Inverter Number"]}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value["Inverter I/P current (A)"]}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value["Power (DC) KWp"]}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {value["Power (AC) KW"]}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "2vmin" }}>
                    {Number(
                      value["Power (DC) KWp"] / value["Power (AC) KW"]
                    ).toFixed(2)}
                    :1
                    {/* {value["Inverter Loading"]}% */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            minHeight: "350px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>Inverter Detail Not Available</h3>
        </div>
      )}
    </div>
  );
};

export default InverterDetails;
