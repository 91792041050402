import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PageURL from "../../constants/PageURL";
import { useDispatch, useSelector } from "react-redux";

const ProjectProtectedRoute = () => {
  const { projectDetail } = useSelector((state) => state.projectDetail);
  const location = useLocation();
  const dispatch = useDispatch();
  const serachParams = new URLSearchParams(location.search);

  if (location.pathname !== PageURL.PROJECT_DETAILS && !projectDetail) {
    
    return (
      <Navigate to={`${PageURL.PROJECT_DETAILS}?${serachParams.toString()}`} />
    );
  }
  return <Outlet />;
};

export default ProjectProtectedRoute;
