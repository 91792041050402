import React from 'react'
import './Loader.css'

const FetchProjectDetailLoader = ({title,width}) => {
  return (
    <div style={{display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center",gap:"0.2rem"}}>
    {title && <h5>{title}</h5>} 
    <div className='loader-3' style={{width:width || "30px"}} ></div>
    </div>

  )
}

export default FetchProjectDetailLoader