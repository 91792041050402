import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors3 } from "../../colors/color";
import CustomizeTootip from "../../components/CustomizeTootip";
import CustomizeLegend from "../../components/CustomizeLegend";
import "./prescritive.css";
import Printer2 from "../../components/Printer2";
import { useSelector } from "react-redux";

const colorData = [
  {
    name: "Module",
    Loss: 5.25,
    // fill: "#FF0000"
    fill: "#de425b",
  },
  {
    name: "Soiling",
    Loss: 3.5,
    // fill:"rgb(227, 94, 27)"
    fill: "#e18745",
  },
  {
    name: "Shadow",
    Loss: 1.3,
    // fill: "#FFA500"
    fill: "#dac767",
  },
  {
    name: "Resistance",
    Loss: 1,
    // fill:"rgb(223, 200, 51)"
    fill: "#78ab63",
  },
  {
    name: "Overheating",
    Loss: 1,
    // fill: "rgb(27, 84, 227)"
    // fill: "rgb(24, 134, 245)"
    // fill:"rgb(252, 237, 107)"
    // fill:"rgb(94, 216, 253)"
    fill: "#488f31",
  },
];
const defaultLoss = {
  module: {
    description:
      "There is a multiple of module defects have been identified with bypass diode dominating with __ impact on underperformance",
    loss: null,
  },
  soiling: {
    description:
      "We have observed a __ gap between the actual soiling loss and PVSYST model",
    loss: null,
  },
  shadow: {
    description:
      "There is __ gap in actual plant performance compared to PVSYST model",
    loss: null,
  },
  insulationRegistance: {
    description:
      "There are number of damage Y connector and DC string cable exposed to the sharp edge of thr module mountaining structure that require attention to avoid inverter tripping due to low insultion resistance",
    loss: null,
  },
  overHeatingDCString: {
    description:
      "IR inspection of the SMBs have indentified several overheating strings and Dc cable joint, which is causing derating of cables and could result into fire in high irradiance days",
    loss: null,
  },
};

const PrescriptiveLoss = ({ showCheckBox }) => {
  const { projectDetail = {} } = useSelector((state) => state.projectDetail);
  const [dataSet, setDataSet] = useState(null);
  const [showLoss, setShowLoss] = useState({
    all: true,
    Loss: true,
  });

  /* ###   Reference to download graph data in CSV format   ### */


  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef = useRef(null);
  useEffect(() => {
    const objData = projectDetail?.plantLoss || defaultLoss;
    const data = [];
    Object.keys(objData).forEach((key, index) => {
      if (showCheckBox[key]) {
        data.push({
          name: key,
          Loss: objData[key]?.loss,
          fill: colorData[index].fill || "#488f31",
        });
      }
    });
    setDataSet(data);
  }, [projectDetail, showCheckBox]);

  const graphData =
    useMemo(() => {
      return (
        dataSet?.reduce((acc, curr) => {
          acc.push({
            name: curr.name,
            LossPercentage: curr.Loss,
          });
          return acc;
        }, []) || []
      );
    }, [dataSet]) || [];

  /* ###   Function To Visible Selected Graph Values   ### */

  return (
    <>
      {" "}
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h3>Loss (%)</h3>
        <Printer2
          
          dataSetDetail={{ data: graphData, name: "Loss Flow Table" ,ref:graphRef,
            refCurrent:graphRef.current?.container}}
        />
      </div>
      <div
        className="prescriptive-responsive-container"
        style={{ width: "100%" }}
      >
        <ResponsiveContainer>
          <ComposedChart
            data={dataSet || []}
            ref={graphRef}
            margin={{
              right: 5,
              top: 5,
              bottom: 5,
              left: 5,
            }}
            barCategoryGap={"25%"}
          >
            <XAxis
              fontSize={"0.8rem"}
              interval={0}
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tickMargin={5}
            />
            <CartesianGrid stroke="grey" strokeWidth={0.2} />
            <YAxis
              type="number"
              fontSize={"0.8rem"}
              dataKey={() =>
                Math.ceil(
                  dataSet?.reduce(
                    (acc, curr, index) => (curr.Loss > acc ? curr.Loss : acc),
                    -Infinity
                  )
                ) + 1
              }
              // domain={[
              //   Math.floor(
              //     dataSet?.reduce(
              //       (acc, curr, index) => (curr.Loss < acc ? curr.Loss : acc),
              //       Infinity
              //     )
              //   ),
              //   "dataMax",
              // ]}
              tickLine={false}
              tickMargin={5}
            />
            <Legend
              content={
                <CustomizeLegend
                  active={false}
                  payload={[]}
                  label={""}
                  LegendValues={["Loss"]}
                  data={showLoss}
                  setData={setShowLoss}
                  styles={{ fontSize: "1rem" }}
                />
              }
            />
            <Tooltip
              content={
                <CustomizeTootip
                  active={false}
                  payload={[]}
                  label={""}
                  TooltipValues={["Loss"]}
                />
              }
              contentStyle={{ fontSize: "0.7rem" }}
            />
            {
              <Bar
                hide={showLoss.Loss ? false : true}
                dataKey="Loss"
                fill={colors3[0]}
                onTransitionEnd={"1s all"}
              >
                <LabelList
                  dataKey="Loss"
                  position="top"
                  fontSize={"1.8vmax"}
                  fontWeight={600}
                  fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                />
              </Bar>
            }
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PrescriptiveLoss;
