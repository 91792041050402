import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import SpeedChart from "../../../../components/SpeedChart";
import { saveAs } from "file-saver";
import SelectOptions from "./SelectOptions";
import { Grid } from "@mui/material";

const ExcessShortFall = ({ netContractualEnergyShortfall, loading,showPVsystEnergy }) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(null);

 const minMaxRange= useMemo(() => {
    if (
      netContractualEnergyShortfall &&
      netContractualEnergyShortfall?.length > 0
    ) {
      setSelectedDate(netContractualEnergyShortfall[0].name);
return{
  min1:netContractualEnergyShortfall?.reduce(
    (acc, curr, index) =>
      Math.min(acc, curr["shortFall_kwh"]) ,
    Infinity
  ),
  max1:netContractualEnergyShortfall?.reduce(
    (acc, curr, index) =>
       Math.max(acc, curr["shortFall_kwh"]) ,
    -Infinity
  ),
  min2:  Math.floor(netContractualEnergyShortfall?.reduce(
    (acc, curr, index) => Math.min(acc, curr["shortFall"]),
    Infinity
  )) ,
  max2: Math.ceil(netContractualEnergyShortfall?.reduce(
    (acc, curr, index) => Math.max(acc, curr["shortFall"]),
    -Infinity
  ) )


}
    }
    return{}
  }, [netContractualEnergyShortfall]);

  const [PVsystContractual,setPVsystContractual]=useState({shortFall_kwh:"shortFall_kwh",shortFall:"shortFall"})

  useEffect(()=>{
    if(showPVsystEnergy){
      setPVsystContractual({
        ...PVsystContractual,
        shortFall_kwh:"pvsystNetShortFall_kwh",shortFall:"pvsystNetEnergyshortFall"
  
      })
    }
 

  },[showPVsystEnergy])

  return (
    <Grid
    item
    xl={12}
    lg={12}
    md={12}
    sm={12}
    xs={12}
    sx={{
      position: "relative",
      backgroundColor: "#fff",
      padding: "1vmax",
      overflow: "auto",
    }}
    ref={graphRef}
  >
      <CSVLink
        data={netContractualEnergyShortfall?.reduce((acc, curr) => {
          acc.push({
            Date: curr["name"],
            ExcessShortfall: curr[PVsystContractual.shortFall_kwh],
            ExcessShortfallPercentage: curr[PVsystContractual.shortFall],
          });
          return acc;
        }, []) || []}
        filename="data.csv"
        className="hidden"
        ref={csvDownloadRef}
        target="_blank"
      />

      <div
        style={{
          padding: "0 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <div
          style={{
            padding: "0 1.5rem",
            position: "sticky",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <select
            name="level"
            // id="level"
            style={{
              padding: "1vmin 1.5vmin",
              textAlign: "center",
              fontWeight: "bolder",
              borderStyle: "solid",
              borderColor: "#ED7D31",
              borderWidth: "3px",
              fontSize: "1rem",
              backgroundColor: "#edeaea",
            }}
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
          >
            {/* ###  Monthly Value component for Gaugemeter ### */}

            <SelectOptions
              dataArray={
                netContractualEnergyShortfall?.map((e) => e.name) || []
              }
            />
          </select>
        </div>
        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() => {
            if(!graphRef.current) return null
            document.getElementById("hide_content").style.display = "none";
            exportComponentAsJPEG(
              graphRef,
              (document.getElementById("greenenco2").style.display = "block")
            );
            document.getElementById("greenenco2").style.display = "none";
          }}
          svgDownload={async () => {
            if(!graphRef.current)return null
            document.getElementById("hide_content").style.display = "none";
            const svgData = await saveToSvg(
              graphRef.current,
              (document.getElementById("greenenco2").style.display = "block")
            );
            document.getElementById("greenenco2").style.display = "none";

            saveAs(svgData, "gaugemeter.svg");
          }}
        />
      </div>
      <div className="speed-chart-container">
        <SpeedChart
          title={`Excess(+)/Shortfall(-) (KWh)`}
          minValue={
            minMaxRange.min1 ||0
          }
          maxValue={
            minMaxRange.max1 ||0
          }
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.name === selectedDate
            )?.[PVsystContractual.shortFall_kwh] || 0
          }
        />
        <SpeedChart
          title={`Excess(+)/Shortfall(-) (%)`}
          minValue={
            minMaxRange.min2 ||0
          }
          maxValue={
            minMaxRange.max2 ||0
          }
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.name === selectedDate
            )?.[PVsystContractual.shortFall] || 0
          }
        />
      </div>
      <div id="greenenco2" style={{ position: "relative", display: "none" }}>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "0 0.5rem 0.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "1.2rem" }}>&#169;</span>
          <span>GreenEnco</span>
        </div>
      </div>
      </Grid>
  );
};

export default ExcessShortFall;
