import React from "react";
import { Bar, ComposedChart, XAxis, ResponsiveContainer } from "recharts";
import GraphLoader from "../../components/GraphLoader";

const PvsystAmbientTemp = ({
  loading,
  pvsystActualTemp,
  setProgressValueAnimate,
  progressValueAnimate,
}) => {
  return (
    <div className="detail_overview_1">
      {loading ? (
        <div style={{ height: "100%", width: "100%" }}>
          <GraphLoader />
        </div>
      ) : !pvsystActualTemp || pvsystActualTemp?.length == 0 ? (
        <div style={{ height: "100%", width: "100%", fontSize: "2vmin" }}>
          <p>PVsyst Ambient Temp (℃) and Actual Ambient Temp (℃)</p>
          <p>Data Not Available</p>
        </div>
      ) : (
        <>
          <div>
            <div>
              <div>
                <progress
                  className="progress_4"
                  style={{
                    accentColor: "green",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress7
                      ? "3px solid red"
                      : "none",
                  }}
                  value={parseFloat(
                    pvsystActualTemp?.reduce(
                      (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                      0
                    ) / pvsystActualTemp?.length || 0
                  ).toFixed(2)}
                  max={100}
                />
              </div>
              <div>
                <span>PVsyst Ambient Temp (℃)</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      parseFloat(
                        pvsystActualTemp?.reduce(
                          (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                          0
                        ) / pvsystActualTemp?.length || 0
                      ).toFixed(2) - 3
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span>
                  {Number( parseFloat(
                    pvsystActualTemp?.reduce(
                      (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                      0
                    ) / pvsystActualTemp?.length || 0
                  ).toFixed(2))}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_5"
                  style={{
                    accentColor: "green",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress8
                      ? "3px solid black"
                      : "none",
                  }}
                  value={
                    parseFloat(
                      pvsystActualTemp?.reduce(
                        (acc, curr) => acc + Number(curr["Actual Ambient Temp"]),
                        0
                      ) / pvsystActualTemp?.length || 0
                    ).toFixed(2) || 0
                  }
                  max={100}
                />
              </div>
              <div>
                <span>Actual Ambient Temp (℃)</span>
                {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      parseFloat(
                        pvsystActualTemp?.reduce(
                          (acc, curr) => acc + Number(curr["Actual Ambient Temp"]),
                          0
                        ) / pvsystActualTemp?.length || 0
                      ).toFixed(2) - 3 || 0
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span>
                  {Number( parseFloat(
                    pvsystActualTemp?.reduce(
                      (acc, curr) => acc + Number(curr["Actual Ambient Temp"]),
                      0
                    ) / pvsystActualTemp?.length || 0
                  ).toFixed(2) || 0)}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_3"
                  style={{ accentColor: "blue" }}
                  value={Math.abs(
                    parseFloat(
                      ((parseFloat(
                        pvsystActualTemp?.reduce(
                          (acc, curr) => acc + Number(curr["Actual Ambient Temp"]),
                          0
                        ) / pvsystActualTemp?.length || 0
                      ).toFixed(2) -
                        parseFloat(
                          pvsystActualTemp?.reduce(
                            (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                            0
                          ) / pvsystActualTemp?.length || 0
                        ).toFixed(2)) *
                        100) /
                        parseFloat(
                          pvsystActualTemp?.reduce(
                            (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                            0
                          ) / pvsystActualTemp?.length || 0
                        ).toFixed(2)
                    ).toFixed(2) || 0
                  )}
                  max="100"
                />
              </div>
              <div>
                <span>Excess(+)/ShortFall(-) (%)</span>
                {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      Math.abs(
                        parseFloat(
                          ((parseFloat(
                            pvsystActualTemp?.reduce(
                              (acc, curr) => acc + Number(curr["Actual Ambient Temp"]),
                              0
                            ) / pvsystActualTemp?.length || 0
                          ).toFixed(2) -
                            parseFloat(
                              pvsystActualTemp?.reduce(
                                (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                                0
                              ) / pvsystActualTemp?.length || 0
                            ).toFixed(2)) *
                            100) /
                            parseFloat(
                              pvsystActualTemp?.reduce(
                                (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                                0
                              ) / pvsystActualTemp?.length || 0
                            ).toFixed(2)
                        ).toFixed(2)
                      ) - 3
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span>
                  {Number( parseFloat(
                    ((parseFloat(
                      pvsystActualTemp?.reduce(
                        (acc, curr) => acc + Number(curr["Actual Ambient Temp"]),
                        0
                      ) / pvsystActualTemp?.length || 0
                    ).toFixed(2) -
                      parseFloat(
                        pvsystActualTemp?.reduce(
                          (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                          0
                        ) / pvsystActualTemp?.length || 0
                      ).toFixed(2)) *
                      100) /
                      parseFloat(
                        pvsystActualTemp?.reduce(
                          (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                          0
                        ) / pvsystActualTemp?.length || 0
                      ).toFixed(2)
                  ).toFixed(2) || 0)}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>
                {Number( parseFloat(
                  pvsystActualTemp?.reduce(
                    (acc, curr) => acc + Number(curr["PVsyst Ambient Temp"]),
                    0
                  ) / pvsystActualTemp?.length || 0
                ).toFixed(2) || 0)}
              </span>
              <span>PVsyst Ambient Temp (℃)</span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={pvsystActualTemp || []}
                  >
                    <XAxis dataKey="name" hide />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GHI"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={14}
                      dataKey="PVsyst Ambient Temp"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                className="progress_button_1"
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress7: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress7: false,
                  })
                }
              >
                View
              </span>
            </div>
            <div>
              <span>
                {Number( parseFloat(
                  pvsystActualTemp?.reduce(
                    (acc, curr) => acc + Number(curr["Actual Ambient Temp"]),
                    0
                  ) / pvsystActualTemp?.length || 0
                ).toFixed(2) || 0)}
              </span>
              <span>Actual Ambient Temp (℃) </span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={pvsystActualTemp || []}
                  >
                    <XAxis dataKey="name" hide />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Actual GHI"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={14}
                      dataKey="Actual Ambient Temp"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress8: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress8: false,
                  })
                }
                className="progress_button_2"
              >
                View
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PvsystAmbientTemp;
