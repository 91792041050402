// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 900px) {
  .select-date-range-container {
    /* overflow: auto; */
  }
  .select-date-range-container > :first-child {
    display: none !important;
  }
}

.selected-detail-container {
  padding: 0 0.3rem;
  font-weight: bolder;
  color: rgb(3, 99, 125);
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.selected-detail-container > div {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.selected-detail-container > div .forword-arrow-icon {
  font-weight: bolder;
  font-size: 1.3rem;
}
.selected-detail-container > div > span {
  text-decoration: underline;

}
.selected-detail-container > div > span:hover {
  color: rgb(7, 69, 86);

  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .select-date-range-container {
    /* overflow: auto; */
  }
  .select-date-range-container > :first-child {
    display: none !important;
  }
  .project-detail-date-container {
    gap: 0.8rem;
    flex-direction: column !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/countrymodule.css"],"names":[],"mappings":"AAAA;EACE;IACE,oBAAoB;EACtB;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,0BAA0B;;AAE5B;AACA;EACE,qBAAqB;;EAErB,eAAe;AACjB;;AAEA;EACE;IACE,oBAAoB;EACtB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,WAAW;IACX,iCAAiC;EACnC;AACF","sourcesContent":["@media screen and (max-width: 900px) {\r\n  .select-date-range-container {\r\n    /* overflow: auto; */\r\n  }\r\n  .select-date-range-container > :first-child {\r\n    display: none !important;\r\n  }\r\n}\r\n\r\n.selected-detail-container {\r\n  padding: 0 0.3rem;\r\n  font-weight: bolder;\r\n  color: rgb(3, 99, 125);\r\n  font-size: 1.3rem;\r\n  display: flex;\r\n  align-items: center;\r\n  flex-wrap: wrap;\r\n}\r\n.selected-detail-container > div {\r\n  display: inline-flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  gap: 0.5rem;\r\n}\r\n.selected-detail-container > div .forword-arrow-icon {\r\n  font-weight: bolder;\r\n  font-size: 1.3rem;\r\n}\r\n.selected-detail-container > div > span {\r\n  text-decoration: underline;\r\n\r\n}\r\n.selected-detail-container > div > span:hover {\r\n  color: rgb(7, 69, 86);\r\n\r\n  cursor: pointer;\r\n}\r\n\r\n@media screen and (max-width: 900px) {\r\n  .select-date-range-container {\r\n    /* overflow: auto; */\r\n  }\r\n  .select-date-range-container > :first-child {\r\n    display: none !important;\r\n  }\r\n  .project-detail-date-container {\r\n    gap: 0.8rem;\r\n    flex-direction: column !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
