import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import PrescriptiveTable from "./PrescriptiveTable";
import PrescriptiveLoss from "./PrescriptiveLoss";
import { useSelector } from "react-redux";

const defaultLoss = {
  module: {
    description:
      "There is a multiple of module defects have been identified with bypass diode dominating with __ impact on underperformance",
    loss: null,
  },
  soiling: {
    description:
      "We have observed a __ gap between the actual soiling loss and PVSYST model",
    loss: null,
  },
  shadow: {
    description:
      "There is __ gap in actual plant performance compared to PVSYST model",
    loss: null,
  },
  insulationRegistance: {
    description:
      "There are number of damage Y connector and DC string cable exposed to the sharp edge of thr module mountaining structure that require attention to avoid inverter tripping due to low insultion resistance",
    loss: null,
  },
  overHeatingDCString: {
    description:
      "IR inspection of the SMBs have indentified several overheating strings and Dc cable joint, which is causing derating of cables and could result into fire in high irradiance days",
    loss: null,
  },
};
const PresCripModel = () => {
  const { projectDetail = {} } = useSelector((state) => state.projectDetail);
  const [showCheckBox, setShowCheckBox] = useState({});
  useEffect(() => {
    const objData = projectDetail?.plantLoss || defaultLoss;
    let showObj = {};
    const data = Object.keys(objData).forEach((key, index) => {
      showObj[key] = true;
    });
    setShowCheckBox({ ...showCheckBox, ...showObj });
  }, [projectDetail]);
  return (
    <>
      <Grid
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          padding: "1vmax 0",
        }}
      >
        <Grid
          className="prescriptive-container"
          sx={{ boxShadow: 2 }}
          container
          xl={11.8}
          lg={11.8}
          md={11.8}
          sm={11.8}
          xs={11.8}
          style={{
            margin: "0 auto",
            boxSizing: "border-box",
            borderWidth: "3px",
            padding: "1vmax",
            display: "flex",
            justifyContent: "center",
            gap: "1vmax",
            background:
              "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          }}
        >
          <Grid
            className="prescriptive-container-item-1"
            item
            lg={5.9}
            md={5.9}
            sm={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
            }}
          >
            <PrescriptiveTable showCheckBox={showCheckBox} setShowCheckBox={setShowCheckBox} />
          </Grid>
          <Grid
            item
            lg={5.9}
            md={5.9}
            sm={12}
            xs={12}
            style={{
              background: "white",
              position: "relative",
              overflow: "auto",
              padding: "1vmax",
            }}
            // className="prescriptive-container-item-2"
          >
            <PrescriptiveLoss showCheckBox={showCheckBox}  />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PresCripModel;
