import React, { useRef, useState } from "react";
import Table from "../../components/Table";

const PrescriptiveTable = ({ showCheckBox, setShowCheckBox }) => {
  const onChangeCheckBox = ({ name }) => {
    try {
      const checked = { ...showCheckBox };
      checked[name] = !checked[name];
      setShowCheckBox(checked);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Table onChange={onChangeCheckBox} />
    </div>
  );
};

export default PrescriptiveTable;
