import React, { useEffect, useMemo, useState } from "react";

import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PageURL from "../../constants/PageURL";
import DiagonisticGuard from "./guard/DiagonisticGuard";
import Diagonistic from "./components/diagonisticModel/Diagonistic";
import Footer from "../../components/Footer";
import CountryHeader3 from "./components/Headers/CountryHeader3";
import { useDispatch, useSelector } from "react-redux";
import "./countrymodule.css";
import { toast } from "react-toastify";
import {
  clear_errors,
  new_project_detail_request,
} from "../../constants/dataConstants";
import SpinLoader from "../../components/SpinLoader";
import { Grid } from "@mui/material";
import DateRangeComp from "../../components/DateRangeComp";

const CountryModule = () => {
  const { projectDetail, availableProject, loading2, error2 } = useSelector(
    (state) => state.projectDetail
  );

  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    country: "",
    partnerName: "",
    projectName: "",
    projectDetail: "",
  });

  const [scbsmbDetail, setscbsmbDetail] = useState({
    block: null,
    inverter: null,
    inverterType: null,
    scbsmb: null,
    labelData: null,
    scbsmbType: null,
  });
  const [selectedLebel, sestSelectedLebel] = useState({
    year: false,
    month: false,
    day: false,
    labelValue: null,
    yearData: null,
    monthData: null,
    startData: null,
    endData: null,
    yearDataValue: null,
    monthDataValue: null,
    startDataValue: null,
    endDataValue: null,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const serachParams = new URLSearchParams(location.search);

  const databaseStr = useMemo(() => {
    if (
      selectedValue.country &&
      selectedValue.partnerName &&
      selectedValue.projectName
    ) {
      return (
        selectedValue.country +
        selectedValue.partnerName +
        selectedValue.projectName
      )
        .split(" ")
        .join("");
    }
    return null;
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue({
      ...selectedValue,
      country: serachParams.get("country") || "",
      partnerName: serachParams.get("partner-name") || "",
      projectName: serachParams.get("project-name") || "",
    });
  }, []);

  useEffect(() => {
    if (selectedValue.country) {
      serachParams.set("country", selectedValue.country);
    } else {
      ["country", "partner-name", "project-name", "project-detail"].forEach(
        (e) => serachParams.delete(e)
      );
    }
    if (selectedValue.partnerName) {
      serachParams.set("partner-name", selectedValue.partnerName);
    } else
      ["partner-name", "project-name", "project-detail"].forEach((e) =>
        serachParams.delete(e)
      );
    if (selectedValue.projectName) {
      serachParams.set("project-name", selectedValue.projectName);
    } else
      ["project-name", "project-detail"].forEach((e) => serachParams.delete(e));
    if (selectedValue.projectDetail) {
      serachParams.set("project-detail", selectedValue.projectDetail);
    } else ["project-detail"].forEach((e) => serachParams.delete(e));
    navigate(`${location.pathname}?${serachParams.toString()}`);
    if (
      (!selectedValue.country ||
        !selectedValue.partnerName ||
        !selectedValue.projectName) &&
      projectDetail
    ) {
      dispatch({ type: new_project_detail_request });
    }
  }, [navigate, selectedValue]);

  useEffect(() => {
    if (error2) {
      toast.error(error2);
      dispatch({ type: clear_errors });
    }
  }, [error2]);

  if (
    location.pathname !== PageURL.PROJECT_DETAILS &&
    !projectDetail &&
    location.pathname != PageURL.COUNTRYDEFAULT
  ) {
   return <Navigate to={`${PageURL.PROJECT_DETAILS}?${serachParams.toString()}`} />;
  }

  switch (location.pathname) {
    case PageURL.PROJECT_DETAILS:
    case PageURL.INDIA_PRES_MODEL_Loss_Flow:
    case PageURL.INDIA_PRES_MODEL_Fault_Tree:
    case PageURL.INDIA_INVERTER_EFFICIENCY:
    case PageURL.INDIA_INVERTER_DEVIATION:
    case PageURL.INDIA_PROJ_OVERVIEW:
    case PageURL.INDIA_GEN_SUMMARY:

    case PageURL.INDIA_DIAGONISTIC_DETAILED:
    case PageURL.INDIA_LOSS_FLOW:

    case PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM:
    case PageURL.SCBSMB_LOSS:
      return (
        <>
          <CountryHeader3
            databaseStr={databaseStr}
            selectedLebel={selectedLebel}
            sestSelectedLebel={sestSelectedLebel}
            scbsmbDetail={scbsmbDetail}
            setscbsmbDetail={setscbsmbDetail}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
          <div
            style={{ marginTop: openSidebar ? "60px" : "0px" }}
            className="country-module-container"
          >
            {location.pathname !== PageURL.PROJECT_DETAILS &&
              location.pathname !== PageURL.INDIA_PROJ_OVERVIEW &&
              location.pathname !== PageURL.INDIA_PRES_MODEL_Fault_Tree &&
              location.pathname !== PageURL.INDIA_PRES_MODEL_Loss_Flow &&
              location.pathname !== PageURL.INDIA_LOSS_FLOW &&
              (loading2 ? (
                <SpinLoader />
              ) : (
                <Grid container xl={12} textAlign={"center"}>
                  <Grid
                    sx={{ boxShadow: 2 }}
                    item
                    xl={11.8}
                    lg={11.8}
                    md={11.8}
                    sm={11.8}
                    xs={11.8}
                    style={{
                      background:
                        "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                      boxSizing: "border-box",
                      borderWidth: "3px",
                      // borderRadius: "8px",
                      margin: "1vmax auto",
                      padding: "1vmax",
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        position: "relative",
                        backgroundColor: "#fff",
                        padding: "0.2vmax 0",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="select-date-range-container"
                    >
                      <div
                        style={{
                          padding: "0 1vmax",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                        className="selected-detail-container"
                      >
                        {selectedValue.country && (
                          <span>{selectedValue.country}</span>
                        )}
                        {selectedValue.partnerName && (
                          <span> {`${selectedValue.partnerName}`} </span>
                        )}
                        {selectedValue.projectName && (
                          <span> {" " + selectedValue.projectName}</span>
                        )}
                        {selectedValue.projectDetail && (
                          <span> {" " + selectedValue.projectDetail}</span>
                        )}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          gap: "1vmin",
                          padding: "0 1vmax",
                          position: "relative",
                        }}
                      >
                        <DateRangeComp
                          selectedLebel={selectedLebel}
                          sestSelectedLebel={sestSelectedLebel}
                          scbsmbDetail={scbsmbDetail}
                          setscbsmbDetail={setscbsmbDetail}
                          selectedValue={selectedValue}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

            <DiagonisticGuard>
              <Diagonistic
                databaseStr={databaseStr}
                selectedLebel={selectedLebel}
                sestSelectedLebel={sestSelectedLebel}
                setscbsmbDetail={setscbsmbDetail}
                scbsmbDetail={scbsmbDetail}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            </DiagonisticGuard>
          </div>
          <Footer />
        </>
      );
    default:
      return null;
  }
};

export default CountryModule;
