import React, { useMemo, useRef, useState } from "react";
import {

  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors3 } from "../../colors/color";
import CustomizeTootip from "../../components/CustomizeTootip";
import CustomizeLegend from "../../components/CustomizeLegend";
import Printer2 from "../../components/Printer2";
import GraphLoader from "../../components/GraphLoader";
const AcutalPR = ({ acLineLossActualPr, loading }) => {
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({
    actualPR: true,
  });

  const minMaxRangeAndGraphData = useMemo(() => {
    if (acLineLossActualPr && acLineLossActualPr?.length > 0) {
      const graphData =
        acLineLossActualPr?.reduce((acc, curr) => {
          if (curr["Date"] && curr["Actual Pr"]) {
            acc.push({
              Date: curr["Date"],
              'Actual Pr': Number(curr["Actual Pr"]),
            });
          }

          return acc;
        }, []) || [];

      return {
        graphData,
        min: Math.floor(
          graphData?.reduce(
            (acc, curr, index) => Math.min(curr["Actual Pr"], acc),
            Infinity
          )
        ),
        max: Math.ceil(
          graphData?.reduce(
            (acc, curr, index) => Math.max(curr["Actual Pr"], acc),
            -Infinity
          )
        ),
      };
    }
    return {};
  }, [acLineLossActualPr]);

  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>Actual PR (%)</h5>
        <Printer2
          dataSetDetail={{
            data: minMaxRangeAndGraphData.graphData,
            name: "Actual PR",
            ref: graphRef,
            refCurrent: graphRef.current?.container,
          }}
        />
      </div>

      <div
        style={{
          minWidth: "100%",
          width:
            acLineLossActualPr?.length > 0
              ? acLineLossActualPr.length *
                  Object.keys(acLineLossActualPr[0]).length *
                  10 +
                "px"
              : "100%",
        }}
        className="generation-overview-responsive-container-2"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : acLineLossActualPr?.length > 0 ? (
            <ComposedChart
              barCategoryGap={"20%"}
              ref={graphRef}
              margin={{
                top: 5,
                bottom: 5,
                left: 5,
                right: 5,
              }}
              data={minMaxRangeAndGraphData.graphData || []}
            >
              <XAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                dataKey="Date"
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              {/* <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" /> */}
              <CartesianGrid stroke="grey" strokeWidth={0.3} />

              <YAxis
                type="number"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickCount={6}
                domain={[
                  minMaxRangeAndGraphData.min -
                    minMaxRangeAndGraphData.min / 10,
                  minMaxRangeAndGraphData.max +
                    minMaxRangeAndGraphData.min / 10,
                ]}
              />

              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    label={""}
                    LegendValues={["Actual PR"]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />
              <Tooltip
                cursor={{ fill: "none" }}
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={["Actual PR"]}
                  />
                }
                contentStyle={{ fontSize: "0.7rem" }}
              />
              {
                <Bar
                  hide={showBarData.actualPR ? false : true}
                  dataKey="Actual Pr"
                  fill={colors3[0]}
                  onTransitionEnd={"1s all"}
                  maxBarSize={30}
                >
                  <LabelList
                    style={{ zIndex: 6 }}
                    dataKey="Actual Pr"
                    position="top"
                    fontSize={"0.8rem"}
                    fontWeight={600}
                    fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                  />
                </Bar>
              }
            </ComposedChart>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3>Data Not Available</h3>
            </div>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default AcutalPR;
