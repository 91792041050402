import React, { Fragment, memo } from 'react'
import './LegendTooltip.css'

/* ##### CustomizeTooltip component is used To Customize Graph ToolTip  ##### */ 

const CustomizeTootip = ({ active, payload, label, TooltipValues ,showDataKey}) => {

    if (active && payload?.length) {
        return (
            <div className='custom-tooltip-container'>
                <span style={{fontWeight:"800"}}>{label}</span>
                {payload?.map((ele, index) => {
                    return (
                        < Fragment  key={index}>
                            <small style={{ color: `${ele.color}` ,fontWeight:"800"}} > {ele?.dataKey} : {ele?.value===null || ele?.value===undefined || ele?.value===""? "N/A":ele?.value} </small>
                        </Fragment>
                    )
                })}
            </div>
        )
    } else return null;
}

export default memo(CustomizeTootip)
