import React, { memo } from "react";

/* ##### CustomizedLegend component is used To Customize Graph Legend  ##### */

const CustomizeLegend = ({
  active,
  payload,
  LegendValues,
  data,
  setData,
  styles,
}) => {
  if(!data){
    return;
  }
  let keys = (data && Object.keys(data)) || [];
  const changeData = (val) => {
    if (val === "all") {
      let newData = { ...data };
      newData[val] = !newData[val];

      for (let i in newData) {
        if (newData[val]) newData[i] = true;
        else newData[i] = false;
      }
      setData(newData);
    } else {
      let newData = { ...data };
      newData[val] = !newData[val];
      let check = false;
      for (let i in newData) {
        if (i !== "all") {
          if (newData[i]) check = true;
        }
      }
      if (check) newData["all"] = true;
      else newData["all"] = false;
      setData(newData);
    }
  };

  if (payload?.length && keys?.length > 0) {
    return (
      <div
        id="legend"
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          flexWrap: "wrap",
          width: "100%",
          color: "black",
          margin: "auto",
          paddingTop:styles?.paddingTop || "0",
          height: "fit-content",
          position: "relative",
          zIndex: "1",
    
        }}
      >
        {payload?.map((ele, index) => {
          return (
            <div
              onClick={() => changeData(keys[index])}
              key={index}
              style={{ zIndex: 10, display: "inline-block" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.25rem",
                  padding: "0.2rem 0.3rem",
                }}
              >
                <span
                  style={{
                    height: "0.6rem",
                    width: "0.6rem",
                    background: `${ele.color}`,
                    marginTop: "0.1rem",
                  }}
                ></span>
                <span
                  style={{
                    fontSize:styles?.fontSize || "2vmin",
                    color: `${ele.color}`,
                    listStyleType: "square",
                    textDecoration: data[keys[index]] ? "none" : "line-through",
                  }}
                  key={index}
                >
                  {" "}
                  {LegendValues[index]}{" "}
                </span>
              </div>
            </div>
          );
        })}
        {keys?.length > 2 && (
          <div
            onClick={() => changeData("all")}
            style={{
              zIndex: 10,
              display: "inline-block",
              padding: "0.2rem 0.3rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <span
                style={{
                  height: "0.6rem",
                  width: "0.6rem",
                  background: `red`,
                  marginTop: "0.1rem",
                }}
              ></span>
              <span
                style={{
                  fontSize:styles?.fontSize || "2vmin",
                  color: `red`,
                  listStyleType: "square",
                  textDecoration: data["all"] ? "none" : "line-through",
                }}
              >
                All{" "}
              </span>
            </div>
          </div>
        )}
        <p
          className="cpy_right"
          style={{
            display: "none",
            position: "absolute",
            right: "-1%",
            top: "1%",
            fontSize: "1.5vmin",
          }}
        >
          &#169;GreenEnco
        </p>
      </div>
    );
  }
  
  else if (payload?.length) {
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {payload?.map((ele, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    height: "10px",
                    width: "10px",
                    background: `${ele.color}`,
                    marginTop: "0.150rem",
                  }}
                ></span>
                <span
                  style={{
                    color: `${ele.color}`,
                    listStyleType: "square",
                    padding: "0 0.5rem",
                    boxSizing: "border-box",
                  }}
                  key={index}
                >
                  {" "}
                  {LegendValues[index]}{" "}
                </span>
              </div>
            );
          })}
          <p
            className="cpy_right"
            style={{
              display: "none",
              position: "absolute",
              right: "-1%",
              top: "1%",
              fontSize: "0.8rem",
            }}
          >
            &#169;GreenEnco
          </p>
        </div>
      </>
    );
  }
  return null;
};

export default  memo(CustomizeLegend);
