import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Grid, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";

const defaultLoss = {
  module: {
    description:
      "There is a multiple of module defects have been identified with bypass diode dominating with __ impact on underperformance",
    loss: null,
  },
  soiling: {
    description:
      "We have observed a __ gap between the actual soiling loss and PVSYST model",
    loss: null,
  },
  shadow: {
    description:
      "There is __ gap in actual plant performance compared to PVSYST model",
    loss: null,
  },
  insulationRegistance: {
    description:
      "There are number of damage Y connector and DC string cable exposed to the sharp edge of thr module mountaining structure that require attention to avoid inverter tripping due to low insultion resistance",
    loss: null,
  },
  overHeatingDCString: {
    description:
      "IR inspection of the SMBs have indentified several overheating strings and Dc cable joint, which is causing derating of cables and could result into fire in high irradiance days",
    loss: null,
  },
};

const LossflowTable = ({ showCheckBox, setShowCheckBox }) => {
  const { projectDetail = {} } = useSelector((state) => state.projectDetail);

  const plantLossDetail = useMemo(
    () => projectDetail?.plantLoss || defaultLoss,
    [projectDetail]
  );

  return (
    <div>
      <h3 style={{ marginTop: "0.5rem" }}>Loss Flow Table</h3>
      <table
        className="loss_flow_table"
        style={{
          height: "75vh",
        }}
      >
        <tbody>
          <tr style={{ backgroundColor: "#edeafb", padding: "0.5vmax 0" }}>
            {/* <th></th> */}
            <th>Losses</th>
            <th>Description</th>
            <th>Loss (%)</th>
          </tr>
          {Object.keys(plantLossDetail).map((key) => {
            return (
              <tr style={{ padding: "1vmax 0" }}>
                {/* <td>
                  <Checkbox
                    id="module"
                    checked={showCheckBox.Module}
                    color="success"
                    onChange={() =>
                      setShowCheckBox({
                        ...showCheckBox,
                        Module: !showCheckBox.Module,
                      })
                    }
                  />
                </td> */}

                <td style={{ textAlign: "center" }}>
                  <InputLabel
                    style={{
                      color: "black",
                      fontSize: "0.7rem",
                      fontWeight: "bold",
                    }}
                    htmlFor="module"
                  >
                    {key}
                  </InputLabel>{" "}
                </td>

                <td style={{ textAlign: "center", fontSize: "0.7rem" }}>
                  {plantLossDetail[key]?.description}
                </td>
                <td style={{ textAlign: "center", fontSize: "0.7rem" }}>
                  {!isNaN(plantLossDetail[key]?.loss) &&
                  plantLossDetail[key]?.loss !== null
                    ? `${plantLossDetail[key]?.loss}%`
                    : ""}{" "}
                </td>
              </tr>
            );
          })}
          {/* <tr style={{ padding: "1vmax 0" }}>
          <td>
            <Checkbox
              id="module"
              checked={showCheckBox.Module}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Module: !showCheckBox.Module,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              style={{ color: "black", fontSize: "2vmin" }}
              htmlFor="module"
            >
              Module
            </InputLabel>{" "}
          </td>

          <td style={{ textAlign: "center" }}>
            There is a multiple of module defects have been identified with
            bypass diode dominating with __ impact on underperformance
          </td>
          <td style={{ textAlign: "center" }}>{loss.module? loss.module+"%":"N/A"} </td>
        </tr>

        
        <tr>
          <td>
            <Checkbox
              id="insulator"
              checked={showCheckBox.Resistance}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Resistance: !showCheckBox.Resistance,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              htmlFor="insulator"
              style={{
                whiteSpace: "pre-wrap",
                color: "black",
                fontSize: "2vmin",
              }}
            >
              Insulation resistance of cable & connector
            </InputLabel>
          </td>

          <td style={{ textAlign: "center" }}>
            There are number of damage Y connector and DC string cable exposed
            to the sharp edge of thr module mountaining structure that require
            attention to avoid inverter tripping due to low insultion resistance
          </td>
          <td style={{ textAlign: "center" }}>{loss.insulationRegistance? loss.insulationRegistance+"%":"N/A"}</td>
        </tr>
        <tr>
          <td>
            <Checkbox
              id="overheating"
              checked={showCheckBox.Overheating}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Overheating: !showCheckBox.Overheating,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              htmlFor="overheating"
              style={{
                whiteSpace: "pre-wrap",
                color: "black",
                fontSize: "2vmin",
              }}
            >
              Overheating DC string and DC main cables
            </InputLabel>
          </td>

          <td style={{ textAlign: "center" }}>
            IR inspection of the SMBs have indentified several overheating
            strings and Dc cable joint, which is causing derating of cables and
            could result into fire in high irradiance days
          </td>
          <td style={{ textAlign: "center" }}>{loss.overHeatingDCString? (loss.overHeatingDCString+"%"): "N/A"}</td>
        </tr>
        <tr>
          <td>
            <Checkbox
              checked={showCheckBox.Soiling}
              id="soiling"
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Soiling: !showCheckBox.Soiling,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            {" "}
            <InputLabel
              style={{ color: "black", fontSize: "2vmin" }}
              htmlFor="soiling"
            >
              Soiling
            </InputLabel>{" "}
          </td>

          <td style={{ textAlign: "center" }}>
            We have observed a __ gap between the actual soiling loss and
            PVSYST model
          </td>
          <td style={{ textAlign: "center" }}>{loss.soiling? loss.soiling+"%": "N/A"}</td>
        </tr>
        <tr>
          <td>
            <Checkbox
              id="shadow"
              checked={showCheckBox.Shadow}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Shadow: !showCheckBox.Shadow,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              htmlFor="shadow"
              style={{ color: "black", fontSize: "2vmin" }}
            >
              {" "}
              Shadow Loss
            </InputLabel>{" "}
          </td>

          <td style={{ textAlign: "center" }}>
            There is __ gap in actual plant performance compared to PVSYST
            model
          </td>
          <td style={{ textAlign: "center" }}>{loss.shadow?loss.shadow+"%": "N/A"}</td>
        </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default LossflowTable;
