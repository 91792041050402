import React from "react";
import { Bar, ComposedChart, XAxis, ResponsiveContainer } from "recharts";
import GraphLoader from "../../../../components/GraphLoader";

const PvsystActualGTIContainer = ({
  GHIGTI,
  loading,
  progressValueAnimate,
  setProgressValueAnimate,
}) => {
  return (
    <div className="detail_overview_1">
      {loading ? (
        <div style={{ height: "100%", width: "100%" }}>
          <GraphLoader />
        </div>
      ) : !GHIGTI || GHIGTI?.length == 0 ? (
        <div style={{ height: "100%", width: "100%", fontSize: "2vmin" }}>
          <p>PVsyst GII (kWh/m^2) and Actual GII (kWh/m^2)</p>
          <p>Data Not Available</p>
        </div>
      ) : (
        <>
          <div>
            <div>
              <div>
                <progress
                  className="progress_1"
                  style={{
                    accentColor: "green",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress1
                      ? "3px solid red"
                      : "none",
                  }}
                  value={
                    Math.floor(
                      GHIGTI?.reduce(
                        (acc, curr) => acc + curr["GII (KWh/m^2)"],
                        0
                      )
                    ).toFixed(2) || 0
                  }
                  max={
                    Math.ceil(
                      GHIGTI?.reduce(
                        (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                        0
                      )
                    ) + 100
                  }
                />
              </div>
              <div>
                <span>PVsyst GII (kWh/m^2)</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      (parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                          0
                        )
                      ).toFixed(2) *
                        100) /
                        (Math.ceil(
                          GHIGTI?.reduce(
                            (acc, curr) => curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                            0
                          )
                        ) +
                          100) -
                      3
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>

                <span>
                  {parseFloat(
                    GHIGTI?.reduce(
                      (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                      0
                    )
                  ).toFixed(2) || 0}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_2"
                  style={{
                    accentColor: "green",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress2
                      ? "3px solid black"
                      : "none",
                  }}
                  value={parseFloat(
                    GHIGTI?.reduce(
                      (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                      0
                    )
                  ).toFixed(2)}
                  max={
                    Math.ceil(
                      GHIGTI?.reduce(
                        (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                        0
                      )
                    ) + 100
                  }
                />
              </div>
              <div>
                <span>Actual GII (kWh/m^2)</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      (parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                          0
                        )
                      ).toFixed(2) *
                        100) /
                        (Math.ceil(
                          GHIGTI?.reduce(
                            (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                            0
                          )
                        ) +
                          100) -
                      3
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span >
                  {parseFloat(
                    GHIGTI?.reduce(
                      (acc, curr) => curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                      0
                    )
                  ).toFixed(2) || 0}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_3"
                  style={{ accentColor: "green" }}
                  value={
                    // curr["GII (KWh/m^2)"],
                    // curr["Actual GII (kWh/m^2)"],
                    // acc
                    Math.abs(
                      parseFloat(
                        ((parseFloat(
                          GHIGTI?.reduce(
                            (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                            0
                          )
                        ).toFixed(2) -
                          parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                              0
                            )
                          ).toFixed(2)) *
                          100) /
                          parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                              0
                            )
                          ).toFixed(2)
                      ).toFixed(2) || 0
                    )
                  }
                  max="100"
                />
              </div>
              <div>
                <span>Excess(+)/ShortFall(-) (%)</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      Math.abs(
                        parseFloat(
                          ((parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                              0
                            )
                          ).toFixed(2) -
                            parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                                0
                              )
                            ).toFixed(2)) *
                            100) /
                            parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                                0
                              )
                            ).toFixed(2)
                        ).toFixed(2) || 0
                      ) - 3
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span>
                  {parseFloat(
                    ((parseFloat(
                      GHIGTI?.reduce(
                        (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                        0
                      )
                    ).toFixed(2) -
                      parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                          0
                        )
                      ).toFixed(2)) *
                      100) /
                      parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc,
                          0
                        )
                      ).toFixed(2)
                  ).toFixed(2) || 0}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>
                {parseFloat(
                  GHIGTI?.reduce((acc, curr) =>curr["GII (KWh/m^2)"]? acc + curr["GII (KWh/m^2)"]:acc, 0)
                ).toFixed(2)}
              </span>
              <span>PVsyst GII (kWh/m^2) </span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={GHIGTI || []}
                  >
                    <XAxis dataKey="name" hide />
                    {/* <Tooltip/> */}
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GTI"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={14}
                      dataKey="GII (KWh/m^2)"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                className="progress_button_1"
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress1: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress1: false,
                  })
                }
              >
                View
              </span>
            </div>
            <div>
              <span>
                {parseFloat(
                  GHIGTI?.reduce(
                    (acc, curr) =>curr["Actual GII (kWh/m^2)"]? acc + curr["Actual GII (kWh/m^2)"]:acc,
                    0
                  )
                ).toFixed(2)}
              </span>
              <span>Actual GII (kWh/m^2) </span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={GHIGTI || []}
                  >
                    <XAxis dataKey="name" hide />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Actual GTI"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={14}
                      dataKey="Actual GII (kWh/m^2)"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress2: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress2: false,
                  })
                }
                className="progress_button_2"
              >
                View
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PvsystActualGTIContainer;
