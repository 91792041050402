import React, { Fragment, memo } from 'react'
import './LegendTooltip.css'

/* ##### CustomizeTooltip component is used To Customize Graph ToolTip  ##### */ 

const CustomizeTootip = ({ active, payload, label, TooltipValues ,showDataKey}) => {

    if (active && payload?.length) {
        return (
            <div style={{ backgroundColor: "white", display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start",gap:"0.2rem",color: "black", padding: "0.6rem", opacity: "1.3", boxShadow: "2px 2px 3px black",maxHeight:"150px",overflow:"auto" }}>
                <span style={{fontWeight:"800"}}>{label}</span>
                {payload?.map((ele, index) => {
                    return (
                        < Fragment key={index}>
                            <small style={{ color: `${ele.color}` ,fontWeight:"800"}} > {showDataKey?ele?.dataKey: TooltipValues[index]} : {ele?.value} </small>
                        </Fragment>
                    )
                })}
            </div>
        )
    } else return null;
}

export default memo(CustomizeTootip)