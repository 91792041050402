import React, { useEffect, useMemo, useRef, useState } from "react";
import Printer2 from "../../components/Printer2";
import SpeedChart from "../../components/SpeedChart";
import SelectOptions from "./SelectOptions";
import { Grid } from "@mui/material";

const ExcessShortFall = ({
  netContractualEnergyShortfall,
  loading,
  showPVsystEnergy,
}) => {
  const graphRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(null);

  const minMaxRange = useMemo(() => {
    if (
      netContractualEnergyShortfall &&
      netContractualEnergyShortfall?.length > 0
    ) {
      setSelectedDate(netContractualEnergyShortfall[0]?.Date);
      return {
        min1: netContractualEnergyShortfall?.reduce(
          (acc, curr, index) => Math.min(acc, curr["Shortfall kwh"]),
          Infinity
        ),
        max1: netContractualEnergyShortfall?.reduce(
          (acc, curr, index) => Math.max(acc, curr["Shortfall kwh"]),
          -Infinity
        ),
        min2: Math.floor(
          netContractualEnergyShortfall?.reduce(
            (acc, curr, index) => Math.min(acc, curr["Shortfall"]),
            Infinity
          )
        ),
        max2: Math.ceil(
          netContractualEnergyShortfall?.reduce(
            (acc, curr, index) => Math.max(acc, curr["Shortfall"]),
            -Infinity
          )
        ),
      };
    }
    return {};
  }, [netContractualEnergyShortfall]);

  const [PVsystContractual, setPVsystContractual] = useState({
    'Shortfall kwh': "Shortfall kwh",
    Shortfall: "Shortfall",
  });


  useEffect(() => {
    if (showPVsystEnergy) {
      setPVsystContractual({
        ...PVsystContractual,
        'Shortfall kwh': "PVsyst Net Shortfall kwh",
        Shortfall: "PVsyst Net Energy Shortfall",
      });
    }
  }, [showPVsystEnergy]);

  return (
    <Grid
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        padding: "1vmax",
        overflow: "auto",
      }}
      ref={graphRef}
    >
      <div
        style={{
          padding: "0 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <div
          style={{
            padding: "0 1.5rem",
            position: "sticky",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <select
            name="level"
            // id="level"
            style={{
              padding: "1vmin 1.5vmin",
              textAlign: "center",
              fontWeight: "bolder",
              borderStyle: "solid",
              borderColor: "#ED7D31",
              borderWidth: "3px",
              fontSize: "1rem",
              backgroundColor: "#edeaea",
            }}
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
          >
            {/* ###  Monthly Value component for Gaugemeter ### */}

            <SelectOptions
              dataArray={
                netContractualEnergyShortfall?.map((e) => e.Date) || []
              }
            />
          </select>
        </div>
        <Printer2
          

          dataSetDetail={{
            data:
              netContractualEnergyShortfall?.reduce((acc, curr) => {
                acc.push({
                  Date: curr["Date"],
                  ExcessShortfall: curr[PVsystContractual['Shortfall kwh']],
                  ExcessShortfallPercentage: curr[PVsystContractual.Shortfall],
                });
                return acc;
              }, []) || [],
            name: "Excess ShortFall",
            ref: graphRef,
            refCurrent: graphRef.current,
          }}
        />
      </div>
      <div className="speed-chart-container">
        <SpeedChart
          title={`Excess(+)/Shortfall(-) (KWh)`}
          minValue={minMaxRange.min1 || 0}
          maxValue={minMaxRange.max1 || 0}
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.Date === selectedDate
            )?.[PVsystContractual['Shortfall kwh']] || 0
          }
        />
        <SpeedChart
          title={`Excess(+)/Shortfall(-) (%)`}
          minValue={minMaxRange.min2 || 0}
          maxValue={minMaxRange.max2 || 0}
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.Date === selectedDate
            )?.[PVsystContractual.Shortfall] || 0
          }
        />
      </div>
      <div className="watermark-text">© GreenEnco</div>
    </Grid>
  );
};

export default ExcessShortFall;
