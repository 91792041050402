import React from "react";

const SelectOptions = ({ dataArray }) => {
  return dataArray?.map((e, index) => {
    return (
      <option key={index} value={e}>
        {e}
      </option>
    );
  });
};

export default SelectOptions;
