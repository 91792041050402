import React, { useMemo } from "react";
import "./Table.css";
import { Checkbox } from "@mui/material";
import { useSelector } from "react-redux";

/* Tabel component for PresCripModel  */
const defaultLoss = {
  module: {
    description:
      "There is a multiple of module defects have been identified with bypass diode dominating with __ impact on underperformance",
    loss: null,
  },
  soiling: {
    description:
      "We have observed a __ gap between the actual soiling loss and PVSYST model",
    loss: null,
  },
  shadow: {
    description:
      "There is __ gap in actual plant performance compared to PVSYST model",
    loss: null,
  },
  insulationRegistance: {
    description:
      "There are number of damage Y connector and DC string cable exposed to the sharp edge of thr module mountaining structure that require attention to avoid inverter tripping due to low insultion resistance",
    loss: null,
  },
  overHeatingDCString: {
    description:
      "IR inspection of the SMBs have indentified several overheating strings and Dc cable joint, which is causing derating of cables and could result into fire in high irradiance days",
    loss: null,
  },
};
const Table = (props) => {
  const { projectDetail = {} } = useSelector((state) => state.projectDetail);

  const plantLossDetail = useMemo(
    () => projectDetail?.plantLoss || defaultLoss ,
    [projectDetail]
  );

  
  return (
    plantLossDetail? <table
      className="loss_flow_table"
      style={{
        width: "95%",
      }}
    >
      <tr style={{ backgroundColor: "#edeafb", padding: "0.5vmax 0" }}>
        <th></th>
        <th>Sl No.</th>
        <th>Losses</th>
        <th>Description</th>
        <th>Loss (%)</th>
      </tr>
      {Object.keys(plantLossDetail).map((key, index) => {
        return (
          <tr>
            <td>
              <Checkbox
                defaultChecked
                color="success"
                onChange={(e) => {
                  props?.onChange({ name: key });
                }}
              />
            </td>
            <td>{index + 1}</td>
            <td>{key}</td>
            <td>{plantLossDetail[key]?.description}</td>

            <td>
            { !isNaN( plantLossDetail[key]?.loss) &&  plantLossDetail[key]?.loss!==null
                ? `${plantLossDetail[key]?.loss}%`
                : ""}{" "}
            </td>
          </tr>
        );
      })}
      {/* <tr>
        <td>
          <Checkbox
            defaultChecked
            color="success"
            onChange={(e) => {
              props?.onChange({ name: "Module" });
            }}
          />
        </td>
        <td >1</td>
        <td >Module</td>
        <td >
          There is a multiple of module defects have been identified with bypass
          diode dominating with 5.25% impact on underperformance
        </td>
        <td >5.25 %</td>
      </tr>
      <tr>
        <td>
          <Checkbox
            defaultChecked
            color="success"
            onChange={(e) => {
              props?.onChange({ name: "Soiling" });
            }}
          />
        </td>
        <td >2</td>

        <td >Soiling</td>

        <td >
          We have observed a 3.5% gap between the actual soiling loss and PVSYST
          model
        </td>
        <td >3.50 %</td>
      </tr>
      <tr>
        <td>
          <Checkbox
            defaultChecked
            color="success"
            onChange={(e) => {
              props?.onChange({ name: "Shadow" });
            }}
          />
        </td>
        <td >3</td>

        <td >Shadow Loss</td>

        <td >
          There is 1.3% gap in actual plant performance compared to PVSYST model
        </td>
        <td >1.30 %</td>
      </tr>
      <tr>
        <td>
          <Checkbox
            defaultChecked
            color="success"
            onChange={(e) => {
              props?.onChange({ name: "Resistance" });
            }}
          />
        </td>
        <td >4</td>

        <td >
          Insulation resistance of cable & connector
        </td>

        <td >
          There are number of damage Y connector and DC string cable exposed to
          the sharp edge of thr module mountaining structure that require
          attention to avoid inverter tripping due to low insultion resistance
        </td>
        <td >1.00 %</td>
      </tr>
      <tr>
        <td>
          <Checkbox
            defaultChecked
            color="success"
            onChange={(e) => {
              props?.onChange({ name: "Overheating" });
            }}
          />
        </td>
        <td >5</td>

        <td >
          Overheating DC string and DC main cables
        </td>

        <td >
          IR inspection of the SMBs have indentified several overheating strings
          and Dc cable joint, which is causing derating of cables and could
          result into fire in high irradiance days
        </td>
        <td >1.00 %</td>
      </tr> */}
    </table>:
    <div  style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>Data Not Available</div>
  );
};

export default Table;
