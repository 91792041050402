import React, { memo, useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./daterange.css";
import { convertMonthToNumber, getDaysInMonth } from "../constants/Utils";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageURL from "../constants/PageURL";

const DateRangeComp = ({
  selectedLebel,
  sestSelectedLebel,
  scbsmbDetail,
  setscbsmbDetail,
}) => {
  const { projectDetail = {} } = useSelector((state) => state.projectDetail);
  const [data, setData] = useState(null);
  const [selectedButton, setSelectedButton] = useState(false);
  const [showLable, setShowLable] = useState({
    label: false,
    startDate: false,
    endDate: false,
  });
  const [dateRange, setDateRange] = useState({
    day: false,
    month: false,
    year: false,
    startYear: null,
    endYear: null,
    startMonth: null,
    endMonth: null,
    startDay: null,
    endDay: null,
  });

  const location = useLocation();

  const onchangeYear = (val, label) => {
    if (label == "Select Label") {
      val = val?.toLowerCase();
      if (selectedLebel.labelValue === val) return;

      if (val === "year") {
        sestSelectedLebel({
          ...selectedLebel,
          labelValue: val,
          [`${val}`]: !selectedLebel[`${val}`],

          day: false,
          month: false,
          yearData: null,
          monthData: null,
          endData: null,
          endDataValue: null,
          startDataValue: null,
          yearDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          monthDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          startData: !selectedLebel[`${val}`]
            ? data?.year?.map((e) => e)
            : null,
        });
      } else if (val === "month") {
        sestSelectedLebel({
          ...selectedLebel,
          [`${val}`]: !selectedLebel[`${val}`],
          labelValue: val,

          year: false,
          day: false,
          yearData: !selectedLebel[`${val}`] ? data?.year?.map((e) => e) : [],
          yearDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          monthDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.monthDataValue,
          monthData: null,
          endData: null,
          startData: null,
          endDataValue: null,
          startDataValue: null,
        });
      } else {
        sestSelectedLebel({
          ...selectedLebel,
          year: false,
          month: false,
          [`${val}`]: !selectedLebel[`${val}`],
          labelValue: val,
          yearData: !selectedLebel[`${val}`] ? data?.year?.map((e) => e) : [],
          monthData: null,
          endData: null,
          startData: null,
          endDataValue: null,
          startDataValue: null,
          yearDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          monthDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.monthDataValue,
        });
      }
    } else if (selectedLebel.year) {
      if (label == "Start Year") {
        sestSelectedLebel({
          ...selectedLebel,
          startDataValue: val,
          endDataValue: null,
          endData:
            data?.year?.slice(
              data?.year?.findIndex((e) => e == val),
              data?.year?.length
            ) || null,
        });
      } else {
        sestSelectedLebel({
          ...selectedLebel,
          endDataValue: val,
        });
      }
    } else if (selectedLebel.month) {
      if (label == "Select Year") {
        sestSelectedLebel({
          ...selectedLebel,
          yearDataValue: val,
          monthDataValue: null,
          startDataValue: null,
          endDataValue: null,
          startData:
            data?.month
              ?.find((e) => e.year == val)
              .month?.map((e) => e + "-" + val) || null,
          endData: null,
        });
      } else if (label == "Start Month") {
        sestSelectedLebel({
          ...selectedLebel,
          startDataValue: val?.split("-")[0],
          endDataValue: null,
          endData:
            selectedLebel.startData?.slice(
              selectedLebel.startData?.findIndex((e) => e == val),
              selectedLebel.startData?.length || 0
            ) || null,
        });
      } else if (label === "End Month") {
        sestSelectedLebel({
          ...selectedLebel,
          endDataValue: val?.split("-")[0],
        });
      }
    } else if (selectedLebel.day) {
      if (label === "Select Year") {
        sestSelectedLebel({
          ...selectedLebel,
          yearDataValue: val,
          monthData:
            data?.month
              ?.find((e) => e.year == val)
              .month?.map((e) => e + "-" + val) || null,
          monthDataValue: null,
          startDataValue: null,
          endDataValue: null,
          startData: null,
          endData: null,
        });
      } else if (label === "Select Month") {
        sestSelectedLebel({
          ...selectedLebel,
          monthDataValue: val?.split("-")[0],
          startDataValue: null,
          endDataValue: null,
          startData: getDaysInMonth(
            selectedLebel.yearDataValue,
            convertMonthToNumber(val?.split("-")[0])
          ),
          endData: null,
        });
      } else if (label === "Start Day") {
        sestSelectedLebel({
          ...selectedLebel,
          startDataValue: val,
          endDataValue: null,
          endData:
            selectedLebel.startData?.slice(
              selectedLebel.startData?.findIndex((e) => e == val),
              selectedLebel.startData?.length || 0
            ) || null,
        });
      } else if (label === "End Day") {
        sestSelectedLebel({
          ...selectedLebel,
          endDataValue: val,
        });
      }
    }
  };
  useEffect(() => {
    if (!data) {
      setData(projectDetail?.dataYear);
    }
    sestSelectedLebel({
      ...selectedLebel,
      year: false,
      month: false,
      day: false,
      labelValue: null,
      yearData: null,
      monthData: null,
      startData: null,
      endData: null,
      yearDataValue: null,
      monthDataValue: null,
      startDataValue: null,
      endDataValue: null,
    });
  }, [location.pathname, scbsmbDetail]);

  window.onclick = function () {
    setShowLable({
      ...showLable,
      startDate: false,
      endDate: false,
      label: false,
    });
  };

  return (
    <>
      <div className="date-range-container-2">
        <div>
          <div
            className={`${showLable.label ? "selected" : ""}`}
            onClick={(e) => {
              setShowLable({
                ...showLable,
                label: !showLable.label,
                startDate: false,
                endDate: false,
              });
              e.stopPropagation();
            }}
          >
            <div>
              <span>Select Label</span>{" "}
              <span> {selectedLebel.labelValue} </span>{" "}
            </div>
            {/* <span>{value || label}</span> */}
            <ArrowDropDownIcon
              className={showLable.label ? "rotate" : "not-rotate"}
            />
            <div
              className={`label-drop-down-container ${
                showLable.label ? "show" : ""
              }`}
              style={{ padding: showLable.label ? "0.25rem 0.3rem" : 0 }}
            >
              <span
                onClick={() => {
                  if (selectedLebel.labelValue === "year") return;
                  setDateRange({
                    ...dateRange,
                    year: true,
                    month: false,
                    day: false,
                  });
                  onchangeYear("year", "Select Label");
                }}
              >
                Year
              </span>
              <span
                onClick={() => {
                  if (selectedLebel.labelValue === "month") return;
                  setDateRange({
                    ...dateRange,
                    year: true,
                    month: false,
                    day: false,
                  });

                  onchangeYear("month", "Select Label");
                }}
              >
                Month
              </span>
              {/* <span
                style={{ opacity: "0.5" }}
                onClick={() => {
                  // toast.info("Please Choose Month or Year")
                  // return;
                  if (selectedLebel.labelValue === "day") return;
                  setDateRange({
                    ...dateRange,
                    year: true,
                    month: false,
                    day: false,
                  });
                  onchangeYear("day", "Select Label");
                }}
              >
                Day
              </span> */}
            </div>
          </div>
        </div>

        <div onClick={(e) => e.stopPropagation()}>
          <div
            className={`${showLable.startDate ? "selected" : ""}`}
            onClick={() =>
              setShowLable({
                ...showLable,
                endDate: false,
                label: false,
                startDate: !showLable.startDate,
              })
            }
          >
            <div>
              <span>Start Date</span>{" "}
              <span>
                {selectedLebel["month"]
                  ? (selectedLebel["yearDataValue"] || "") +
                    " " +
                    (selectedLebel["startDataValue"] || "")
                  : selectedLebel["year"]
                  ? selectedLebel["startDataValue"]
                  : (selectedLebel["yearDataValue"] || "") +
                    " " +
                    (selectedLebel["monthDataValue"] || "") +
                    " " +
                    (selectedLebel["startDataValue"] || "")}
              </span>{" "}
            </div>
            {/* <span>{value || label}</span> */}
            <ArrowDropDownIcon
              className={showLable.startDate ? "rotate" : "not-rotate"}
            />
            <div
              className={`calender-container ${
                showLable.startDate ? "show2" : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <div>
                {selectedLebel["year"] ||
                selectedLebel["month"] ||
                selectedLebel["day"] ? (
                  <span
                    className={
                      (selectedLebel["year"] &&
                        selectedLebel["startDataValue"]) ||
                      selectedLebel["yearDataValue"]
                        ? "active-date-label"
                        : ""
                    }
                    onClick={() => {
                      if (dateRange.year) return;
                      setDateRange({
                        ...dateRange,
                        year: true,
                        day: false,
                        month: false,
                      });
                    }}
                  >
                    Year
                  </span>
                ) : (
                  <>
                    <span>Year</span>
                    <span>Month</span>
                    <span>Day</span>
                  </>
                )}
                {(selectedLebel["month"] || selectedLebel["day"]) && (
                  <span
                    className={
                      (selectedLebel["month"] &&
                        selectedLebel["startDataValue"]) ||
                      selectedLebel["monthDataValue"]
                        ? "active-date-label"
                        : ""
                    }
                    onClick={() => {
                      if (dateRange.month) return;
                      setDateRange({
                        ...dateRange,
                        month: true,
                        day: false,
                        year: false,
                      });
                    }}
                  >
                    Month
                  </span>
                )}
                {selectedLebel["day"] && (
                  <span
                    className={
                      selectedLebel["day"] && selectedLebel["startDataValue"]
                        ? "active-date-label"
                        : ""
                    }
                    onClick={() => {
                      if (dateRange.day) return;
                      setDateRange({
                        ...dateRange,
                        day: true,
                        year: false,
                        month: false,
                      });
                    }}
                  >
                    Day
                  </span>
                )}
              </div>

              <div>
                {selectedLebel["year"] &&
                  selectedLebel?.startData?.map((e, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          selectedLebel.startDataValue == e
                            ? "active-date-value"
                            : ""
                        }
                        onClick={(ee) => {
                          onchangeYear(e, "Start Year");
                          setShowLable({
                            ...showLable,
                            endDate: false,
                            label: false,
                            startDate: false,
                          });
                          ee.stopPropagation();
                        }}
                      >
                        {e}
                      </span>
                    );
                  })}

                {selectedLebel["month"] &&
                  dateRange.year &&
                  selectedLebel?.yearData?.map((e, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          selectedLebel.yearDataValue == e
                            ? "active-date-value"
                            : ""
                        }
                        onClick={() => {
                          setDateRange({
                            ...dateRange,
                            year: false,
                            month: true,
                            day: false,
                          });
                          onchangeYear(e, "Select Year");
                        }}
                      >
                        {e}
                      </span>
                    );
                  })}
                {selectedLebel["month"] &&
                  dateRange.month &&
                  (!selectedLebel["startData"] ? (
                    <p>Please select a year</p>
                  ) : (
                    selectedLebel["startData"]?.map((e, index) => {
                      return (
                        <span
                          key={index}
                          className={
                            selectedLebel.startDataValue == e?.split("-")[0]
                              ? "active-date-value"
                              : ""
                          }
                          onClick={(ee) => {
                            // setDateRange({
                            //     ...dateRange,
                            //     year:false,
                            //     month:false,
                            //     day:false
                            // })
                            setShowLable({
                              ...showLable,
                              endDate: false,
                              label: false,
                              startDate: false,
                            });
                            onchangeYear(e, "Start Month");
                            ee.stopPropagation();
                          }}
                        >
                          {e?.split("-")[0]}
                        </span>
                      );
                    })
                  ))}

                {selectedLebel["day"] &&
                  dateRange.year &&
                  selectedLebel["yearData"]?.map((e, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          selectedLebel.yearDataValue == e
                            ? "active-date-value"
                            : ""
                        }
                        onClick={() => {
                          setDateRange({
                            ...dateRange,
                            year: false,
                            month: true,
                            day: false,
                          });
                          onchangeYear(e, "Select Year");
                        }}
                      >
                        {e}
                      </span>
                    );
                  })}

                {selectedLebel["day"] &&
                  dateRange.month &&
                  (!selectedLebel["monthData"] ? (
                    <p>Please select a year</p>
                  ) : (
                    selectedLebel["monthData"]?.map((e, index) => {
                      return (
                        <span
                          key={index}
                          className={
                            selectedLebel.monthDataValue == e?.split("-")[0]
                              ? "active-date-value"
                              : ""
                          }
                          onClick={() => {
                            setDateRange({
                              ...dateRange,
                              year: false,
                              month: false,
                              day: true,
                            });
                            onchangeYear(e, "Select Month");
                          }}
                        >
                          {e?.split("-")[0]}
                        </span>
                      );
                    })
                  ))}

                {selectedLebel["day"] &&
                  dateRange.day &&
                  (!selectedLebel["startData"] ? (
                    <p>Please select a month</p>
                  ) : (
                    selectedLebel["startData"]?.map((e, index) => {
                      return (
                        <span
                          key={index}
                          className={
                            selectedLebel.startDataValue == e
                              ? "active-date-value"
                              : ""
                          }
                          onClick={(ee) => {
                            onchangeYear(e, "Start Day");
                            setShowLable({
                              ...showLable,
                              endDate: false,
                              label: false,
                              startDate: false,
                            });
                            ee.stopPropagation();
                          }}
                        >
                          {e}
                        </span>
                      );
                    })
                  ))}
                {!selectedLebel["day"] &&
                  !selectedLebel["month"] &&
                  !selectedLebel["year"] && <p>Please select a label</p>}
              </div>
            </div>
          </div>
          <div
            className={`${showLable.endDate ? "selected" : ""}`}
            onClick={() =>
              setShowLable({
                ...showLable,
                startDate: false,
                label: false,
                endDate: !showLable.endDate,
              })
            }
          >
            <div>
              <span>End Date</span>{" "}
              <span>
                {selectedLebel["month"]
                  ? selectedLebel["endDataValue"] &&
                    (selectedLebel["yearDataValue"] || "") +
                      " " +
                      (selectedLebel["endDataValue"] || "")
                  : selectedLebel["year"]
                  ? selectedLebel["endDataValue"]
                  : selectedLebel["endDataValue"] &&
                    (selectedLebel["yearDataValue"] || "") +
                      " " +
                      (selectedLebel["monthDataValue"] || "") +
                      " " +
                      (selectedLebel["endDataValue"] || "")}
              </span>{" "}
            </div>
            <ArrowDropDownIcon
              className={showLable.endDate ? "rotate" : "not-rotate"}
            />
            <div
              className={`calender-container ${
                showLable.endDate ? "show3" : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <div>
                {selectedLebel["year"] ||
                selectedLebel["month"] ||
                selectedLebel["day"] ? (
                  <span
                    className={`${
                      (selectedLebel["year"] &&
                        selectedLebel["endDataValue"]) ||
                      selectedLebel["yearDataValue"]
                        ? "active-date-label"
                        : ""
                    } ${
                      (selectedLebel["day"] || selectedLebel["month"]) &&
                      selectedLebel["startDataValue"]
                        ? "active-disabled-date-label"
                        : ""
                    }`}
                  >
                    Year
                  </span>
                ) : (
                  <>
                    <span>Year</span>
                    <span>Month</span>
                    <span>Day</span>
                  </>
                )}

                {(selectedLebel["month"] || selectedLebel["day"]) && (
                  <span
                    className={`${
                      (selectedLebel["month"] &&
                        selectedLebel["endDataValue"]) ||
                      selectedLebel["monthDataValue"]
                        ? "active-date-label"
                        : ""
                    }
                      ${
                        selectedLebel["day"] && selectedLebel["startDataValue"]
                          ? "active-disabled-date-label"
                          : ""
                      }`}
                  >
                    Month
                  </span>
                )}
                {selectedLebel["day"] && (
                  <span
                    className={
                      selectedLebel["day"] && selectedLebel["endDataValue"]
                        ? "active-date-label"
                        : ""
                    }
                  >
                    Day
                  </span>
                )}
              </div>

              <div>
                {selectedLebel["year"] &&
                  selectedLebel?.endData?.map((e, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          selectedLebel.endDataValue == e
                            ? "active-date-value"
                            : ""
                        }
                        onClick={(ee) => {
                          onchangeYear(e, "End Year");
                          setShowLable({
                            ...showLable,
                            endDate: false,
                            label: false,
                            startDate: false,
                          });
                          ee.stopPropagation();
                        }}
                      >
                        {e}
                      </span>
                    );
                  })}

                {selectedLebel["month"] &&
                  selectedLebel["startDataValue"] &&
                  selectedLebel["endData"]?.map((e, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          selectedLebel.endDataValue == e?.split("-")[0]
                            ? "active-date-value"
                            : ""
                        }
                        onClick={(ee) => {
                          onchangeYear(e, "End Month");
                          setShowLable({
                            ...showLable,
                            endDate: false,
                            label: false,
                            startDate: false,
                          });
                          ee.stopPropagation();
                        }}
                      >
                        {e?.split("-")[0]}
                      </span>
                    );
                  })}
                {selectedLebel["day"] &&
                  selectedLebel["yearDataValue"] &&
                  selectedLebel["monthDataValue"] &&
                  selectedLebel["startData"] &&
                  selectedLebel["endData"]?.map((e, index) => {
                    return (
                      <span
                        key={index}
                        className={
                          selectedLebel.endDataValue == e
                            ? "active-date-value"
                            : ""
                        }
                        onClick={(ee) => {
                          onchangeYear(e, "End Day");
                          setShowLable({
                            ...showLable,
                            endDate: false,
                            label: false,
                            startDate: false,
                          });
                          ee.stopPropagation();
                        }}
                      >
                        {e}
                      </span>
                    );
                  })}
                {!selectedLebel?.startDataValue && <p>Please Select Start Date</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(DateRangeComp);
