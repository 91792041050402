// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader-3 {
    width: 30px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
      linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
      linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
  }
  .loader-3::before,
  .loader-3::after {
     content: "";
     grid-area: 1/1;
     border-radius: 50%;
     background: inherit;
     opacity: 0.915;
     transform: rotate(30deg);
  }
  .loader-3::after {
     opacity: 0.83;
     transform: rotate(60deg);
  }
  @keyframes l23 {
    100% {transform: rotate(1turn)}
  }`, "",{"version":3,"sources":["webpack://./src/components/Loaders/Loader.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB;;yFAEqF;IACrF,4BAA4B;IAC5B,oCAAoC;EACtC;EACA;;KAEG,WAAW;KACX,cAAc;KACd,kBAAkB;KAClB,mBAAmB;KACnB,cAAc;KACd,wBAAwB;EAC3B;EACA;KACG,aAAa;KACb,wBAAwB;EAC3B;EACA;IACE,MAAM,wBAAwB;EAChC","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\r\n.loader-3 {\r\n    width: 30px;\r\n    aspect-ratio: 1;\r\n    display: grid;\r\n    border-radius: 50%;\r\n    background:\r\n      linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,\r\n      linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;\r\n    background-repeat: no-repeat;\r\n    animation: l23 1s infinite steps(12);\r\n  }\r\n  .loader-3::before,\r\n  .loader-3::after {\r\n     content: \"\";\r\n     grid-area: 1/1;\r\n     border-radius: 50%;\r\n     background: inherit;\r\n     opacity: 0.915;\r\n     transform: rotate(30deg);\r\n  }\r\n  .loader-3::after {\r\n     opacity: 0.83;\r\n     transform: rotate(60deg);\r\n  }\r\n  @keyframes l23 {\r\n    100% {transform: rotate(1turn)}\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
