import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  
  Bar,
  CartesianGrid,
  ComposedChart,
  
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { saveAs } from "file-saver";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import { CSVLink } from "react-csv";
import GraphLoader from "../../../../components/GraphLoader";

const NetContractualShortFall = ({
  netContractualEnergyShortfall,
  loading,
  projectDetail
}) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({
    netEnergy:true,
    contractualOrPVsystEnergy:true,
    shortfall:true,
    all:true

  });

  const minMaxRange= useMemo(() => {
    if (
      netContractualEnergyShortfall &&
      netContractualEnergyShortfall?.length > 0
    ) {
      

      return{
        min:
          netContractualEnergyShortfall?.reduce(
            (acc, curr, index) =>
              Math.min(
                curr["netEnergy"],
                curr["contractualEnergy"],
                acc
              ),
            Infinity
          ),
        
        max:netContractualEnergyShortfall?.reduce(
          (acc, curr, index) =>
            Math.max(
              curr["netEnergy"],
              curr["contractualEnergy"],
              acc
            ),
          -Infinity
        )
      }
    }
    return {}

  }, [netContractualEnergyShortfall]);

  const [PVsystOrContractual,setPVsystOrContractual]=useState({tooltipValue:null,dataKey:null,downloadKey:null})

  useEffect(()=>{
    if(projectDetail && projectDetail?.plantDataAvailability?.netPVsyst){
      setPVsystOrContractual({tooltipValue: "PVsyst Energy",dataKey:"pvsystEnergy",downloadKey:"PVsystEnergy"})
    }else setPVsystOrContractual({tooltipValue: "Contractual Energy",dataKey:"contractualEnergy",downloadKey:"ContractualEnergy"})

  },[projectDetail])
  return (
    <>
      {" "}
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>
          Net Energy (KWh) vs {PVsystOrContractual.tooltipValue} (KWh) and Excess(+)/Shortfall(-) (%)
        </h5>
        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() => {
            if(!graphRef.current)return null
            document.getElementsByClassName("cpy_right")[0].style.display =
              "block";
            exportComponentAsJPEG(graphRef, {
              fileName: "graph",
            });
            document.getElementsByClassName("cpy_right")[0].style.display =
              "none";
          }}
          svgDownload={async () => {
            if(!graphRef.current)return null
            document.getElementsByClassName("cpy_right")[0].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[0].style.display =
              "none";
            saveAs(svgData, "graph.svg");
          }}
        />
      </div>
      <CSVLink
        data={
          netContractualEnergyShortfall?.reduce((acc, curr) => {
            acc.push({
              Date: curr.name,
              NetEnergy: curr["netEnergy"],
              [PVsystOrContractual.downloadKey]: curr[PVsystOrContractual.dataKey],
              ExcessShortfall: curr["shortFall"],
            });
            return acc;
          }, []) ||
           []
        }
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={csvDownloadRef}
      />
      <div
        style={{
          
          minWidth:"100%",
          width:
            netContractualEnergyShortfall?.length > 0
              ? netContractualEnergyShortfall.length *
                  Object.keys(netContractualEnergyShortfall[0]).length*7+
                   
              
                "px"
              : "100%",
        }}
        className="generation-overview-responsive-container-1"
      >
        <ResponsiveContainer>
        {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) :
          netContractualEnergyShortfall?.length>0 ?
           (
          
          <ComposedChart
            barCategoryGap={"20%"}
            ref={graphRef}
            data={netContractualEnergyShortfall || []}
            margin={{
              top: 5,
              right: 5,
              bottom: 5,
              left: 15,

              // top: "0.5vmin",
              // right: "2vmin",
              // bottom: "0.5vmin",
              // left: "2vmin",
            }}
          >
            <CartesianGrid stroke="grey" strokeWidth={0.3} />

            <XAxis
              dataKey="name"
              fontSize={"0.8rem"}
              fontWeight={600}
              tickLine={false}
              axisLine={false}
              tickMargin={5}
            />
            <YAxis
              fontSize={"0.7rem"}
              fontWeight={600}
              tickLine={false}
              tickMargin={5}
              tickCount={7}
              tickFormatter={(v) =>
                v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
              }
              yAxisId="left-axis"
              // label={<AxisLabel axisType='yAxis' x={-23} y={0}>Energy</AxisLabel>}
              label={{
                value: "Energy",
                angle: -90,
                position: "insideBottomLeft",
                dx: -10,
                fontWeight: 600,
                fontSize: "0.8rem",
              }}
              domain={[
                minMaxRange.min&&minMaxRange?minMaxRange.min-(minMaxRange.min)/4:0,
                minMaxRange.min&&minMaxRange?minMaxRange.max+(minMaxRange.min)/4:0,
                
              ]}
            />
            <YAxis
              fontSize={"0.8rem"}
              fontWeight={600}
              tickLine={false}
              tickMargin={8}
              minTickGap={2}
              yAxisId="right-axis"
              orientation="right"
              // label={<AxisLabel axisType='yAxis' x={42} y={355}>Excess/Shortfall</AxisLabel>}
              label={{
                value: "Excess/Shortfall",
                angle: 90,
                position: "insideBottomRight",
                // dy: -110,
                // dx: 10,
                fontSize: "0.8rem",
                fontWeight: "600",
              }}
              domain={[
                Math.floor(
                  netContractualEnergyShortfall?.reduce(
                    (acc, curr, index) => Math.min(curr["shortFall"], acc),
                    Infinity
                  )
                ),
                Math.ceil(
                  netContractualEnergyShortfall?.reduce(
                    (acc, curr, index) =>
                      Math.max(
                        curr["shortFall"],

                        acc
                      ),
                    -Infinity
                  )
                ),
              ]}
            />
            <Tooltip
              cursor={{ fill: "none" }}
              content={
                <CustomizeTootip
                  active={false}
                  payload={[]}
                  label={""}
                  TooltipValues={[
                    "Net Energy",
                    `${PVsystOrContractual.tooltipValue}`,
                    "Excess/Shortfall",
                  ]}
                />
              }
            />
            <Legend
              content={
                <CustomizeLegend
                  active={false}
                  payload={[]}
                  LegendValues={[
                    "Net Energy",
                    `${PVsystOrContractual.tooltipValue}`,
                    "Excess/Shortfall",
                  ]}
                  data={showBarData}
                  setData={setShowBarData}
                  styles={{ fontSize: "0.8rem" }}
                />
              }
            />

           
                  <Bar
                    hide={showBarData.netEnergy ? false : true}
                    dataKey={"netEnergy"}
                    // barSize={10}
                    // fill="#122b4f"
                    fill={`${colors3[0]}`}
                    yAxisId="left-axis"
                    maxBarSize={30}
                  />
                  <Bar
                    hide={showBarData.contractualOrPVsystEnergy ? false : true}
                    dataKey={PVsystOrContractual.dataKey}
                    // barSize={10}
                    // fill="#122b4f"
                    fill={`${colors3[1]}`}
                    yAxisId="left-axis"
                    maxBarSize={30}

                  />
                   <Line
                      hide={showBarData.shortfall ? false : true}
                      type="monotone"
                      dataKey={"shortFall"}
                      strokeWidth={3}
                      stroke={`${colors1[4]}`}
                      yAxisId="right-axis"
                    />
                {/* );
              })} */}

           
          </ComposedChart>
          ):
          <div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
          <h3>Data Not Available</h3>
          </div>
        }
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default NetContractualShortFall;
