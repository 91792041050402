import React, { useEffect, useState } from "react";
import "./GenOverView.css";
import { useDispatch, useSelector } from "react-redux";
import NetContractual from "./NetContractual";
import ExcessShortFall from "./ExcessShortFall";
import ActualPrACLineLoss from "./ActualPRACLineLoss";
import NetContractualShortFall from "./NetContractualShortFall";
import AcutalPR from "./AcutalPR";
import {
  acLineLossActualPrAction,
  GHIGTIAction,
  netContractualEnergyShortfallAction,
} from "../../actions/energyAction";
import PvsystActualGTI from "../diagonisticModel/PvsystActualGTI";
import NetPVsystContainer from "./NetPVsystContainer";
import NetContractualContainer from "./NetContractualContainer";
import ActualPRAcAndDcCUFContainer from "./ActualPRAcAndDcCUFContainer";
import ACLineLoss from "./ACLineLoss";
import { Grid } from "@mui/material";

const GenOverView = ({ selectedLebel, scbsmbDetail, databaseStr }) => {
  const { projectDetail = {} } = useSelector((state) => state.projectDetail);

  const { netContractualEnergyShortfall = [], loading } = useSelector(
    (state) => state.netContractualEnergyShortfall
  );
  const { acLineLossActualPr = [], loading2 } = useSelector(
    (state) => state.acLineLossActualPr
  );

  const { GHIGTI = [], loading: GHIGTILoader } = useSelector(
    (state) => state.GHIGTI
  );

  /* ###   Initialize State to animate progress bar   ### */

  const [progressValueAnimate, setProgressValueAnimate] = useState({
    progress1: false,
    progress2: false,
    progress3: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLebel?.endDataValue) {
      dispatch(netContractualEnergyShortfallAction(selectedLebel, databaseStr));
      dispatch(acLineLossActualPrAction(selectedLebel, databaseStr));
      dispatch(GHIGTIAction(selectedLebel, databaseStr));
    }
  }, [dispatch, selectedLebel?.endDataValue]);

  useEffect(() => {
    dispatch(GHIGTIAction({}, databaseStr));
    dispatch(netContractualEnergyShortfallAction({}, databaseStr));

    dispatch(acLineLossActualPrAction({}, databaseStr));
  }, []);

  return (
    <>
      {
        // loading3 ? (
        //   <SpinLoader />
        // ) :
        <>
          <div>
            <Grid container xl={12} textAlign={"center"}>
              {/* ###   GenOverview Container 1 Start   ### */}
              <Grid
                sx={{ boxShadow: 2 }}
                container
                xs={11.8}
                columnGap={"1vmax"}
                rowGap={"1vmax"}
                style={{
                  boxSizing: "border-box",
                  borderWidth: "3px",
                  // borderRadius: "8px",
                  margin: "0 auto 1vmax",
                  padding: "1vmax",
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                }}
                justifyContent={"space-between"}
                className="generation-overview-container"
              >
                {projectDetail?.plantDataAvailability?.netPVsyst ? (
                  <NetPVsystContainer
                    progressValueAnimate={progressValueAnimate}
                    setProgressValueAnimate={setProgressValueAnimate}
                    netContractualEnergyShortfall={
                      netContractualEnergyShortfall
                    }
                    loading={loading}
                  />
                ) : (
                  <NetContractualContainer
                    progressValueAnimate={progressValueAnimate}
                    setProgressValueAnimate={setProgressValueAnimate}
                    netContractualEnergyShortfall={
                      netContractualEnergyShortfall
                    }
                    loading={loading}
                  />
                )}

                <ActualPRAcAndDcCUFContainer
                  acLineLossActualPr={acLineLossActualPr}
                  loading={loading2}
                />
                <ACLineLoss
                  acLineLossActualPr={acLineLossActualPr}
                  loading={loading2}
                />
              </Grid>

              {/* ###   GenOverview Container 3 end ### */}
              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                style={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0vmax auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <NetContractual
                  showPVsystEnergy={
                    projectDetail &&
                    projectDetail?.plantDataAvailability?.netPVsyst
                      ? true
                      : false
                  }
                  netContractualEnergyShortfall={netContractualEnergyShortfall}
                  loading={loading}
                />
              </Grid>
              {/* ###   GenOverview Container 3 end ### */}
              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                style={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0vmax auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <ExcessShortFall
                  showPVsystEnergy={
                    projectDetail &&
                    projectDetail?.plantDataAvailability?.netPVsyst
                      ? true
                      : false
                  }
                  netContractualEnergyShortfall={netContractualEnergyShortfall}
                  loading={loading}
                />
              </Grid>
              {/* ###   GenOverview Container 4 start ### */}

              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                style={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0vmax auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <ActualPrACLineLoss
                  acLineLossActualPr={acLineLossActualPr}
                  loading2={loading2}
                />
              </Grid>
              {/* ###   GenOverview Container 4 end ### */}
              {/* ###   GenOverview Container 5 start ### */}

              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                style={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0 auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"1vmax"}
                >
                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                  >
                    <NetContractualShortFall
                      netContractualEnergyShortfall={
                        netContractualEnergyShortfall
                      }
                      loading={loading}
                      projectDetail={projectDetail}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                  >
                    <PvsystActualGTI
                      barColors={["#298c8c", "#f3bc00"]}
                      //  barColors={["#ea801c","#f2c45f"]}
                      //  barColors={[colors3[0],colors3[1]]}
                      GHIGTI={GHIGTI}
                      loading={GHIGTILoader}
                    />

                    {/* <AcutalPR
                      acLineLossActualPr={acLineLossActualPr}
                      loading={loading2}
                    /> */}
                  </Grid>
                </Grid>

                {/* ###   GenOverview Container 5 end ### */}
              </Grid>

              {/* ###   GenOverview Container 6 start ### */}
              <Grid container xl={12}>
                <Grid
                  item
                  xl={11.8}
                  lg={11.8}
                  md={11.8}
                  sm={11.8}
                  xs={11.8}
                  // borderRadius={"14px"}
                  style={{
                    background:
                      "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                    margin: "0 auto 1vmax",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1vmax",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      background: "white",
                      padding: "0.5vmax 1vmax",
                      position: "relative",
                      overflow: "auto",
                    }}
                  >
                    <AcutalPR
                      acLineLossActualPr={acLineLossActualPr}
                      loading={loading2}
                    />
                  </Grid>
                </Grid>
                {/* ###   GenOverview Container 6 end ### */}
              </Grid>
            </Grid>
          </div>
          {/* )} */}
        </>
      }
    </>
  );
};

export default GenOverView;
