import React from "react";
import { useLocation } from "react-router-dom";
import "../Headers/CountryHeader.css";
import PageURL from "../../constants/PageURL";
import InverterEfficiency from "../inverterEfficiency/InverterEfficiency";
import SCBSMB from "../SCBSMB/SCBSMB";
import ProjOverView from "../projectOverView/ProjOverView";
import PresCripModel from "../prescriptiveModel/PresCripModel";
import LossFlow from "./LossFlow";
import Detailed from "./Detailed";
import Heatmap from "../SCBSMB/Heatmap";
import FaultTree from "../prescriptiveModel/FaultTree";
import GenOverView from "../overviewDetail/GenOverView";
import ProjectDetailBody from "../ProjectDetails/ProjectDetailBody";
import InverterDeviation from "../inverterEfficiency/InverterDeviation";

/* ##### Diagnostic component which having all the country related components  ##### */

const Diagonistic = ({
  databaseStr,
  selectedLebel,
  sestSelectedLebel,
  selectedValue,
  setSelectedValue,
  scbsmbDetail,
  setscbsmbDetail,
}) => {
  const location = useLocation();

  return (
    <>
      {location.pathname === PageURL.PROJECT_DETAILS && <ProjectDetailBody />}
      
      {location.pathname === PageURL.INDIA_LOSS_FLOW && <LossFlow />}
      {location?.pathname === PageURL.INDIA_DIAGONISTIC_DETAILED && (
        <Detailed
          selectedLebel={selectedLebel}
          scbsmbDetail={scbsmbDetail}
          setscbsmbDetail={setscbsmbDetail}
          databaseStr={databaseStr}
        />
      )}
      {location?.pathname === PageURL.INDIA_INVERTER_EFFICIENCY && (
        <InverterEfficiency
          selectedValue={selectedValue}
          selectedLebel={selectedLebel}
          scbsmbDetail={scbsmbDetail}
          setscbsmbDetail={setscbsmbDetail}
          databaseStr={databaseStr}
          sestSelectedLebel={sestSelectedLebel}
        />
      )}

      {location?.pathname === PageURL.INDIA_INVERTER_DEVIATION && (
        <InverterDeviation
          selectedValue={selectedValue}
          selectedLebel={selectedLebel}
          scbsmbDetail={scbsmbDetail}
          setscbsmbDetail={setscbsmbDetail}
          databaseStr={databaseStr}
          sestSelectedLebel={sestSelectedLebel}
        />
      )}
      {location?.pathname === PageURL.INDIA_GEN_SUMMARY && (
        <GenOverView
          selectedValue={selectedValue}
          selectedLebel={selectedLebel}
          scbsmbDetail={scbsmbDetail}
          setscbsmbDetail={setscbsmbDetail}
          databaseStr={databaseStr}
        />
      )}
      {/* {location?.pathname === PageURL.INDIA_GEN_SUMMARY && null} */}
      {location?.pathname === PageURL.INDIA_PROJ_OVERVIEW && (
        <ProjOverView databaseStr={databaseStr} />
      )}
      {location?.pathname === PageURL.INDIA_PRES_MODEL_Loss_Flow && (
        <PresCripModel />
      )}
      {location?.pathname === PageURL.INDIA_PRES_MODEL_Fault_Tree && (
        <FaultTree />
      )}
      {location?.pathname === PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM && (
        <Heatmap
          databaseStr={databaseStr}
          scbsmbDetail={scbsmbDetail}
          selectedLebel={selectedLebel}
          sestSelectedLebel={sestSelectedLebel}
        />
      )}

      {location.pathname === PageURL.SCBSMB_LOSS && (
        <SCBSMB
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          sestSelectedLebel={sestSelectedLebel}
          selectedLebel={selectedLebel}
          scbsmbDetail={scbsmbDetail}
          setscbsmbDetail={setscbsmbDetail}
          databaseStr={databaseStr}
        />
      )}
    </>
  );
};

export default Diagonistic;
