import React, {  useMemo, useRef, useState } from "react";
import {

  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors1, colors3 } from "../../colors/color";
import CustomizeTootip from "../../components/CustomizeTootip";
import CustomizeLegend from "../../components/CustomizeLegend";
import Printer2 from "../../components/Printer2";

import GraphLoader from "../../components/GraphLoader";

const PvsystGHINormalised = ({ pvsystNormalised, loading }) => {
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({
    netenergy: true,
    normalisedEnergy: true,
    shortfall: true,
    all: true,
  });

  const minMaxRange = useMemo(() => {
    if (pvsystNormalised && pvsystNormalised?.length > 0) {
      return {
        min: Math.floor(
          pvsystNormalised?.reduce(
            (acc, curr, index) =>
              Math.min(curr["Net Energy"], curr["PVsyst GHI Normalised Energy"], acc),
            Infinity
          )
        ),
        max: Math.ceil(
          pvsystNormalised?.reduce(
            (acc, curr, index) =>
              Math.max(curr["Net Energy"], curr["PVsyst GHI Normalised Energy"], acc),
            -Infinity
          )
        ),
      };
    }
    return {};
  }, [pvsystNormalised]);

  return (
    <>
      {" "}
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>
          Net Energy(KWh) vs GHI-Corrected Normalized PVsyst Energy(KWh) and
          Excess(+)/Shortfall(-)(%)
        </h5>
        <Printer2
          
          dataSetDetail={{
            data: pvsystNormalised?.reduce((acc, curr) => {
              if (
                curr["Date"] &&
                !isNaN(curr["Net Energy"]) &&
                !isNaN(curr["PVsyst GHI Normalised Energy"]) &&
                !isNaN(curr["Shortfall"])
              ) {
                acc.push({
                  Date: curr.Date,
                  NetEnergy: curr["Net Energy"],
                  'PVsyst GHI Normalised Energy': curr["PVsyst GHI Normalised Energy"],
                  Shortfall: curr["Shortfall"],
                });
              }
              return acc;
            }, []),
            name: "Net GHI-Corrected Normalized Pvsyst Energy",ref:graphRef,
            refCurrent:graphRef.current?.container
          }}
        />
      </div>
      <div
        style={{
          minWidth: "100%",
          width:
            pvsystNormalised?.length > 0
              ? pvsystNormalised.length *
                  (Object.keys(pvsystNormalised[0]).length || 0) *
                  10 +
                "px"
              : "100%",
        }}
        className="detailed-responsive-container-3"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : pvsystNormalised?.length > 0 ? (
            <ComposedChart
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 15,
              }}
              barCategoryGap={"20%"}
              data={
                pvsystNormalised?.reduce((acc, curr) => {
                  // if(curr["name"] && !isNaN(curr["Net Energy"]) && !isNaN( curr["PVsyst GHI Normalised Energy"]) && !isNaN(curr["Shortfall"])){
                  acc.push({
                    Date: curr.Date,
                    'Net Energy': curr["Net Energy"],
                    'PVsyst GHI Normalised Energy': curr["PVsyst GHI Normalised Energy"],
                    Shortfall: curr["Shortfall"],
                  });
                  // }

                  return acc;
                }, []) || []
              }
              ref={graphRef}
              maxBarSize={30}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="Date"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickFormatter={(v) =>
                  v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
                }
                yAxisId="left-axis"
                //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}}
                // label={<AxisLabel axisType='yAxis' x={-30} y={-5}>Energy</AxisLabel>}
                label={{
                  value: "Energy",
                  angle: -90,
                  position: "insideBottomLeft",
                  dx: -10,
                  fontWeight: 600,
                  fontSize: "0.8rem",
                }}
                domain={[
                  minMaxRange.min - minMaxRange.min / 10,
                  minMaxRange.max + minMaxRange.min / 10,
                ]}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={800}
                yAxisId="right-axis"
                orientation="right"
                // label={<AxisLabel axisType='yAxis' x={26} y={305}>Excess/Shortfall</AxisLabel>}
                label={{
                  value: "Excess/Shortfall",
                  angle: 90,
                  position: "insideBottomRight",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  // dy: -110,
                  // dx: 10,
                }}
                tickLine={false}
                tickMargin={5}
                domain={[
                  Math.floor(
                    pvsystNormalised?.reduce(
                      (acc, curr, index) =>
                        Math.min(
                          curr["Shortfall"],

                          acc
                        ),
                      Infinity
                    )
                  ) - 1,
                  Math.ceil(
                    pvsystNormalised?.reduce(
                      (acc, curr, index) =>
                        Math.max(
                          curr["Shortfall"],

                          acc
                        ),
                      -Infinity
                    )
                  ) + 1,
                ]}
              />
              <Tooltip
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={[
                      "Net Energy",
                      "GHI Normalised",
                      "Excess(+)/Shortfall(-)",
                    ]}
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={[
                      "Net Energy",
                      "GHI Normalised",
                      "Excess(+)/Shortfall(-)",
                    ]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />

              <Bar
                hide={showBarData.netenergy ? false : true}
                dataKey="Net Energy"
                fill={`${colors3[0]}`}
                yAxisId="left-axis"
              />
              <Bar
                hide={showBarData.normalisedEnergy ? false : true}
                dataKey="PVsyst GHI Normalised Energy"
                fill={`${colors3[1]}`}
                yAxisId="left-axis"
              />
              <Line
                hide={showBarData.shortfall ? false : true}
                type="monotone"
                dataKey="Shortfall"
                strokeWidth={3}
                stroke={`${colors1[4]}`}
                yAxisId="right-axis"
              />
            </ComposedChart>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3>Data Not Available</h3>
            </div>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PvsystGHINormalised;
