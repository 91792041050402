
/* #####  Constant URLs for different route  ##### */

const PageURL = {
  BASE: "/",
  SIGN_UP:"/user/register",
  FORGOT_PASSWORD:"/user/fogot-password",
  OTP_VERIFICATION:"/user/forgot-password/otp/verify",
  COUNTRYDEFAULT: "/dashboard",
  PROJECT_DETAILS: "/dashboard/project-details",
  PROJECT_ANALYTICS:"/dashboard/project-analytics",
  INDIA_GEN_SUMMARY: "/dashboard/generationsummary",
  INDIA_PROJ_OVERVIEW: "/dashboard/projectoverview",
  INDIA_PRES_MODEL_Loss_Flow: "/dashboard/prescriptivemodel/loss-flow",
  INDIA_PRES_MODEL_Fault_Tree: "/dashboard/prescriptivemodel/fault-tree",
  INDIA_DIAGONISTIC: "/dashboard/diagonistic",
  INDIA_DIAGONISTIC_DETAILED: "/dashboard/diagonistic/detailed",
  INDIA_LOSS_FLOW: "/dashboard/diagonistic/lossflow",
  INDIA_INVERTER_EFFICIENCY: "/dashboard/diagonistic/inverter-efficiency",
  INDIA_INVERTER_DEVIATION: "/dashboard/diagonistic/inverter-deviation",
  INDIA_INVERTER_HEATMAP_DIAGRAM: "/dashboard/diagonistic/inverter/heatmap-diagram",
  SCBSMB_LOSS:"/dashboard/projectdetail/scbsmb-loss"
};

export default PageURL;
