import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

import { scbsmbLossMonthlyAction } from "../../actions/energyAction";
import GraphLoader from "../../components/GraphLoader";

/* ##### Heatmap Component  ##### */

const Heatmap = ({ databaseStr, scbsmbDetail, selectedLebel }) => {
  const { scbsmbLoss, loading } = useSelector((state) => state.scbsmbLoss);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { projectDetail } = useSelector((state) => state.projectDetail);
  const [uniqueKyes, setUniqueKyes] = useState([]);
  /* ###  End of Function To Get Inverter Number from URL   ### */
  const [heatmapData, setHeatmapData] = useState(null);
  const [title, setTitle] = useState(null);
  useEffect(() => {
    if (scbsmbLoss) {
      let heatmapData = [];
      const set = new Set();

      scbsmbLoss?.forEach((e) => {
        let finalObj = {};
        finalObj["name"] = e["Date"];
        let newObj = {};
        let countObj = {};

        Object.keys(e || {}).forEach((ee) => {
          if (ee != "Date") {
            // let string =ee
            let string =
              projectDetail?.plantDetail?.string === "STRING"
                ? ee?.substring(0, 9)
                : ee.substring(0, 6);
            set.add(string);
            if (!isNaN(newObj[string])) {
              newObj[string] = Number(newObj[string]) + Number(e[ee]);
              countObj[string]++;
            } else {
              newObj[string] = Number(e[ee]);
              countObj[string] = 1;
            }
          }
        });

        Object.keys(newObj || {}).forEach((e) => {
          finalObj[e] = (newObj[e] / countObj[e]).toFixed(2);
        });
        heatmapData.push(finalObj);
      });
      setUniqueKyes([...set].sort());

      let result = heatmapData?.map((e, index) => {
        let obj = {};
        obj["name"] = e["name"];
        obj["data"] = [];
        Object.keys(e || {}).forEach((ee) => {
          if (ee != "name") {
            obj["data"].push({
              x: ee,
              y: !isNaN(e[ee]) ? Number(e[ee])?.toFixed(0) : 0,
            });
          }
        });

        return obj;
      });
      setHeatmapData(result);
    }
  }, [scbsmbLoss]);
  useEffect(() => {
    if (selectedLebel.endDataValue) {
      dispatch(
        scbsmbLossMonthlyAction(scbsmbDetail, selectedLebel, databaseStr)
      );
    }
  }, [dispatch, selectedLebel]);
  useEffect(() => {
    let { inverter, block, scbsmb, stack } = scbsmbDetail;
    inverter = inverter?.split(" ")[1];
    block = block?.split(" ")[1];
    scbsmb = scbsmb?.split(" ")[1];
    stack = stack?.split(" ")[1];
    setTitle(
      (projectDetail?.plantDetail?.block_name || "BL") +
        "-" +
        block +
        "-" +
        "INV-" +
        inverter +
        (stack ? "-STACK-" + stack : "")
    );
  }, [scbsmbDetail]);

  useEffect(() => {
    dispatch(scbsmbLossMonthlyAction(scbsmbDetail, {}, databaseStr));
  }, [scbsmbDetail]);

  return (
    <>
      {
        <Grid container textAlign={"center"}>
          <Grid
            item
            sx={{ boxShadow: 2 }}
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              // borderRadius: "8px",
              margin: "0 auto 1vmax",
              padding: "1vmax",
            }}
          >
            <Grid
              item
              lg={12}
              sx={{
                position: "relative",
                backgroundColor: "white",
                padding: "1rem 0",
                minHeight: "75vh",
                height: "fit-content",
                overflow: "auto",
              }}
            >
              <h3>Heatmap Diagram ({title})</h3>
              {loading ? (
                <div
                  style={{
                    height: "70vh",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <GraphLoader />
                </div>
              ) : (
                <Chart
                  style={{
                    margin: "auto",
                    width: "95%",
                    minWidth: "500px",
                  }}
                  height={"95%"}
                  type="heatmap"
                  options={{
                    chart: {
                      toolbar: {
                        show: false,
                        show: user?.userLevel !== 1 ? false : true,
                        // tools: {

                        // },
                      },
                    },
                    // tooltip: {
                    //   y: {
                    //     formatter: (v) => (v == 0 ? v : -v),
                    //   },
                    // },

                    plotOptions: {
                      heatmap: {
                        enableShades: false,
                        shadeIntensity: 0,
                        colorScale: {
                          // inverse: false,
                          ranges: [
                            {
                              from: 1,
                              to: 100,
                              name: "low",
                              color: "#00A100",
                            },
                            {
                              from: -5,
                              to: 0,
                              name: "medium",
                              color: "#128FD9",
                            },
                            {
                              from: -15,
                              to: -6,
                              name: "high",
                              color: "#FFB200",
                            },
                            {
                              from: -100,
                              to: -16,
                              name: "extreme",
                              color: "#FF0000",
                            },
                          ],
                        },
                      },
                    },
                    xaxis: {
                      categories: uniqueKyes,
                      labels: {
                        style: {
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          fontFamily:
                            "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                        },
                      },
                    },
                    yaxis: {
                      labels: {
                        style: {
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          fontFamily:
                            "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                        },
                      },
                    },
                    grid: {
                      show: true,
                      borderColor: "red",
                    },

                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      showForSingleSeries: true,
                      show: true,
                      position: "top",
                    },
                  }}
                  series={heatmapData || []}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default Heatmap;
