import React, { useEffect, useRef, useState } from "react";
import "../CountryDefault.css";
import "./ProjOverView.css";
import { Grid } from "@mui/material";
import SmallCard from "../../components/SmallCard";
import GeneralDetails from "./GeneralDetails";
import InverterDetails from "./InverterDetails";
import SmbDetails from "./SmbDetails";
import { useDispatch, useSelector } from "react-redux";
import AsBuiltDetails from "./AsBuiltDetails";
import SpinLoader from "../../components/SpinLoader";
import { downloadProjectOverviewImgAction } from "../../actions/energyAction";

const ProjOverView = ({databaseStr}) => {
  const {  projectDetail,loading:loading2 } = useSelector((state) => state.projectDetail);
  const{projectOverviewImg,loading}=useSelector(state=>state.projectImages)

  const [showDetails, setShowDetails] = useState({
    showGeneralDetails: false,
    showInverterDetails: false,
    showAsbuiltDetails: false,
    showSMB: false,
  });

  const refReset = useRef(null)
  const refForGeneralDetails = useRef(null);
  const refInverterDetails = useRef(null);
  const refsmb = useRef(null)
  const refAsbuiltDiagram = useRef(null)
  const dispatch = useDispatch();
  const[plantDetail,setPlantDetail]=useState(null)
  const [projectOverviewImage,setProjectOverviewImage]=useState(null)


  useEffect(() => {
    if (showDetails.showGeneralDetails) {
      refForGeneralDetails.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (showDetails.showInverterDetails) {
      refInverterDetails.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (showDetails.showSMB) {
      refsmb.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (showDetails.showAsbuiltDetails) {
      refAsbuiltDiagram.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if(projectDetail){
      setPlantDetail(projectDetail?.plantDetail)
    }
 
    if(projectOverviewImg){
      setProjectOverviewImage(URL.createObjectURL(projectOverviewImg))
    }
  
  }, [showDetails, projectDetail,projectOverviewImg])
  useEffect(()=>{
    if(!projectOverviewImg){
      dispatch(downloadProjectOverviewImgAction({fileName: "projectOverViewPic",databaseStr}))
    }

  },[])


  return (
    < >
      {
      loading2 ?
        <SpinLoader /> 
        :
        <div ref={refReset}  >
          <Grid container textAlign={"center"} >

            {/* ###  Project Overview Image Container  ### */}

            <Grid item
              lg={12}
              md={12}
              xs={12}
              xl={12}
              style={{ height: "45vh" }}
            >
              <img
                src={projectOverviewImage}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="Project-Overview"
              />
            </Grid>
            <Grid
              item
              lg={11.8}
              md={11.8}
              xl={11.8}
              xs={11.8}

              style={{
                margin: "1vmax auto",
                padding: "0.8vmax 0.5vmax",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >

            {/* ###  Card Container  ### */}

              <Grid
                container
                spacing={2}
                style={{
                  boxSizing: "border-box",
                  borderRadius: "14px",
                  padding: "1vmax",
                  
                }}
                justifyContent={"center"}
              >
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={10}

                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    heading="General Details"
                    body={[
                      `Location: ${projectDetail?.plantLocation?.name || 'N/A'}`,
                      `Capacity DC [MWp]: ${plantDetail?.dcCapacity|| 'N/A'} `,
                      `Capacity AC [MW]: ${plantDetail?.acCapacity|| 'N/A'}`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showInverterDetails: false,
                        showSMB: false,
                        showAsbuiltDetails: false,
                        showGeneralDetails: !showDetails?.showGeneralDetails,
                      });
                    }}
                    showExpanded={showDetails?.showGeneralDetails}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    heading="As-built Details"
                    body={[
                      `Datasheet: Modules, Inverter(s) and Transformer(s)`,
                      `Cable: Cable Details`,
                      `SLD: DC SLD and AC SLD`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showGeneralDetails: false,
                        showInverterDetails: false,
                        showSMB: false,
                        showAsbuiltDetails: !showDetails?.showAsbuiltDetails,
                      });
                    }}
                    showExpanded={showDetails?.showAsbuiltDetails}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}

                  xs={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    heading="Inverter(s)"
                    body={[
                      `Number of Inverter(s): ${plantDetail?.noOfInverter|| 'N/A'}`,
                      `Specifications: ${plantDetail?.inverterSpecification|| 'N/A'}`,
                      `Inverter Type: ${plantDetail?.inverterType|| 'N/A'
                      }`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showGeneralDetails: false,
                        showSMB: false,
                        showAsbuiltDetails: false,
                        showInverterDetails: !showDetails?.showInverterDetails,
                      });
                    }}
                    showExpanded={showDetails?.showInverterDetails}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    bgColor="#ed7d31"
                    heading={ plantDetail?.string==="STRING"?"STRINGS":`${plantDetail?.string} & String`}
                    body={
                      plantDetail?.string==="STRING"?[`Number of string: ${plantDetail?.noOfString|| 'N/A'}`]:
                      [
                      `Number of ${plantDetail?.string || 'SCB'}: ${plantDetail?.noOfscbsmb|| 'N/A' }`,
                      `Number of string: ${plantDetail?.noOfString|| 'N/A'}`,
                      `String Type: ${plantDetail?.stringType|| 'N/A'}`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showGeneralDetails: false,
                        showInverterDetails: false,
                        showAsbuiltDetails: false,
                        showSMB: !showDetails?.showSMB,
                      });
                    }}
                    showExpanded={showDetails?.showSMB}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ###  GeneralDetails Container  ### */}

            {showDetails?.showGeneralDetails && (
              <Grid
                item
                lg={11.8}
                md={11.8}
                xl={11.8}
                xs={11.8}
                style={{
                  margin: "1rem auto",
                  padding: "1vmax",
                  background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                }}
              >
                <Grid container 
                  style={{
                    backgroundColor: "white",
                    // borderRadius: "14px",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    ref={refForGeneralDetails}
                  >
                    <GeneralDetails title={projectDetail?.plantLocation?.title} url={projectDetail?.plantLocation?.url} />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* ###  AsbuiltDetails Container  ### */}

            {showDetails?.showAsbuiltDetails &&
              (
                <Grid item
                  lg={11.8}
                  md={11.8}
                  xl={11.8}
                  xs={11.8}
                  style={{
                    margin: "1rem auto",
                    padding: "1vmax",
                    background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  }}
                >
                  <Grid container 
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      ref={refAsbuiltDiagram}
                    >
                      <AsBuiltDetails databaseStr={databaseStr} />
                    </Grid>
                  </Grid>
                </Grid>
              )
            }

            {/* ###  InverterDetails Container  ### */}

            {showDetails?.showInverterDetails &&
              (
                <Grid
                  item
                  lg={11.8}
                  md={11.8}
                  xl={11.8}
                  xs={11.8}

                  style={{
                    margin: "1rem auto",
                    padding: "1vmax",
                    background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  }}
                >
                  <Grid container 
                    style={{
                      backgroundColor: "white",
                    }}
                  > <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    style={{
                      margin: "auto",
                    }}
                    ref={refInverterDetails}
                  >
                      <InverterDetails plantDetail={plantDetail}  databaseStr={databaseStr}/>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
            {/* ###  String/SMB Detail Container  ### */}

            {showDetails?.showSMB &&
              (
                <Grid item
                  lg={11.8}
                  md={11.8}
                  xl={11.8}
                  xs={11.8}

                  style={{
                    margin: "1rem auto",
                    padding: "1vmax",
                    background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  }}
                >

                  <Grid container 
                    style={{
                      backgroundColor: "white",
                    }}
                  ><Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    ref={refsmb}
                  >
                      <SmbDetails databaseStr={databaseStr}  />
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </div>
      }
    </>


  );
};

export default ProjOverView;
