import React, { useMemo } from "react";
import "./GenOverView.css";
import { Grid } from "@mui/material";

import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomizeTootip from "../../components/CustomizeTootip";

import GraphLoader from "../../components/GraphLoader";

const ActualPRAcAndDcCUFContainer = ({ loading, acLineLossActualPr }) => {
  const acLineLossMinMaxRange = useMemo(() => {
    if (acLineLossActualPr?.length > 0) {
      return {
        min: Math.floor(
          acLineLossActualPr?.reduce(
            (acc, curr, index) => Math.min(curr["Plant Availability"], acc),
            Infinity
          )
        ),
        max: Math.ceil(
          acLineLossActualPr?.reduce(
            (acc, curr, index) => Math.max(curr["Plant Availability"], acc),
            -Infinity
          )
        ),
      };
    }
    return {};
  }, [acLineLossActualPr]);
  return loading ? (
    <Grid
      item
      lg={3.1}
      md={5.9}
      sm={5.9}
      xs={12}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
      }}
    >
      <GraphLoader />
    </Grid>
  ) : !acLineLossActualPr || acLineLossActualPr?.length == 0 ? (
    <Grid
      item
      lg={3.1}
      md={5.9}
      sm={5.9}
      xs={12}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
      }}
    >
      <div style={{ width: "100%", fontSize: "2vmin" }}>
        <p>Ac Line Loss and Actual PR</p>
        <p>Data Not Available</p>
      </div>
    </Grid>
  ) : (
    <>
      <Grid item lg={3.1} md={5.9} sm={5.9} xs={12} className="gen-overview-2">
        <ResponsiveContainer height="50%" width="100%">
          <AreaChart
            margin={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            data={acLineLossActualPr || []}
          >
            <XAxis dataKey="Date" hide />
            <YAxis
              type="number"
              yAxisId="bar1"
              hide
              domain={[
                Math.floor(
                  acLineLossActualPr?.reduce(
                    (acc, curr, index) =>
                      Math.min(
                        curr["Actual Pr"],
                        curr["Ac Cuf"],
                        curr["Dc Cuf"],
                        acc
                      ),
                    Infinity
                  )
                ) - 1,
                Math.ceil(
                  acLineLossActualPr?.reduce(
                    (acc, curr, index) =>
                      Math.max(
                        curr["Actual Pr"],
                        curr["Ac Cuf"],
                        curr["Dc Cuf"],
                        acc
                      ),
                    -Infinity
                  )
                ) + 1,
              ]}
            />
            <YAxis
              type="number"
              yAxisId="bar2"
              hide
              domain={[
                acLineLossMinMaxRange.min - 2 || 0,
                acLineLossMinMaxRange.max || 0,
              ]}
            />
            <Area
              dataKey="Actual Pr"
              yAxisId="bar1"
              stroke="rgb(11, 165, 119)"
              strokeWidth={0.2}
              fill="rgb(20, 213, 149)"
            />

            <Area
              dataKey="Plant Availability"
              yAxisId="bar2"
              stroke="rgb(11, 165, 119)"
              strokeWidth={0.2}
              fill="rgb(91, 248, 201)"
            />
            <Area
              dataKey="Ac Cuf"
              yAxisId="bar1"
              stroke="rgb(11, 110, 100)"
              strokeWidth={0.2}
              fill="rgb(10, 190, 129)"
            />

            <Area
              dataKey="Dc Cuf"
              yAxisId="bar1"
              stroke="rgb(1, 100, 90)"
              strokeWidth={0.2}
              fill="rgb(10, 190, 129)"
            />
            <Tooltip
              content={
                <CustomizeTootip
                  active={false}
                  payload={[]}
                  label={""}
                  TooltipValues={[
                    "Actual PR",
                    "Plant Availability",
                    "AC CUF",
                    "DC CUF",
                  ]}
                />
              }
            />
          </AreaChart>
        </ResponsiveContainer>
        <div>
          <div>
            <span>
              {Number((
                acLineLossActualPr?.reduce(
                  (acc, curr) => acc + Number(curr["Actual Pr"]),
                  0
                ) / acLineLossActualPr?.length
              ).toFixed(2))}
            </span>
            <span>
              Actual PR <p>(%)</p>{" "}
            </span>
          </div>
          <span
            style={{
              width: "2px",
              background: "rgb(166, 176, 173)",
              height: "90%",
            }}
          ></span>
          <div>
            <span>
              {(
                acLineLossActualPr?.reduce(
                  (acc, curr) => acc + Number(curr["Plant Availability"]),
                  0
                ) / acLineLossActualPr?.length
              ).toFixed(0)}
            </span>
            <span>
              Plant Availability <p>(%)</p>{" "}
            </span>
          </div>
          <span
            style={{
              width: "2px",
              background: "rgb(166, 176, 173)",
              height: "90%",
            }}
          ></span>
          <div>
            <span>
              {Number((
                acLineLossActualPr?.reduce(
                  (acc, curr) => acc + Number(curr["Ac Cuf"]),
                  0
                ) / acLineLossActualPr?.length
              ).toFixed(2))}
            </span>
            <span>
              AC CUF <p>(%)</p>{" "}
            </span>
          </div>

          <span
            style={{
              width: "2px",
              background: "rgb(166, 176, 173)",
              height: "90%",
            }}
          ></span>
          <div>
            <span>
              {Number((
                acLineLossActualPr?.reduce(
                  (acc, curr) => acc + Number(curr["Dc Cuf"]),
                  0
                ) / acLineLossActualPr?.length
              ).toFixed(2))}
            </span>
            <span>
              DC CUF <p>(%)</p>{" "}
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ActualPRAcAndDcCUFContainer;
