import React from "react";
import { Bar, ComposedChart, XAxis, ResponsiveContainer } from "recharts";
import GraphLoader from "../../../../components/GraphLoader";

const PvsystActualGHIContainer = ({GHIGTI,loading,progressValueAnimate, setProgressValueAnimate}) => {
  return (
    <div className="detail_overview_1">
      {loading ? (
        <div style={{ height: "100%", width: "100%" }}>
          <GraphLoader />
        </div>
      ) : !GHIGTI || GHIGTI?.length == 0 ? (
        <div style={{ height: "100%", width: "100%", fontSize: "2vmin" }}>
          <p>PVsyst GHI (kWh/m^2) and Actual GHI (kWh/m^2)</p>
          <p>Data Not Available</p>
        </div>
      ) : (
        <>
          <div>
            <div>
              <div>
                <progress
                  className="progress_4"
                  style={{
                    accentColor: "green",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress4
                      ? "3px solid red"
                      : "none",
                  }}
                  value={parseFloat(
                    GHIGTI?.reduce(
                      (acc, curr) =>
                        curr["GHI (KWh/m^2)"]
                          ? acc + curr["GHI (KWh/m^2)"]
                          : acc,
                      0
                    )
                  ).toFixed(2)}
                  max={
                    Math.ceil(
                      GHIGTI?.reduce(
                        (acc, curr) =>
                          curr["GHI (KWh/m^2)"]
                            ? acc + curr["GHI (KWh/m^2)"]
                            : acc,
                        0
                      )
                    ) + 100 || 0
                  }
                />
              </div>
              <div>
                <span>PVsyst GHI (kWh/m^2)</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      (parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>
                            curr["GHI (KWh/m^2)"]
                              ? acc + curr["GHI (KWh/m^2)"]
                              : acc,
                          0
                        )
                      ).toFixed(2) *
                        100) /
                        Math.ceil(
                          GHIGTI?.reduce(
                            (acc, curr) =>
                              curr["GHI (KWh/m^2)"]
                                ? acc + curr["GHI (KWh/m^2)"]
                                : acc,
                            0
                          ) + 100
                        ) -
                      3
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span>
                  {parseFloat(
                    GHIGTI?.reduce(
                      (acc, curr) =>
                        curr["GHI (KWh/m^2)"]
                          ? acc + curr["GHI (KWh/m^2)"]
                          : acc,
                      0
                    )
                  ).toFixed(2)}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_5"
                  style={{
                    accentColor: "green",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress5
                      ? "3px solid black"
                      : "none",
                  }}
                  value={
                    parseFloat(
                      GHIGTI?.reduce(
                        (acc, curr) =>
                          curr["Actual GHI (kWh/m^2)"]
                            ? acc + curr["Actual GHI (kWh/m^2)"]
                            : acc,
                        0
                      )
                    ).toFixed(2) || 0
                  }
                  max={
                    Math.ceil(
                      GHIGTI?.reduce(
                        (acc, curr) =>
                          curr["Actual GHI (kWh/m^2)"]
                            ? acc + curr["Actual GHI (kWh/m^2)"]
                            : acc,
                        0
                      )
                    ) + 100 || 0
                  }
                />
              </div>
              <div>
                <span>Actual GHI (kWh/m^2)</span>
                {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      (parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>
                            curr["Actual GHI (kWh/m^2)"]
                              ? acc + curr["Actual GHI (kWh/m^2)"]
                              : acc,
                          0
                        )
                      ).toFixed(2) *
                        100) /
                        (Math.ceil(
                          GHIGTI?.reduce(
                            (acc, curr) =>
                              curr["Actual GHI (kWh/m^2)"]
                                ? acc + curr["Actual GHI (kWh/m^2)"]
                                : acc,
                            0
                          )
                        ) +
                          100) -
                        3 || 0
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span >
                  {parseFloat(
                    GHIGTI?.reduce(
                      (acc, curr) =>
                        curr["Actual GHI (kWh/m^2)"]
                          ? acc + curr["Actual GHI (kWh/m^2)"]
                          : acc,
                      0
                    )
                  ).toFixed(2) || 0}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_3"
                  style={{ accentColor: "blue" }}
                  value={Math.abs(
                    parseFloat(
                      ((parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>
                            curr["GHI (KWh/m^2)"]
                              ? acc + curr["GHI (KWh/m^2)"]
                              : acc,
                          0
                        )
                      ).toFixed(2) -
                        parseFloat(
                          GHIGTI?.reduce(
                            (acc, curr) =>
                              curr["Actual GHI (kWh/m^2)"]
                                ? acc + curr["Actual GHI (kWh/m^2)"]
                                : acc,
                            0
                          )
                        ).toFixed(2)) *
                        100) /
                        parseFloat(
                          GHIGTI?.reduce(
                            (acc, curr) =>
                              curr["GHI (KWh/m^2)"]
                                ? acc + curr["GHI (KWh/m^2)"]
                                : acc,
                            0
                          )
                        ).toFixed(2)
                    ).toFixed(2) || 0
                  )}
                  max="100"
                />
              </div>
              <div>
                <span>Excess(+)/ShortFall(-) (%)</span>
                {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      Math.abs(
                        parseFloat(
                          ((parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) =>
                                curr["Actual GHI (kWh/m^2)"]
                                  ? acc + curr["Actual GHI (kWh/m^2)"]
                                  : acc,
                              0
                            )
                          ).toFixed(2) -
                            parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) =>
                                  curr["GHI (KWh/m^2)"]
                                    ? acc + curr["GHI (KWh/m^2)"]
                                    : acc,
                                0
                              )
                            ).toFixed(2)) *
                            100) /
                            parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) =>
                                  curr["GHI (KWh/m^2)"]
                                    ? acc + curr["GHI (KWh/m^2)"]
                                    : acc,
                                0
                              )
                            ).toFixed(2)
                        ).toFixed(2)
                      ) - 3
                    }%`,
                    bottom: "60%",
                  }}
                >
                  <i class="fa-solid fa-caret-up"></i>
                </span>
                <span>
                  {parseFloat(
                    ((parseFloat(
                      GHIGTI?.reduce(
                        (acc, curr) =>
                          curr["Actual GHI (kWh/m^2)"]
                            ? acc + curr["Actual GHI (kWh/m^2)"]
                            : acc,
                        0
                      )
                    ).toFixed(2) -
                      parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>
                            curr["GHI (KWh/m^2)"]
                              ? acc + curr["GHI (KWh/m^2)"]
                              : acc,
                          0
                        )
                      ).toFixed(2)) *
                      100) /
                      parseFloat(
                        GHIGTI?.reduce(
                          (acc, curr) =>
                            curr["GHI (KWh/m^2)"]
                              ? acc + curr["GHI (KWh/m^2)"]
                              : acc,
                          0
                        )
                      ).toFixed(2)
                  ).toFixed(2) || 0}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>
                {parseFloat(
                  GHIGTI?.reduce(
                    (acc, curr) =>
                      curr["GHI (KWh/m^2)"] ? acc + curr["GHI (KWh/m^2)"] : acc,
                    0
                  )
                ).toFixed(2) || 0}
              </span>
              <span>PVSYST GHI (kWh/m^2)</span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={
                      GHIGTI?.reduce((acc, curr) => {
                        if (curr["GHI (KWh/m^2)"] && curr["name"]) {
                          acc.push(curr);
                        }
                        return acc;
                      }, []) || []
                    }
                  >
                    <XAxis dataKey="name" hide />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GHI"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={14}
                      dataKey="GHI (KWh/m^2)"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                className="progress_button_1"
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress4: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress4: false,
                  })
                }
              >
                View
              </span>
            </div>
            <div>
              <span>
                {parseFloat(
                  GHIGTI?.reduce(
                    (acc, curr) =>
                      curr["Actual GHI (kWh/m^2)"]
                        ? acc + curr["Actual GHI (kWh/m^2)"]
                        : acc,
                    0
                  )
                ).toFixed(2) || 0}
              </span>
              <span>Actual GHI (kWh/m^2) </span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={
                      GHIGTI?.reduce((acc, curr) => {
                        if (curr["Actual GHI (kWh/m^2)"] && curr["name"]) {
                          acc.push(curr);
                        }
                        return acc;
                      }, []) || []
                    }
                  >
                    <XAxis dataKey="name" hide />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Actual GHI"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={14}
                      dataKey="Actual GHI (kWh/m^2)"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress5: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress5: false,
                  })
                }
                className="progress_button_2"
              >
                View
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PvsystActualGHIContainer;
