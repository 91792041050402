import React, { useMemo, useRef, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors1, colors3 } from "../../colors/color";
import CustomizeTootip from "../../components/CustomizeTootip";
import CustomizeLegend from "../../components/CustomizeLegend";
import Printer2 from "../../components/Printer2";

import GraphLoader from "../../components/GraphLoader";
const PvsystActualGTI = ({ barColors, GHIGTI, loading }) => {
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({
    pvsystGII: true,
    actualGII: true,
    pvsystVsActualGII: true,
    all: true,
  });

  const minMaxRangeAndGraphData = useMemo(() => {
    if (GHIGTI && GHIGTI?.length > 0) {
      const graphData =
        GHIGTI?.reduce((acc, curr) => {
          if (
            curr["Date"] &&
            curr["PVsyst GII"] &&
            curr["Actual GII"] &&
            curr["PVsyst GII Vs Actual GII"]
          ) {
            acc.push({
              Date: curr.Date,
              PVsystGII: curr["PVsyst GII"],
              ActualGII: curr["Actual GII"],
              PVsystGIIvsActualGII: curr["PVsyst GII Vs Actual GII"],
            });
          }

          return acc;
        }, []) || [];
      return {
        graphData,
        min: Math.floor(
          graphData?.reduce(
            (acc, curr, index) =>
              Math.min(curr["PVsystGII"], curr["ActualGII"], acc),
            Infinity
          )
        ),
        max: Math.ceil(
          graphData?.reduce(
            (acc, curr, index) =>
              Math.max(curr["PVsystGII"], curr["ActualGII"], acc),
            -Infinity
          )
        ),
      };
    }
    return {
      graphData: [],
    };
  }, [GHIGTI]);

  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>
          PVsyst GII (kWh/m^2) vs Actual GII (kWh/m^2) and
          Excess(+)/Shortfall(-) (%)
        </h5>
        <Printer2
          dataSetDetail={{
            data: minMaxRangeAndGraphData.graphData,
            name: "Pvsyst And Actual GTI",
            ref: graphRef,
            refCurrent: graphRef.current?.container,
          }}
        />
      </div>

      <div
        style={{
          minWidth: "100%",
          width:
            minMaxRangeAndGraphData.graphData?.length > 0
              ? minMaxRangeAndGraphData.graphData.length *
                  Object.keys(minMaxRangeAndGraphData.graphData[0]).length *
                  14 +
                "px"
              : "100%",
        }}
        className="detailed-responsive-container-1"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : minMaxRangeAndGraphData.graphData?.length > 0 ? (
            <ComposedChart
              data={minMaxRangeAndGraphData.graphData || []}
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              }}
              barCategoryGap={"20%"}
              ref={graphRef}
              maxBarSize={30}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="Date"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickFormatter={(v) =>
                  v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
                }
                yAxisId="left-axis"
                label={{
                  value: "PVsyst GII & Actual GII",
                  angle: -90,
                  position: "insideBottomLeft",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
                domain={[
                  minMaxRangeAndGraphData.min - minMaxRangeAndGraphData.min / 2,
                  minMaxRangeAndGraphData.max + minMaxRangeAndGraphData.min / 4,
                ]}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                yAxisId="right-axis"
                orientation="right"
                // label={<AxisLabel axisType='yAxis' x={30} y={300}>PVsyst Vs Actual GTI</AxisLabel>}
                label={{
                  value: "PVsyst GII Vs Actual GII",
                  angle: 90,
                  // transform: "translateX(1)",
                  position: "insideBottomRight",
                  fontSize: "0.8rem",
                  // dy: -160,
                  fontWeight: 600,
                }}
                domain={[
                  Math.floor(
                    GHIGTI?.reduce(
                      (acc, curr, index) =>
                        Math.min(curr["PVsyst GII Vs Actual GII"], acc),
                      Infinity
                    )
                  ) - 2,
                  Math.ceil(
                    GHIGTI?.reduce(
                      (acc, curr, index) =>
                        Math.max(curr["PVsyst GII Vs Actual GII"], acc),
                      -Infinity
                    )
                  ) + 2,
                ]}
              />
              <Tooltip
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={[
                      "PVsyst GII",
                      "Actual GII",
                      "Excess/Shortfall",
                    ]}
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={[
                      "PVsyst GII",
                      "Actual GII",
                      "Excess/Shortfall",
                    ]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />

              <Bar
                hide={showBarData.pvsystGII ? false : true}
                dataKey={"PVsystGII"}
                fill={` ${barColors[0] || colors3[0]}`}
                yAxisId="left-axis"
              />

              <Bar
                hide={showBarData.actualGII ? false : true}
                dataKey={"ActualGII"}
                fill={`${barColors[1] || colors3[1]}`}
                yAxisId="left-axis"
              />

              <Line
                hide={showBarData.pvsystVsActualGII ? false : true}
                type="monotone"
                dataKey={"PVsystGIIvsActualGII"}
                strokeWidth={3}
                stroke={`${colors1[4]}`}
                yAxisId="right-axis"
              />
            </ComposedChart>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3>Data Not Available</h3>
            </div>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PvsystActualGTI;
