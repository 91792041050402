import React, { useEffect, useMemo, useRef, useState } from "react";


import CustomizeTootip from "../../components/CustomizeTootip";
import CustomizeLegend from "../../components/CustomizeLegend";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors3 } from "../../colors/color";
import "./scbsmb.css";
import Printer2 from "../../components/Printer2";

import GraphLoader from "../../components/GraphLoader";
import { useSelector } from "react-redux";

const SmbStringLoss = ({ title, scbsmbDetail }) => {
  const { scbsmbLoss = [], loading } = useSelector((state) => state.scbsmbLoss);

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showEfficiencyYearlyLoss, setShowEfficiencyYearlyLoss] = useState({});
  const [tooltipAdjust, setTooltipAdjust] = useState({ x: null, y: null });

  /* ###   Reference to download graph data in CSV format   ### */

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef = useRef(null);

  const [scbsmbStringDeviation, setScbsmbStringDeviation] = useState(null);

  useEffect(() => {
    if (scbsmbDetail.scbsmbType === "SMB") {
      if (scbsmbLoss?.length > 0) {
        let obj = {};
        Object.keys(scbsmbLoss[0] || {}).forEach((e) => {
          if (e !== "Date") {
            obj[e] = true;
          }
        });
        obj["all"] = true;

        setShowEfficiencyYearlyLoss(obj);
        setScbsmbStringDeviation(scbsmbLoss);
      }
    }
  }, [scbsmbLoss]);
  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h3>
          String Current Average Loss{" "}
          {scbsmbDetail?.scbsmbType === "SMB" && `(${title})`}
        </h3>
        <Printer2
          
          dataSetDetail={{
            data:
              scbsmbStringDeviation || [],
            name: "SMB String Deviation",
            ref:graphRef,
            refCurrent:graphRef.current?.container
          }}
        />
      </div>

      <div
        style={{
          minWidth: "100%",
          width: loading
            ? "100%"
            : scbsmbStringDeviation?.length > 0
            ? scbsmbStringDeviation.length *
                Object.keys(scbsmbStringDeviation[0] || {}).length *
                40 +
              "px"
            : "100%",
        }}
        className="scbsmb-responsive-container-1"
      >
        <ResponsiveContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : scbsmbStringDeviation?.length>0 ? (
            <ComposedChart
              style={{ overflow: "hidden" }}
              onMouseMove={(e) => {
                if (e.isTooltipActive) {
                  setTooltipAdjust({
                    x: e.chartX > 20 ? e.chartX - 20 : e.chartX,
                    y: e.chartY > 150 ? 150 : e.chartY,
                  });
                }
              }}
              data={scbsmbStringDeviation || []}
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              }}
              ref={graphRef}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="Date"
                fontSize={"0.8rem"}
                fontWeight={600}
                // tickLine={false}
                // axisLine={false}
                tickMargin={5}
                // padding={{left:10,right:10}}
                minTickGap={10}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                width={30}
                domain={[
                  Math.floor(
                    scbsmbStringDeviation?.reduce((acc, curr, index) => {
                      Object.keys(curr || {}).forEach((e) => {
                        if (e !== "Date") {
                          acc = Math.min(curr[e], acc);
                        }
                      });
                      return acc;
                    }, Infinity)
                  ) - 2,

                  Math.ceil(
                    scbsmbStringDeviation?.reduce((acc, curr, index) => {
                      Object.keys(curr || {}).forEach((e) => {
                        if (e !== "Date") {
                          acc = Math.max(curr[e], acc);
                        }
                      });
                      return acc;
                    }, -Infinity)
                  ) + 2,
                ]}
                tickLine={false}
              />
              <Tooltip
                position={tooltipAdjust}
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={
                      scbsmbStringDeviation?.length > 0
                        ? Object.keys(scbsmbStringDeviation[0] || {}).filter(
                            (e) => e !== "Date"
                          )
                        : []
                    }
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={
                      scbsmbStringDeviation?.length > 0
                        ? Object.keys(scbsmbStringDeviation[0] || {}).filter(
                            (e) => e !== "Date"
                          )
                        : []
                    }
                    data={showEfficiencyYearlyLoss}
                    setData={setShowEfficiencyYearlyLoss}
                  />
                }
                styles={{
                  fontSize: "1rem",
                }}
              />
              <ReferenceLine
                y={0}
                stroke="#000"
                //  strokeDasharray="3 3"
              />

              {scbsmbStringDeviation?.length > 0 &&
                Object.keys(scbsmbStringDeviation[0] || {}).map((e, index) => {
                  if (e === "Date") return null;
                  return (
                    <Bar
                      maxBarSize={30}
                      key={index}
                      hide={showEfficiencyYearlyLoss[e] ? false : true}
                      dataKey={e}
                      fill={`${colors3[0]}`}
                    >
                      <LabelList
                        dataKey={e}
                        position="top"
                        fontSize={"0.7rem"}
                        fontWeight={600}
                        fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                      />
                    </Bar>
                  );
                })}
            </ComposedChart>
          ) : (
            <h3>Data Not Available</h3>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default SmbStringLoss;
