import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./CountryHeader2.css";
import { useLocation, useNavigate } from "react-router-dom";
import PageURL from "../../constants/PageURL";
import logo from "../../fonts and images/newLogo.PNG";
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { projectDetailAction } from "../../actions/energyAction";
import { project_detail_reset } from "../../constants/dataConstants";
import ProjectDetailHeader from "./ProjectDetailHeader";
import ProjectDetailHeader2 from "./ProjectDetailHeader2";

const initialOpenSubHeader = {
  projectDetail: false,
  Diagonistic: false,
  inverterEfficiency: false,
  inverterDeviation: false,
  checkedData: false,
  Prescritive: false,
  string_smb: false,
  correctiveAction: false,
};

const CountryHeader2 = (props) => {
  const {
    openSidebar,
    setOpenSidebar,
    selectedValue,
    setSelectedValue,
    scbsmbDetail,
    setscbsmbDetail,
    selectedLebel,
    databaseStr,
  } = props;

  const { user = {} } = useSelector((state) => state.user);
  const {
    projectDetail,
    availableProject,
    loading: loading2,
    scbSmbMappingArray,
    scbSmbMappingLoader,
    inverterMappingArray,
    inverterMappingLoader,
  } = useSelector((state) => state.projectDetail);
  const [checkedData, setCheckedData] = React.useState(initialOpenSubHeader);

  const [openSelect, setOpenSelect] = useState({
    country: false,
    partnersName: false,
    projectName: false,
    projectDetail: false,
    projectDetail2: false,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const serachParams = new URLSearchParams(location.search);

  const onclickHandler = (name, trueValue1, trueValue2, trueValue3) => {
    let checked = { ...checkedData };
    for (let key in checked) {
      if (key === name) {
        checked[key] = !checked[key];
      } else {
        checked[key] = false;
      }
    }
    if (trueValue1) {
      checked[trueValue1] = true;
    }
    if (trueValue2) {
      checked[trueValue2] = true;
    }
    if (trueValue3) {
      checked[trueValue3] = true;
    }

    setCheckedData(checked);
  };

  window.onresize = () => {
    if (openSidebar && window.innerWidth >= 950) {
      setOpenSidebar(false);
    }
  };

  useEffect(() => {
    if (databaseStr) {
      dispatch(projectDetailAction(user?.userName, databaseStr));
    }
  }, [databaseStr]);

  // useEffect(() => {
  //   if (scbSmbMappingArray && checkedData.string_smb) {
  //     const obj1 = {};
  //     const stringArray = scbSmbMappingArray || [];
  //     stringArray.forEach((element) => {
  //       obj1[element.name] = false;
  //       element.inverters?.forEach((e) => {
  //         if(e?.stacks){
  //           obj1[element.name + e.name] = false;
  //           e?.stacks?.forEach((ee)=>{
  //             obj1[element.name+e?.name+ee?.name]=false
  //           })

  //         }else {
  //           obj1[element.name + e.name] = false;

  //         }
  //       });

  //       setCheckedData({
  //         ...checkedData,
  //         ...obj1,
  //       });
  //     });
  //   }
  //   if (
  //     inverterMappingArray &&
  //     (checkedData.inverterEfficiency || checkedData.inverterDeviation)
  //   ) {
  //     const inverterDetail = inverterMappingArray || [];
  //     const obj2 = {};
  //     inverterDetail.forEach((element) => {
  //       if (element?.inverters?.["0"]?.stacks) {
  //         obj2[element?.name+"Efficiency"] = false;
  //         obj2[element?.name+"Deviation"] = false;
  //         element?.inverters?.forEach((element2) => {
  //           obj2[element?.name + element2?.name+"Efficiency"] = false;
  //           obj2[element?.name + element2?.name+"Deviation"] = false;
  //         });
  //       } else {
  //         obj2[element.name + "Efficiency"] = false;
  //         obj2[element.name + "Deviation"] = false;
  //       }
  //     });
  //     setCheckedData({
  //       ...checkedData,
  //       ...obj2,
  //     });
  //   }
  // }, [inverterMappingArray, scbSmbMappingArray]);

  useEffect(() => {
    if (scbSmbMappingArray && checkedData.string_smb) {
      const obj1 = {};
      const stringArray = scbSmbMappingArray || [];
      
      stringArray.forEach((element) => {
        obj1[element.name] = false;
      
        // Handle subBlocks first
        element.subBlocks?.forEach((subBlock) => {
          obj1[element.name + subBlock.name] = false;
      
          subBlock.inverters?.forEach((e) => {
            if (e?.stacks) {
              obj1[element.name + subBlock.name + e.name] = false;
      
              e?.stacks?.forEach((ee) => {
                obj1[element.name + subBlock.name + e?.name + ee?.name] = false;
              });
            } else {
              obj1[element.name + subBlock.name + e.name] = false;
            }
          });
        });
      
        // If no subBlocks, handle inverters directly
        if (!element.subBlocks) {
          element.inverters?.forEach((e) => {
            if (e?.stacks) {
              obj1[element.name + e.name] = false;
      
              e?.stacks?.forEach((ee) => {
                obj1[element.name + e?.name + ee?.name] = false;
              });
            } else {
              obj1[element.name + e.name] = false;
            }
          });
        }
      });
      
      // Update the state with newly structured data
      setCheckedData({
        ...checkedData,
        ...obj1,
      });

    
    }
    if (
      inverterMappingArray &&
      (checkedData.inverterEfficiency || checkedData.inverterDeviation)
    ) {
      const inverterDetail = inverterMappingArray || [];
      const obj2 = {};
      inverterDetail.forEach((element) => {
        if (element?.subBlocks) {
          obj2[element?.name + "Efficiency"] = false;
          obj2[element?.name + "Deviation"] = false;
          element?.subBlocks?.forEach((element2) => {
            if (element2?.inverters?.["0"]?.stacks) {
              obj2[element?.name + element2?.name + "Efficiency"] = false;
              obj2[element?.name + element2?.name + "Deviation"] = false;
              element2?.inverters?.forEach((element3) => {
                obj2[
                  element?.name + element2?.name + element3?.name + "Efficiency"
                ] = false;
                obj2[
                  element?.name + element2?.name + element3?.name + "Deviation"
                ] = false;
              });
            } else {
              obj2[element?.name + element2.name + "Efficiency"] = false;
              obj2[element?.name + element2.name + "Deviation"] = false;
            }
          });
        } else {
          if (element?.inverters?.["0"]?.stacks) {
            obj2[element?.name + "Efficiency"] = false;
            obj2[element?.name + "Deviation"] = false;
            element?.inverters?.forEach((element2) => {
              obj2[element?.name + element2?.name + "Efficiency"] = false;
              obj2[element?.name + element2?.name + "Deviation"] = false;
            });
          } else {
            obj2[element.name + "Efficiency"] = false;
            obj2[element.name + "Deviation"] = false;
          }
        }
      });
      setCheckedData({
        ...checkedData,
        ...obj2,
      });
    }
  }, [inverterMappingArray, scbSmbMappingArray]);

  useEffect(() => {
    if (availableProject) {
      dispatch({ type: project_detail_reset });
      if (location.pathname !== PageURL.PROJECT_DETAILS) {
        navigate(`${PageURL.PROJECT_DETAILS}?${serachParams.toString()}`);
      }
    }
    if (!projectDetail) {
      setCheckedData(initialOpenSubHeader);
    }
  }, [projectDetail, navigate, availableProject]);

  return (
    <div className="header">
      {/*  ##### GreenEnco Logo Start ##### */}

      <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)}>
        <img src={logo} alt="GreenEnco-Logo" />
      </div>
      {/*  ##### GreenEnco Logo End ##### */}

      <h2>Creating A Better World In A Changing Climate</h2>

      {/*  ##### Code For Header Sidebar In Small Devices Start ##### */}

      <div className="header-open-detail">
        <p
          style={{
            width: "45px",
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <i
            onClick={() => setOpenSidebar((val) => !val)}
            className={`fa-solid ${
              openSidebar ? "fa-bars-staggered" : "fa-bars"
            }`}
          ></i>
        </p>
      </div>
      {/*  */}
      {/*  ##### Code For Header Sidebar In Small Devices End ##### */}

      <div className="sidebar-2" style={{ height: openSidebar ? "60px" : "0" }}>
        <span
          onClick={() =>
            setOpenSelect({ ...openSelect, country: !openSelect.country })
          }
        >
          <FormControl
            variant="filled"
            style={{ color: "white" }}
            sx={{ width: "100%", height: "100%", color: "white" }}
          >
            <InputLabel
              // shrink={
              //   openSelect.country || selectedValue.country !== ""
              //     ? true
              //     : false
              // }
              id="demo-simple-select-filled-label11"
              style={{ color: "white" }}
              sx={{
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Country
            </InputLabel>
            <Select
              open={openSelect.country ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label11"
              id="demo-simple-select-filled11"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  country: e.target.value,
                  partnerName: "",
                  projectName: "",
                  projectDetail: "",
                });

                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              value={selectedValue.country || ""}
              defaultValue={selectedValue.country || ""}
              renderValue={() => selectedValue.country || ""}
            >
              {user?.projects?.country?.length == 0 ||
                (!user?.projects?.country && (
                  <MenuItem value={""}>
                    <em>None</em>{" "}
                  </MenuItem>
                ))}

              {user?.projects?.country?.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                    {user?.projects?.partnersName?.find(
                      (e) => e.country === value
                    )?.partnerName?.length
                      ? ` - [ ${
                          user?.projects?.partnersName?.find(
                            (e) => e.country === value
                          )?.partnerName?.length
                        } ]`
                      : null}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={() =>
            setOpenSelect({
              ...openSelect,
              partnersName: !openSelect.partnersName,
            })
          }
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              // shrink={
              //   openSelect.partnersName || selectedValue.partnerName !== ""
              //     ? true
              //     : false
              // }
              id="demo-simple-select-filled-label1"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Partners Name
            </InputLabel>
            <Select
              open={openSelect.partnersName ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  partnerName: e.target.value,
                  projectName: "",
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              renderValue={() => selectedValue.partnerName || ""}
              value={selectedValue.partnerName || ""}
              defaultValue={selectedValue.partnerName || ""}
            >
              {!selectedValue.country && (
                <MenuItem value={""}>
                  <em>Select a Country</em>{" "}
                </MenuItem>
              )}

              {user?.projects.partnersName
                ?.find((e) => e.country === selectedValue.country)
                ?.partnerName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value}
                      {user?.projects?.projectName?.find(
                        (e) =>
                          e.country === selectedValue.country &&
                          e.partnerName === value
                      )?.projectName?.length
                        ? ` - [ ${
                            user?.projects?.projectName?.find(
                              (e) =>
                                e.country === selectedValue.country &&
                                e.partnerName === value
                            )?.projectName?.length
                          } ]`
                        : null}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={() =>
            setOpenSelect({
              ...openSelect,
              projectName: !openSelect.projectName,
            })
          }
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              // shrink={
              //   openSelect.projectName || selectedValue.projectName !== ""
              //     ? true
              //     : false
              // }
              id="demo-simple-select-filled-label2"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Name
            </InputLabel>
            <Select
              open={openSelect.projectName ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  projectName: e.target.value,
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              value={selectedValue.projectName || ""}
              defaultValue={""}
            >
              {!selectedValue.partnerName && (
                <MenuItem value={""}>
                  <em>Select a Partner Name</em>{" "}
                </MenuItem>
              )}

              {user?.projects?.projectName
                ?.find(
                  (e) =>
                    e.country === selectedValue.country &&
                    e.partnerName === selectedValue.partnerName
                )
                ?.projectName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {`${value.name} (${value.capacity})`}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={(e) => {
            setOpenSelect({
              ...openSelect,
              projectDetail: !openSelect.projectDetail,
            });
          }}
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              shrink={
                openSelect.projectDetail || selectedValue.projectDetail !== ""
                  ? true
                  : false
              }
              id="demo-simple-select-filled-label3"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Detail
            </InputLabel>
            <Select
              open={openSelect.projectDetail ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{
                color: "white",
                fontSize: "1.8vmin",
              }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              // onChange={(e) => {
              //   setSelectedValue({
              //     ...selectedValue,
              //     projectDetail: e.target.value,
              //   });
              // }}
              defaultValue={selectedValue.projectDetail || ""}
              value={selectedValue.projectDetail || ""}
              renderValue={() => selectedValue.projectDetail || ""}
            >
              <ProjectDetailHeader2
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                scbsmbDetail={scbsmbDetail}
                setscbsmbDetail={setscbsmbDetail}
                databaseStr={databaseStr}
                checkedData={checkedData}
                onclickHandler={onclickHandler}
              />
            </Select>
          </FormControl>
        </span>
      </div>

      {/*  ##### Code For Header Sidebar In Large Devices Start ##### */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "0vmax",
        }}
        className="detail-link"
      >
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Country
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true,
                }
              }
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  country: e.target.value,
                  partnerName: "",
                  projectName: "",
                  projectDetail: "",
                });

                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
              value={selectedValue.country || ""}
              defaultValue={selectedValue.country || ""}
              renderValue={() => selectedValue.country || ""}
            >
              {user?.projects?.country?.length == 0 ||
                (!user?.projects?.country && (
                  <MenuItem value={""}>
                    <em>None</em>{" "}
                  </MenuItem>
                ))}

              {user?.projects?.country?.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                    {user?.projects?.partnersName?.find(
                      (e) => e.country === value
                    )?.partnerName?.length
                      ? ` - [ ${
                          user?.projects?.partnersName?.find(
                            (e) => e.country === value
                          )?.partnerName?.length
                        } ]`
                      : null}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label1"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Partners Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  partnerName: e.target.value,
                  projectName: "",
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              renderValue={() => selectedValue.partnerName || ""}
              value={selectedValue.partnerName || ""}
              defaultValue={selectedValue.partnerName || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {!selectedValue.country && (
                <MenuItem value={""}>
                  <em>Select a Country</em>{" "}
                </MenuItem>
              )}

              {user?.projects?.partnersName
                ?.find((e) => e.country === selectedValue.country)
                ?.partnerName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value} onClick={() => {}}>
                      {value}
                      {user?.projects?.projectName?.find(
                        (e) =>
                          e.country === selectedValue.country &&
                          e.partnerName === value
                      )?.projectName?.length
                        ? ` - [ ${
                            user?.projects?.projectName?.find(
                              (e) =>
                                e.country === selectedValue.country &&
                                e.partnerName === value
                            )?.projectName?.length
                          } ]`
                        : null}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label2"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  projectName: e.target.value,
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              value={selectedValue.projectName || ""}
              // renderValue={()=>selectedValue.projectName || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {!selectedValue.partnerName && (
                <MenuItem value={""}>
                  <em>Select a Partner Name</em>{" "}
                </MenuItem>
              )}

              {user?.projects?.projectName
                ?.find(
                  (e) =>
                    e.country === selectedValue.country &&
                    e.partnerName === selectedValue.partnerName
                )
                ?.projectName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {`${value.name} (${value.capacity})`}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <div
          className="dropdown"
          onClick={(e) => {
            setOpenSelect({
              ...openSelect,
              projectDetail2: !openSelect.projectDetail2,
            });
          }}
        >
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label3"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Detail
            </InputLabel>
            <Select
              open={openSelect.projectDetail2 ? true : false}
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              // onChange={(e) => {
              //   setSelectedValue({
              //     ...selectedValue,
              //     projectDetail: e.target.value,
              //   });
              // }}
              defaultValue={selectedValue.projectDetail || ""}
              value={selectedValue.projectDetail || ""}
              renderValue={() => selectedValue.projectDetail || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              <ProjectDetailHeader2
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                scbsmbDetail={scbsmbDetail}
                setscbsmbDetail={setscbsmbDetail}
                databaseStr={databaseStr}
                checkedData={checkedData}
                onclickHandler={onclickHandler}
              />
            </Select>
          </FormControl>
        </div>
      </div>

      {/*  ##### Code For Header Sidebar In Large Devices End ##### */}
    </div>
  );
};

export default CountryHeader2;
