import "./App.css";
import PageURL from "../src/constants/PageURL";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AuthModule from "../src/modules/auth/AuthModule";
import ProtectedRoute from "./modules/auth/ProtectedRoute";
import CountryModule from "./modules/countries/CountryModule";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadUser } from "./actions/userActions";
import Footer from "./components/Footer";
// import Analytics from './modules/countries/components/Analyctis/Analytics';
import CountryDefault from "./modules/countries/components/CountryDefault";
import SpinLoader from "./components/SpinLoader";
import ProjectProtectedRoute from "./modules/countries/ProjectProtectedRoute";

function App() {
  const { user, error, loadingUser } = useSelector((state) => state.user);
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, []);
  const path = location.pathname;
  return (
    <>
      {loadingUser && <SpinLoader />}
      <div className="App">
        <ToastContainer />
        {location.pathname === PageURL.BASE && user && (
          <Navigate to="/dashboard" />
        )}

        <Routes>
          <Route path={PageURL.BASE} element={<AuthModule />}></Route>
          <Route path={PageURL.SIGN_UP} element={<AuthModule />}></Route>
          <Route
            path={PageURL.FORGOT_PASSWORD}
            element={<AuthModule />}
          ></Route>
          <Route
            path={PageURL.OTP_VERIFICATION}
            element={<AuthModule />}
          ></Route>

          <Route element={<ProtectedRoute />}>
            <Route path={PageURL.COUNTRYDEFAULT} element={<CountryDefault />} />
            <Route path={PageURL.PROJECT_DETAILS} element={<CountryModule />} />
              <Route
                path={PageURL.INDIA_GEN_SUMMARY}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_PRES_MODEL_Loss_Flow}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_PRES_MODEL_Fault_Tree}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_PROJ_OVERVIEW}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_DIAGONISTIC}
                element={<CountryModule />}
              ></Route>
              <Route
                path={PageURL.INDIA_DIAGONISTIC_DETAILED}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_LOSS_FLOW}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_INVERTER_EFFICIENCY}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_INVERTER_DEVIATION}
                element={<CountryModule />}
              />

              <Route path={PageURL.SCBSMB_LOSS} element={<CountryModule />} />
              {location.pathname ===
                PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM && (
                <Route path={path} element={<CountryModule />} />
              )}
              <Route
                path={PageURL.PROJECT_ANALYTICS}
                element={<CountryDefault />}
              />
             

            <Route
              path="*"
              element={<Navigate to={PageURL.COUNTRYDEFAULT} />}
            />
          </Route>
          <Route path="*" element={<Navigate to={PageURL.BASE} />} />
        </Routes>
      </div>
    </>
  );
}

export default App;

// {!activeLink2.total &&
//   <span className='date-container'
//   >

//       <i
//           style={{ position: "absolute", left: "10%", zIndex: 1, fontSize: "0.8vmax" }}
//           className="fa-solid fa-angle-left"
//           onClick={() => handleLeftClickDate()}

//       ></i>
//       <i
//           style={{
//               position: "absolute",
//               right: "10%",
//               zIndex: 1,
//               fontSize: "0.8vmax",
//               cursor: showCursorOnDate.right ? "pointer" : "not-allowed"
//           }}

//           className="fa-solid fa-angle-right"
//           onClick={() => handleRightClickDate()}

//       ></i>
//       {/* <Datetime className='date'
//           timeFormat={false}
//           // initialValue={DateStr}
//           // value={DateStr}
//           initialValue={DateStr}
//           renderDay={renderDay}
//           renderMonth={renderMonth}
//           renderYear={renderYear}
//           dateFormat={activeLink2.day ? "DD/MM/YYYY" : activeLink2.month ? "MM/YYYY" : activeLink2.year ? "YYYY" : "DD/MM/YYYY"}
//           onChange={(e) => convertDateToReadbleFormat(e._d)
//           }
//           closeOnClickOutside={true}
//           closeOnSelect={true}
//           inputProps={{
//               readOnly: true,
//           }}
//       /> */}

//       {
//           activeLink2.day &&
//           (
//               <Datetime className='date'
//                   timeFormat={false}
//                   // initialValue={DateStr}
//                   // value={DateStr}
//                   initialValue={`${DateStr.split("/")[0]}/${DateStr.split("/")[1]}/${DateStr.split("/")[2]}`}

//                   dateFormat={"DD/MM/YYYY"}
//                   onChange={(e) => convertDateToReadbleFormat(e._d)
//                   }
//                   closeOnClickOutside={true}
//                   closeOnSelect={true}
//                   inputProps={{
//                       readOnly: true,
//                   }}
//               />
//           )
//       }
//       {
//           activeLink2.month &&
//           (
//               <Datetime className='date'
//                   timeFormat={false}
//                   // initialValue={DateStr}
//                   // value={DateStr}
//                   initialValue={`${DateStr.split("/")[1]}/${DateStr.split("/")[2]}`}

//                   dateFormat={"MM/YYYY"}
//                   onChange={(e) => convertDateToReadbleFormat(e._d)
//                   }
//                   closeOnClickOutside={true}
//                   closeOnSelect={true}
//                   inputProps={{
//                       readOnly: true,
//                   }}
//               />
//           )
//       }
//       {
//           activeLink2.year &&
//           <Datetime className='date'
//               timeFormat={false}
//               // initialValue={DateStr}
//               // value={DateStr}
//               initialValue={`${DateStr}`.split("/")[2]}
//               dateFormat={"YYYY"}
//               onChange={(e) => convertDateToReadbleFormat(e._d)
//               }
//               closeOnClickOutside={true}
//               closeOnSelect={true}
//               inputProps={{
//                   readOnly: true,
//               }}
//           />
//       }
//   </span>
// }

// Function to get a cookie by name
// const getCookie = (name) => {
//   const cookieArr = document.cookie.split("; "); // Split cookies into an array
//   for (const cookie of cookieArr) {
//     const [key, value] = cookie.split("="); // Split each cookie into key and value
//     if (key === name) {
//       return decodeURIComponent(value); // Decode and return the value
//     }
//   }
//   return null; // Return null if the cookie is not found
// };

// // Usage
// const token = getCookie("SESSION_ID");
// console.log("Auth Token:", token);
