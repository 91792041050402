import React, { useEffect, useRef, useState } from "react";
import Printer2 from "../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../constants/Utils";
import SpeedChart from "../../components/SpeedChart";
import { saveAs } from "file-saver";
import SelectOptions from "./SelectOptions";
import { Grid } from "@mui/material";

const ActualPrACLineLoss = ({acLineLossActualPr, loading2}) => {
    const graphRef=useRef(null)

    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
      if (
        acLineLossActualPr &&
        acLineLossActualPr?.length > 0
      ) {
        setSelectedDate(acLineLossActualPr[0]["name"]);
      }
    },[ acLineLossActualPr]);
  return (
    <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    position: "relative",
                    backgroundColor: "#fff",
                    padding: "1vmax",
                    overflow: "auto",
                  }}
                  ref={graphRef}
                >
   
  <div
    style={{
      padding: "0 1.5rem",
      position: "sticky",
      left: "0",
      right: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      zIndex: "1",
    }}
  >
     <div
          style={{
            padding: "0 1.5rem",
            position: "sticky",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <select
            name="level"
            // id="level"
            style={{
              padding: "1vmin 1.5vmin",
              textAlign: "center",
              fontWeight: "bolder",
              borderStyle: "solid",
              borderColor: "#ED7D31",
              borderWidth: "3px",
              fontSize: "1rem",
              backgroundColor: "#edeaea",
            }}
            onChange={(e) => {
            
              setSelectedDate(e.target.value);
            }}
          >
            {/* ###  Monthly Value component for Gaugemeter ### */}

            <SelectOptions
            selectedDate={selectedDate}
              dataArray={
                acLineLossActualPr?.map((e) => e.Date) || []
              }
            />
          </select>
        </div>
    <Printer2
     
     

      dataSetDetail={{data:acLineLossActualPr?.reduce((acc, curr) => {
        acc.push({
          Date: curr["Date"],
          ActualPR: curr["Actual Pr"],
          AcLoss: curr["Ac Line Loss"],
        });
        return acc;
      }, []) ||
       [],name:"Ac Line Loss And Actual PR",ref:graphRef,refCurrent:graphRef.current}}
    />

   
  </div>
  <div className="speed-chart-container">
    <SpeedChart
      title={`Actual PR (%)`}
      minValue={
       Math.floor( acLineLossActualPr?.reduce((acc,curr)=>
          Math.min(acc,curr["Actual Pr"]),
          Infinity
        ) )-1
  
      }
      maxValue={
        Math.ceil( acLineLossActualPr?.reduce((acc,curr)=>
          
            Math.max(acc, curr["Actual Pr"]),
          -Infinity
        ) )+1
        
      }
      value={
       Number( acLineLossActualPr?.find(
          (e) => e.Date === selectedDate
        )?.["Actual Pr"] || 0)
      }
    />
    <SpeedChart
      title={`AC Line Loss (%)`}
      minValue={Math.floor(
        acLineLossActualPr?.reduce(
          (acc, curr, index) =>
             Math.min(acc, curr["Ac Line Loss"]),
          Infinity
        )
      )-1}
      maxValue={Math.ceil(
        acLineLossActualPr?.reduce(
          (acc, curr, index) =>
             Math.max(acc, curr["Ac Line Loss"]),
          -Infinity
        )
      )+1}
      value={
       acLineLossActualPr?.find(
          (e) => e.Date ===selectedDate
        )?.["Ac Line Loss"] || 0
      }
    />
  </div>
  <div className="watermark-text">© GreenEnco</div>
  </Grid>
  )
}

export default ActualPrACLineLoss