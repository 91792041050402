import React, { useMemo, useRef, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { saveAs } from "file-saver";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import { CSVLink } from "react-csv";
import GraphLoader from "../../../../components/GraphLoader";
const AcutalPR = ({ acLineLossActualPr, loading }) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({
    actualPR: true,
  });

  const minMaxRangeAndGraphData = useMemo(() => {
    if (acLineLossActualPr && acLineLossActualPr?.length > 0) {
      const graphData =
        acLineLossActualPr?.reduce((acc, curr) => {
          if (curr["name"] && curr["actualPr"]) {
            acc.push({
              Date: curr["name"],
              ActualPr: Number(curr["actualPr"]),
            });
          }

          return acc;
        }, []) || [];

      return {
        graphData,
        min: Math.floor(
          graphData?.reduce(
            (acc, curr, index) => Math.min(curr["ActualPr"], acc),
            Infinity
          )
        ),
        max: Math.ceil(
          graphData?.reduce(
            (acc, curr, index) => Math.max(curr["ActualPr"], acc),
            -Infinity
          )
        ),
      };
    }
    return {};
  }, [acLineLossActualPr]);

  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>Actual PR (%)</h5>
        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() => {
            if (!graphRef.current) return null;
            document.getElementsByClassName("cpy_right")[1].style.display =
              "block";
            exportComponentAsJPEG(graphRef, {
              fileName: "graph",
            });
            document.getElementsByClassName("cpy_right")[1].style.display =
              "none";
          }}
          svgDownload={async () => {
            if (!graphRef.current) return null;
            document.getElementsByClassName("cpy_right")[1].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[1].style.display =
              "none";

            saveAs(svgData, "graph.svg");
          }}
        />
      </div>
      <CSVLink
        data={minMaxRangeAndGraphData.graphData || []}
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={csvDownloadRef}
      />

      <div
        style={{
          minWidth: "100%",
          width:
            acLineLossActualPr?.length > 0
              ? acLineLossActualPr.length *
                  Object.keys(acLineLossActualPr[0]).length *
                  10 +
                "px"
              : "100%",
        }}
        className="generation-overview-responsive-container-2"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) :
          acLineLossActualPr?.length>0 ?
           (
            <ComposedChart
              barCategoryGap={"20%"}
              ref={graphRef}
              margin={{
                top: 5,
                bottom: 5,
                left: 5,
                right: 5,
              }}
              data={minMaxRangeAndGraphData.graphData || []}
            >
              <XAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                dataKey="Date"
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              {/* <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" /> */}
              <CartesianGrid stroke="grey" strokeWidth={0.3} />

              <YAxis
                type="number"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickCount={6}
                domain={[
                  minMaxRangeAndGraphData.min -
                    minMaxRangeAndGraphData.min / 10,
                  minMaxRangeAndGraphData.max +
                    minMaxRangeAndGraphData.min / 10,
                ]}
              />

              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    label={""}
                    LegendValues={["Actual PR"]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />
              <Tooltip
                cursor={{ fill: "none" }}
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={["Actual PR"]}
                  />
                }
                contentStyle={{ fontSize: "0.7rem" }}
              />
              {
                <Bar
                  hide={showBarData.actualPR ? false : true}
                  dataKey="ActualPr"
                  fill={colors3[0]}
                  onTransitionEnd={"1s all"}
                  maxBarSize={30}
                >
                  <LabelList
                    style={{ zIndex: 6 }}
                    dataKey="ActualPr"
                    position="top"
                    fontSize={"0.8rem"}
                    fontWeight={600}
                    fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                  />
                </Bar>
              }
            </ComposedChart>
          ):
          <div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
          <h3>Data Not Available</h3>
          </div>
        }
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default AcutalPR;
