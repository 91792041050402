import React from "react";
import "./GenOverView.css";
import { Grid } from "@mui/material";

import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { convertToReadableValue } from "../../constants/Utils";
import GraphLoader from "../../components/GraphLoader";

const NetPVsystContainer = ({
  projectDetail,
  netContractualEnergyShortfall,
  loading,
  progressValueAnimate,
  setProgressValueAnimate,
}) => {
  console.log(netContractualEnergyShortfall)
  return (
    <Grid item lg={5.5} md={12} sm={12} xs={12} className="gen-overview-1">
      {loading ? (
        <div style={{ height: "100%", width: "100%" }}>
          <GraphLoader />
        </div>
      ) : !netContractualEnergyShortfall ||
        netContractualEnergyShortfall?.length == 0 ? (
        <div style={{ width: "100%", fontSize: "2vmin" }}>
          <p>Net Energy and PVsyst Energy</p>
          <p>Data Not Available</p>
        </div>
      ) : (
        <>
          <div>
            <div>
              <div>
                <progress
                  className="progress_1"
                  style={{
                    accentColor: "green",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress1
                      ? "3px solid red"
                      : "none",
                  }}
                  value={Math.abs(
                    netContractualEnergyShortfall?.reduce(
                      (acc, curr) => acc + Number(curr["Net Energy"]),
                      0
                    ) || 0
                  )}
                  max={
                    netContractualEnergyShortfall?.reduce(
                      (acc, curr) => acc + Number(curr["Net Energy"]),
                      0
                    ) + 10000000 || 0
                  }
                />
              </div>
              <div>
                <span>Net Energy (KWh)</span>
                <span
                  style={{
                    position: "absolute",
                    left: ` ${
                      (netContractualEnergyShortfall?.reduce(
                        (acc, curr) => acc + Number(curr["Net Energy"]),
                        0
                      ) *
                        100) /
                        (netContractualEnergyShortfall?.reduce(
                          (acc, curr) => acc + Number(curr["Net Energy"]),
                          0
                        ) +
                          10000000) -
                      3
                    }%`,
                    bottom: "50%",
                  }}
                >
                  <i className="fa-solid fa-caret-up"></i>
                </span>

                <span>
                  {convertToReadableValue(
                    netContractualEnergyShortfall
                      ?.reduce(
                        (acc, curr) => acc + Number(curr["Net Energy"]),
                        0
                      )
                      ?.toFixed(0)
                  )}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_2"
                  style={{
                    borderRadius: "14px",
                    border: progressValueAnimate.progress2
                      ? "3px solid black"
                      : "none",
                  }}
                  value={Math.abs(
                    netContractualEnergyShortfall?.reduce(
                      (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                      0
                    ) || 0
                  )}
                  max={
                    netContractualEnergyShortfall?.reduce(
                      (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                      0
                    ) + 10000000 || 0
                  }
                />
              </div>

              <div>
                <span>PVsyst Energy (KWh)</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      ((netContractualEnergyShortfall?.reduce(
                        (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                        0
                      ) || 0) *
                        100) /
                        (netContractualEnergyShortfall?.reduce(
                          (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                          0
                        ) +
                          10000000) -
                      3
                    }%`,
                    bottom: "50%",
                  }}
                >
                  <i className="fa-solid fa-caret-up"></i>
                </span>
                <span>
                  {convertToReadableValue(
                    netContractualEnergyShortfall
                      ?.reduce(
                        (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                        0
                      )
                      ?.toFixed(0)
                  )}
                </span>
              </div>
            </div>
            <div>
              <div>
                <progress
                  className="progress_3"
                  style={{
                    accentColor: "blue",
                    borderRadius: "14px",
                    border: progressValueAnimate.progress3
                      ? "3px solid red"
                      : "none",
                  }}
                  value={
                    Math.abs(
                      netContractualEnergyShortfall?.reduce(
                        (acc, curr) =>
                          acc + Number(curr["PVsyst Net Energy Shortfall"]),
                        0
                      ) / netContractualEnergyShortfall?.length || 0
                    )?.toFixed(0) || 0
                  }
                  max={100}
                />
              </div>
              <div>
                <span>Excess(+)/ShortFall(-)(%)</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${
                      (Math.abs(
                        netContractualEnergyShortfall?.reduce(
                          (acc, curr) =>
                            acc + Number(curr["PVsyst Net Energy Shortfall"]),
                          0
                        ) / netContractualEnergyShortfall?.length
                      )?.toFixed(0) *
                        100) /
                        100 -
                      3
                    }%`,
                    bottom: "50%",
                  }}
                >
                  <i className="fa-solid fa-caret-up"></i>
                </span>

                <span>
                  {(
                    ((netContractualEnergyShortfall?.reduce(
                      (acc, curr) => acc + Number(curr["Net Energy"]),
                      0
                    ) -
                      netContractualEnergyShortfall?.reduce(
                        (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                        0
                      )) /
                      netContractualEnergyShortfall?.reduce(
                        (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                        0
                      )) *
                    100
                  )?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>
                {convertToReadableValue(
                  netContractualEnergyShortfall
                    ?.reduce((acc, curr) => acc + Number(curr["Net Energy"]), 0)
                    ?.toFixed(0)
                )}
              </span>
              <span>
                Net Energy <p>( KWh )</p>{" "}
              </span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={
                      netContractualEnergyShortfall?.reduce(
                        (acc, curr, index) => {
                          acc.push({
                            Date: curr["Date"],
                            'Net Energy': curr["Net Energy"],
                          });
                          return acc;
                        },
                        []
                      ) || []
                    }
                  >
                    <XAxis dataKey="Date" hide />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Net Energy"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={18}
                      dataKey="Net Energy"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress1: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress1: false,
                  })
                }
                onMouseLeave={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress1: false,
                  })
                }
                className="progress_button_1"
              >
                View
              </span>
            </div>
            <div>
              <span>
                {convertToReadableValue(
                  netContractualEnergyShortfall
                    ?.reduce(
                      (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                      0
                    )
                    ?.toFixed(0)
                ) || 0}
              </span>
              <span>
                PVsyst Energy <p>(KWh)</p>{" "}
              </span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={
                      netContractualEnergyShortfall?.reduce(
                        (acc, curr, index) => {
                          acc.push({
                            Date: curr["Date"],
                            'PVsyst Energy': curr["PVsyst Energy"],
                          });
                          return acc;
                        },
                        []
                      ) || []
                    }
                  >
                    <XAxis dataKey="Date" hide />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Contractual Energy"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={18}
                      dataKey="PVsyst Energy"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress2: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress2: false,
                  })
                }
                onMouseLeave={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress2: false,
                  })
                }
                className="progress_button_2"
              >
                View
              </span>
            </div>
            <div>
              <span>
                {(
                  ((netContractualEnergyShortfall?.reduce(
                    (acc, curr) => acc + Number(curr["Net Energy"]),
                    0
                  ) -
                    netContractualEnergyShortfall?.reduce(
                      (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                      0
                    )) /
                    netContractualEnergyShortfall?.reduce(
                      (acc, curr) => acc + Number(curr["PVsyst Energy"]),
                      0
                    )) *
                  100
                )?.toFixed(2)}
              </span>
              <span>
                Excess/ShortFall <p>(%)</p>{" "}
              </span>
              <div style={{ width: "80%", height: 30 }}>
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    data={
                      netContractualEnergyShortfall?.reduce(
                        (acc, curr, index) => {
                          acc.push({
                            Date: curr["Date"],
                            shortFall: curr["PVsyst Energy"],
                          });
                          return acc;
                        },
                        []
                      ) || []
                    }
                  >
                    <XAxis dataKey="Date" hide />
                    <YAxis
                      hide
                      // domain={[
                      //   Math.floor(netContractualEnergyShortfall?.reduce((acc, curr) => {
                      //     acc=Math.min(acc,curr["shortFall"])
                      //     return acc
                      //   }, Infinity)),
                      //   Math.ceil(netContractualEnergyShortfall?.reduce((acc, curr) => {
                      //     acc=Math.max(acc,curr["shortFall"])
                      //     return acc
                      //   }, -Infinity))
                      // ]}
                    />
                    {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Excess/Shortfall Percentage"]} />} /> */}
                    <Bar
                      barSize={4}
                      radius={18}
                      dataKey="shortFall"
                      fill="#8884d8"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              <span
                className="progress_button_3"
                onMouseEnter={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress3: true,
                  })
                }
                onMouseOut={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress3: false,
                  })
                }
                onMouseLeave={() =>
                  setProgressValueAnimate({
                    ...progressValueAnimate,
                    progress3: false,
                  })
                }
              >
                View
              </span>
            </div>
          </div>
        </>
      )}
    </Grid>
  );
};

export default NetPVsystContainer;
