import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { saveAs } from "file-saver";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import { CSVLink } from "react-csv";
import GraphLoader from "../../../../components/GraphLoader";

export const NetNormalisedShortFall = ({
  netNormalisedEnergyShortfall,
  loading,
}) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({});

  const minMaxRange = useMemo(() => {
    if (
      netNormalisedEnergyShortfall &&
      netNormalisedEnergyShortfall?.length > 0
    ) {
      let obj = {};

      Object.keys(netNormalisedEnergyShortfall[0]).forEach((e) => {
        if (e != "name") {
          obj[e] = true;
        }
      });
      obj["all"] = true;
      setShowBarData(obj);

      return {
        min: Math.floor(
          netNormalisedEnergyShortfall?.reduce(
            (acc, curr, index) =>
              Math.min(curr["netEnergy"], curr["normalisedEnergy"], acc),
            Infinity
          )
        ),
        max: Math.ceil(
          netNormalisedEnergyShortfall?.reduce(
            (acc, curr, index) =>
              Math.max(curr["netEnergy"], curr["normalisedEnergy"], acc),
            -Infinity
          )
        ),
      };
    }
    return {};
  }, [netNormalisedEnergyShortfall]);

  return (
    <>
      {" "}
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>
          Net Energy(KWh) vs GII-Corrected Normalized Contractual Energy(KWh)
          and Excess(+)/Shortfall(-)(%)
        </h5>
        <Printer2
          clickhandler={() => {
            let data = netNormalisedEnergyShortfall?.reduce((acc, curr) => {
              acc.push({
                Date: curr.name,
                NetEnergy: curr["netEnergy"],
                NormalisedEnergy: curr["normalisedEnergy"],
                ExcessShortfall: curr["shortFall"],
              });
              return acc;
            }, []);
            if (data?.length > 0) return csvDownloadRef.current.link.click();
            else return null;
          }}
          jpgDownload={() => {
            if (!graphRef.current) return null;
            setTimeout(async () => {
              document.getElementsByClassName("cpy_right")[2].style.display =
                "block";
              exportComponentAsJPEG(graphRef, {
                fileName: "graph",
              });
              document.getElementsByClassName("cpy_right")[2].style.display =
                "none";
            }, 100);
          }}
          svgDownload={async () => {
            if (!graphRef.current) return null;
            document.getElementsByClassName("cpy_right")[2].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[2].style.display =
              "none";
            saveAs(svgData, "graph.svg");
          }}
        />
      </div>
      <CSVLink
        data={
          netNormalisedEnergyShortfall?.reduce((acc, curr) => {
            acc.push({
              Date: curr.name,
              NetEnergy: curr["netEnergy"],
              NormalisedEnergy: curr["normalisedEnergy"],
              ExcessShortfall: curr["shortFall"],
            });
            return acc;
          }, []) || []
        }
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={csvDownloadRef}
      />
      <div
        style={{
          minWidth: "100%",
          width:
            netNormalisedEnergyShortfall?.length > 0
              ? netNormalisedEnergyShortfall.length *
                  Object.keys(netNormalisedEnergyShortfall[0]).length *
                  14 +
                "px"
              : "100%",
        }}
        className="detailed-responsive-container-3"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : netNormalisedEnergyShortfall?.length > 0 ? (
            <ComposedChart
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 15,
              }}
              title="abc"
              barCategoryGap={"20%"}
              data={netNormalisedEnergyShortfall || []}
              ref={graphRef}
              maxBarSize={30}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="name"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickFormatter={(v) =>
                  v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
                }
                yAxisId="left-axis"
                //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}}
                // label={<AxisLabel axisType='yAxis' x={-30} y={-5}>Energy</AxisLabel>}
                label={{
                  value: "Energy",
                  angle: -90,
                  position: "insideBottomLeft",
                  dx: -10,
                  fontWeight: 600,
                  fontSize: "0.8rem",
                }}
                domain={[
                  minMaxRange.min - minMaxRange.min / 10,
                  minMaxRange.max + minMaxRange.min / 10,
                ]}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={800}
                yAxisId="right-axis"
                orientation="right"
                // label={<AxisLabel axisType='yAxis' x={26} y={305}>Excess/Shortfall</AxisLabel>}
                label={{
                  value: "Excess/Shortfall",
                  angle: 90,
                  position: "insideBottomRight",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  // dy: -110,
                  // dx: 10,
                }}
                tickLine={false}
                tickMargin={5}
                domain={[
                  Math.floor(
                    netNormalisedEnergyShortfall?.reduce(
                      (acc, curr, index) =>
                        Math.min(
                          curr["shortFall"],

                          acc
                        ),
                      Infinity
                    )
                  ) - 1,
                  Math.ceil(
                    netNormalisedEnergyShortfall?.reduce(
                      (acc, curr, index) =>
                        Math.max(
                          curr["shortFall"],

                          acc
                        ),
                      -Infinity
                    )
                  ) + 1,
                ]}
              />
              <Tooltip
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={[
                      "Net Energy",
                      "Normalised Energy",
                      "Excess(+)/Shortfall(-)",
                    ]}
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={[
                      "Net Energy",
                      "Normalised Energy",
                      "Excess(+)/Shortfall(-)",
                    ]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />

              <Bar
                hide={showBarData["netEnergy"] ? false : true}
                dataKey="netEnergy"
                fill={`${colors3[0]}`}
                yAxisId="left-axis"
              />
              <Bar
                hide={showBarData["normalisedEnergy"] ? false : true}
                dataKey="normalisedEnergy"
                fill={`${colors3[1]}`}
                yAxisId="left-axis"
              />
              <Line
                hide={showBarData["shortFall"] ? false : true}
                type="monotone"
                dataKey="shortFall"
                strokeWidth={3}
                stroke={`${colors1[4]}`}
                yAxisId="right-axis"
              />
            </ComposedChart>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3>Data Not Available</h3>
            </div>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};
