import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  allow_user,
  projectDetail,
  registred_users,
  user,
} from "../reducers/userReducers";
import {
  acLineLossActualPrReducer,
  asbuiltDocumentReducer,
  GHIGTIReducer,
  inverterDeviationReducer,
  inverterEfficiencyReducer,
  netContractualEnergyShortfallReducer,
  netNormalisedEnergyShortfallReducer,
  projectImages,
  projectImagesReducer,
  pvsystActualTempReducer,
  pvsystContractualEnergyReducer,
  pvsystNormalisedReducer,
  scbsmbLossReducer,
} from "../reducers/energyReducer";
import {
  new_project_detail_request,
  project_detail_request,
} from "../constants/dataConstants";

const reducer = combineReducers({
  user: user,
  projectDetail: projectDetail,
  registred_users: registred_users,
  allow_user: allow_user,
  scbsmbLoss: scbsmbLossReducer,
  inverterEfficiency: inverterEfficiencyReducer,
  inverterDeviation: inverterDeviationReducer,
  GHIGTI: GHIGTIReducer,
  pvsystActualTemp: pvsystActualTempReducer,
  pvsystContractualEnergy: pvsystContractualEnergyReducer,
  netNormalisedEnergyShortfall: netNormalisedEnergyShortfallReducer,
  netContractualEnergyShortfall: netContractualEnergyShortfallReducer,
  acLineLossActualPr: acLineLossActualPrReducer,
  pvsystNormalised: pvsystNormalisedReducer,
  asbuiltDocument: asbuiltDocumentReducer,
  projectImages: projectImagesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return reducer(undefined, action);
  } else if (action.type == new_project_detail_request) {
    const { user } = state; // Extract the user reducer state
    const resetState = Object.keys(state).reduce((acc, key) => {
      acc[key] = key === "user" ? state[key] : {}; // Retain `user` state, nullify others

      return acc;
    }, {});

    return reducer(resetState, action);
  } else return reducer(state, action);
};

const middleware = [thunk];
let initialState = {};

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
