import React, { useEffect, useState } from "react";
import "./CountryHeader.css";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FolderIcon from "@mui/icons-material/Folder";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageURL from "../../../../constants/PageURL";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../../actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import { clear_errors } from "../../../../constants/dataConstants";
import logo from "../../../../fonts and images/newLogo.PNG";


const CountryHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [openSidebar, setOpenSidebar] = useState(false);

  const [activeLink, setActiveLink] = useState({
    admin: false,
    analytics: false,
  });

  const logoutHandler = () => {
    dispatch(userLogout());
  };
 
  window.onresize = () => {
    if (window.innerWidth > 700) {
      setOpenSidebar(false);
    }
  };
  return (
    <div className="header">
      {/*  ##### GreenEnco Logo Start ##### */}
      <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)}>
        <img src={logo} alt="GreenEnco-Logo" />
      </div>

      {/* <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)} style={{ cursor: "pointer", display: "flex", color: "white", alignItems: "center", flexDirection: "column", gap: "0", justifyContent: "center", fontFamily: "Georgia Ref Regular" }}>
        <span style={{ fontSize: "4vmin", letterSpacing: "0.1vmin", fontWeight: "200", lineHeight: ".9" }}>GreenEnco</span>
        <span style={{ fontSize: "1.25vmin", fontWeight: "500", letterSpacing: "0.1vmin" }}>Maximizing Asset Values</span>
      </div> */}

      {/*  ##### GreenEnco Logo End ##### */}

      <h4>Creating A Better World In A Changing Climate</h4>

      <div className="bar">

        <i   onClick={() => {
              setOpenSidebar(!openSidebar);
            }} className={`fa-solid ${!openSidebar ?"fa-bars bar-icon":"fa-xmark xmark-icon"}`}></i>
      
          {/* <i
            onClick={() => {
              setOpenSidebar(true);
            }}
            className={`fa-solid fa-bars bar-icon`}
            style={{
              position: "relative",
              right: !openSidebar ? "10%" : "-130%",
            }}
          ></i>
          <i
            onClick={() => {
              setOpenSidebar(false);
            }}
            className={`fa-solid fa-xmark xmark-icon`}
            style={{
              position: "absolute",
              top: openSidebar ? "10%" : "-130%",
              right: openSidebar ? "25%" : "0%",
            }}
          ></i> */}
      </div>
      {/*  ##### Code For Header Sidebar ##### */}

      <div className="sidebar-1" style={{
        //  height: openSidebar ? "80px" : 0, 
         display:openSidebar?"flex":"none"
         }}>
        <Link to={PageURL.PROJECT_DETAILS}>Project Details </Link>
        {
          user &&
          user.userLevel ==1 && (
            <Link
              onClick={() => {
                props?.setDashBoard(true);
                setActiveLink({
                  ...activeLink,
                  analytics: false,
                  admin: true,
                });
              }}
            >
              {" "}
              Admin DashBoard{" "}
            </Link>
          )}
        <Link onClick={logoutHandler}>Logout </Link>
      </div>
      <div className="header-links-1">
        <span
          onClick={() => {
            navigate(PageURL.PROJECT_DETAILS);
          }}
        >
          <FolderIcon />
          Project Details{" "}
        </span>
        {
          user &&
          user?.userLevel==1 && (
            <span
              style={{ color: activeLink.admin ? "#ed7d31" : "white" }}
              onClick={() => {
                props?.setDashBoard(true);
                setActiveLink({
                  ...activeLink,
                  analytics: false,
                  admin: true,
                });
              }}
            >
              <AdminPanelSettingsIcon />
              Admin DashBoard{" "}
            </span>
          )}
        <span onClick={logoutHandler}>
          <LogoutIcon />
          Logout{" "}
        </span>
      </div>
    </div>
  );
};

export default CountryHeader;
