import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SpinLoader from "../../../../components/SpinLoader";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors3 } from "../../../../colors/color";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import "./scbsmb.css";
import Printer2 from "../../../../components/Printer2";
import GraphLoader from "../../../../components/GraphLoader";
import { scbsmbLossMonthlyAction } from "../../../../actions/energyAction";

const ScbSmbLoss = ({
  selectedLebel,
  scbsmbDetail,
  databaseStr,
  title,
  setTitle,
}) => {
  const { scbsmbLoss = [], loading } = useSelector((state) => state.scbsmbLoss);

  /* ###   Initialize State to animate Graph Bars and Lines   ### */
  const [loader, setLoader] = useState(false);
  const [showEfficiencyYearlyLoss, setShowEfficiencyYearlyLoss] = useState({});
  const [tooltipAdjust, setTooltipAdjust] = useState({ x: null, y: null });
  const [scbSmbDeviation, setscbSmbDeviation] = useState(null);
  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef = useRef(null);

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (scbsmbLoss && scbsmbLoss?.length > 0) {
      if (scbsmbDetail.scbsmbType === "SMB") {
        let newArr = [];
        scbsmbLoss?.forEach((e) => {
          let newObj = {};
          newObj["name"] = e["name"];
          let sum = 0,
            i = 0;
          Object.keys(e).forEach((ee) => {
            if (ee != "name" && !isNaN(e[ee])) {
              sum += Number(e[ee]);
              i++;
            }
          });
          newObj[
            `${scbsmbDetail.scbsmbType}-${scbsmbDetail?.scbsmb?.split(" ")[1]}`
          ] = Number(sum / i).toFixed(2);
          newArr.push(newObj);
        });

        setscbSmbDeviation(newArr);

        let obj = {};
        Object.keys(newArr[0]).forEach((e) => {
          if (e !== "name") {
            obj[e] = true;
          }
        });
        obj["all"] = true;
        setShowEfficiencyYearlyLoss(obj);
      } else {
        setscbSmbDeviation(scbsmbLoss);
        let obj = {};
        Object.keys(scbsmbLoss[0]).forEach((e) => {
          if (e !== "name") {
            obj[e] = true;
          }
        });
        obj["all"] = true;
        setShowEfficiencyYearlyLoss(obj);
      }
    }
  }, [scbsmbLoss]);

  useEffect(() => {
    if (selectedLebel?.endDataValue) {
      dispatch(
        scbsmbLossMonthlyAction(scbsmbDetail, selectedLebel, databaseStr)
      );
    }
  }, [dispatch, selectedLebel?.endDataValue]);
  useEffect(() => {
    let { inverter, block, scbsmb, scbsmbType } = scbsmbDetail;
    inverter = inverter?.split(" ")[1];
    block = block?.split(" ")[1];
    scbsmb = scbsmb?.split(" ")[1] === "SCB" ? "ALL" : scbsmb?.split(" ")[1];
    if (scbsmb) {
      setTitle(
        "BL-" +
          block +
          " " +
          "INV-" +
          inverter +
          " " +
          scbsmbType +
          "-" +
          scbsmb
      );
    }
  }, [scbsmbDetail]);

  useEffect(() => {
      dispatch(scbsmbLossMonthlyAction(scbsmbDetail, {}, databaseStr));
  }, [scbsmbDetail]);

  return (
    <>
      <CSVLink
        data={
          JSON.parse(
            JSON.stringify(scbSmbDeviation).replaceAll("name", "Date")
          ) || []
        }
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={downloadRef}
      />
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        {title ? (
          <h3>
            {scbsmbDetail.scbsmbType} Current Average Loss ({title})
          </h3>
        ) : (
          <h3>{scbsmbDetail.scbsmbType} Current Average Loss </h3>
        )}

        <Printer2
          clickhandler={() => {
            downloadRef.current.link.click();
          }}
          jpgDownload={() => {
            if (!graphRef.current) return null;
            document.getElementsByClassName("cpy_right")[0].style.display =
              "block";
            exportComponentAsJPEG(graphRef, { fileName: "graph" });
            document.getElementsByClassName("cpy_right")[0].style.display =
              "none";
          }}
          svgDownload={async () => {
            if (!graphRef.current) return null;
            document.getElementsByClassName("cpy_right")[0].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[0].style.display =
              "none";
            saveAs(svgData, "graph.svg");
          }}
        />
      </div>

      <div
        style={{
          minWidth: "100%",
          width: loading
            ? "100%"
            : scbSmbDeviation?.length > 0
            ? scbSmbDeviation.length *
                Object.keys(scbSmbDeviation[0]).length *
                40 +
              "px"
            : "100%",
        }}
        className="scbsmb-responsive-container-1"
      >
        <ResponsiveContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : scbSmbDeviation ? (
            <ComposedChart
              style={{ overflow: "hidden" }}
              onMouseMove={(e) => {
                if (e.isTooltipActive) {
                  setTooltipAdjust({
                    x: e.chartX > 20 ? e.chartX - 20 : e.chartX,
                    y: e.chartY > 150 ? 150 : e.chartY,
                  });
                }
              }}
              data={scbSmbDeviation || []}
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              }}
              barCategoryGap={"5%"}
              ref={graphRef}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="name"
                fontSize={"0.8rem"}
                fontWeight={600}
                // tickLine={false}
                // axisLine={false}
                tickMargin={5}
                // padding={{left:10,right:10}}
                minTickGap={10}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                width={30}
                domain={[
                  Math.floor(
                    scbSmbDeviation?.reduce((acc, curr, index) => {
                      Object.keys(curr).forEach((e) => {
                        if (e !== "name") {
                          acc = Math.min(curr[e], acc);
                        }
                      });
                      return acc;
                    }, Infinity)
                  ) - 2,

                  Math.ceil(
                    scbSmbDeviation?.reduce((acc, curr, index) => {
                      Object.keys(curr).forEach((e) => {
                        if (e !== "name") {
                          acc = Math.max(curr[e], acc);
                        }
                      });
                      return acc;
                    }, -Infinity)
                  ) + 2,
                ]}
                tickLine={false}
              />
              <Tooltip
                position={tooltipAdjust}
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={
                      scbSmbDeviation?.length > 0
                        ? Object.keys(scbSmbDeviation[0]).filter(
                            (e) => e !== "name"
                          )
                        : []
                    }
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={
                      scbSmbDeviation?.length > 0
                        ? Object.keys(scbSmbDeviation[0]).filter(
                            (e) => e !== "name"
                          )
                        : []
                    }
                    data={showEfficiencyYearlyLoss}
                    setData={setShowEfficiencyYearlyLoss}
                  />
                }
                styles={{
                  fontSize: "1rem",
                }}
              />
              <ReferenceLine
                y={0}
                stroke="#000"
                //  strokeDasharray="3 3"
              />

              {scbSmbDeviation?.length > 0 &&
                Object.keys(scbSmbDeviation[0]).map((e, index) => {
                  if (e === "name") return null;
                  return (
                    <Bar
                      maxBarSize={30}
                      key={index}
                      hide={showEfficiencyYearlyLoss[e] ? false : true}
                      dataKey={e}
                      fill={`${colors3[0]}`}
                    >
                      <LabelList
                        dataKey={e}
                        position="top"
                        fontSize={"0.7rem"}
                        fontWeight={600}
                        fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                      />
                    </Bar>
                  );
                })}
            </ComposedChart>
          ) : (
            <h3>Data Not Available</h3>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default ScbSmbLoss;
