import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import SpeedChart from "../../../../components/SpeedChart";
import { saveAs } from "file-saver";
import SelectOptions from "./SelectOptions";
import { Grid } from "@mui/material";

const NetContractual = ({ netContractualEnergyShortfall, loading ,showPVsystEnergy}) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const minMaxRange = useMemo(() => {
    if(netContractualEnergyShortfall?.length>0){
      setSelectedDate(netContractualEnergyShortfall[0]?.name);
    return {
      min:
        netContractualEnergyShortfall?.reduce(
          (acc, curr, index) =>
            Math.min(acc, curr["netEnergy"], curr["contractualEnergy"]),
          Infinity
        ) ,
      max:
        netContractualEnergyShortfall?.reduce(
          (acc, curr, index) =>
            Math.max(acc, curr["netEnergy"], curr["contractualEnergy"]),
          -Infinity
        ) ,
    }}
    return {}
  }, [netContractualEnergyShortfall]);
  const [PVsystContractual,setPVsystContractual]=useState({name:"Contractual Energy",dataKey:"contractualEnergy"})

  useEffect(()=>{
    if(showPVsystEnergy){
      setPVsystContractual({
        ...PVsystContractual,
        name:"PVsyst Energy",dataKey:"pvsystEnergy"
  
      })
    }
    

  },[showPVsystEnergy])

  return (
    <Grid
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      ref={graphRef}
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        padding: "1vmax",
        overflow: "auto",
      }}
    >
      {" "}
      <CSVLink
        data={
          netContractualEnergyShortfall?.reduce((acc, curr) => {
            acc.push({
              Date: curr.name,
              NetEnergy: curr["netEnergy"],
              [PVsystContractual.name]: curr[PVsystContractual.dataKey],
            });
            return acc;
          }, []) || []
        }
        filename="data.csv"
        className="hidden"
        ref={csvDownloadRef}
        target="_blank"
      />
      <div
        style={{
          padding: "0 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <div
          style={{
            padding: "0 1.5rem",
            position: "sticky",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <select
            name="level"
            // id="level"
            style={{
              padding: "1vmin 1.5vmin",
              textAlign: "center",
              fontWeight: "bolder",
              borderStyle: "solid",
              borderColor: "#ED7D31",
              borderWidth: "3px",
              fontSize: "1rem",
              backgroundColor: "#edeaea",
            }}
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
          >
            {/* ###  Monthly Value component for Gaugemeter ### */}

            <SelectOptions
              dataArray={
                netContractualEnergyShortfall?.map((e) => e.name) || []
              }
            />
          </select>
        </div>

        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() => {
            if (!graphRef.current) return null;
            document.getElementById("hide_content").style.display = "none";
            exportComponentAsJPEG(
              graphRef || null,
              (document.getElementById("greenenco").style.display = "block")
            );
            document.getElementById("greenenco").style.display = "none";
          }}
          svgDownload={async () => {
            if (!graphRef.current) return null;
            document.getElementById("hide_content").style.display = "none";
            const svgData = await saveToSvg(
              graphRef.current || null,
              (document.getElementById("greenenco").style.display = "block")
            );
            document.getElementById("greenenco").style.display = "none";
            saveAs(svgData, "gaugemeter.svg");
          }}
        />
      </div>
      <div className="speed-chart-container">
        <SpeedChart
          title={`Net Energy (KWh)`}
          // minValue={minMaxRange.min&&minMaxRange?minMaxRange.min-(minMaxRange.min+minMaxRange.max)/2:0}
          // maxValue={minMaxRange.min&&minMaxRange?minMaxRange.max+(minMaxRange.min+minMaxRange.max)/2:0}
          minValue={0}
          // minValue={minMaxRange.min ||0}
          maxValue={minMaxRange.max ||0}
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.name === selectedDate
            )?.["netEnergy"] || 0
          }
        />
        <SpeedChart
          title={`${PVsystContractual.name} (KWh)`}
          // minValue={minMaxRange.min&&minMaxRange?minMaxRange.min-(minMaxRange.min+minMaxRange.max)/2:0}
          // maxValue={minMaxRange.min&&minMaxRange?minMaxRange.max+(minMaxRange.min+minMaxRange.max)/2:0}
          minValue={0}
          // minValue={minMaxRange.min ||0}
          maxValue={minMaxRange.max ||0}
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.name === selectedDate
            )?.[PVsystContractual.dataKey]
          }
        />
      </div>
      <div id="greenenco" style={{ position: "relative", display: "none" }}>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "0 0.5rem 0.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "1.2rem" }}>&#169;</span>
          <span>GreenEnco</span>
        </div>
      </div>
    </Grid>
  );
};

export default NetContractual;
