import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

import { scbsmbLossMonthlyAction } from "../../../../actions/energyAction";
import GraphLoader from "../../../../components/GraphLoader";

/* ##### Heatmap Component  ##### */

const Heatmap = ({ databaseStr, scbsmbDetail, selectedLebel }) => {
  const { scbsmbLoss, loading } = useSelector((state) => state.scbsmbLoss);
  const dispatch = useDispatch();

  /* ###  End of Function To Get Inverter Number from URL   ### */
  const [heatmapData, setHeatmapData] = useState(null);
  const [minMax, setMinMax] = useState({});
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (scbsmbLoss?.length > 0) {
      let heatmapData = [];
      scbsmbLoss?.forEach((e) => {
        let finalObj = {};
        finalObj["name"] = e["name"];
        let newObj = {};
        let countObj = {};

        Object.keys(e).forEach((ee) => {
          if (ee != "name") {
            let string = ee.substring(0, 6);
            if (!isNaN(newObj[string])) {
              newObj[string] = Number(newObj[string]) + Number(e[ee]);
              countObj[string]++;
            } else {
              newObj[string] = Number(e[ee]);
              countObj[string] = 1;
            }
          }
        });

        Object.keys(newObj).forEach((e) => {
          finalObj[e] = (newObj[e] / countObj[e]).toFixed(2);
        });
        heatmapData.push(finalObj);
      });

      const minMax = scbsmbLoss?.reduce(
        (acc, curr) => {
          let min = Infinity,
            max = -Infinity;
          Object.keys(curr).forEach((key) => {
            if (key != "name" && !isNaN(curr[key])) {
              min = Math.min(min, curr[key]);
              max = Math.max(max, curr[key]);
            }
          });
          return {
            min: Math.min(acc.min, min),
            max: Math.max(acc.max, max),
          };
        },
        { min: Infinity, max: -Infinity }
      );

      setMinMax(minMax);

      let result = heatmapData?.map((e, index) => {
        let obj = {};
        obj["name"] = e["name"];
        obj["data"] = [];
        Object.keys(e).forEach((ee) => {
          if (ee != "name") {
            obj["data"].push({
              x: ee,
              y: !isNaN(e[ee]) ? Number(e[ee])?.toFixed(0) : 0,
            });
          }
        });

        return obj;
      });
      setHeatmapData(result);
    }
  }, [scbsmbLoss]);
  useEffect(() => {
    if (selectedLebel.endDataValue) {
      dispatch(
        scbsmbLossMonthlyAction(scbsmbDetail, selectedLebel, databaseStr)
      );
    }
  }, [dispatch, selectedLebel]);
  useEffect(() => {
    let { inverter, block, scbsmb } = scbsmbDetail;
    inverter = inverter?.split(" ")[1];
    block = block?.split(" ")[1];
    scbsmb = scbsmb?.split(" ")[1];
    if (scbsmb) {
      setTitle("BL-" + block + " " + "INV-" + inverter);
    }
  }, [scbsmbDetail]);

  useEffect(() => {
    dispatch(scbsmbLossMonthlyAction(scbsmbDetail, {}, databaseStr));
  }, [scbsmbDetail]);
  return (
    <>
      {
        <Grid container textAlign={"center"}>
          <Grid
            item
            sx={{ boxShadow: 2 }}
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              // borderRadius: "8px",
              margin: "0 auto 1vmax",
              padding: "1vmax",
            }}
          >
            <Grid
              item
              lg={12}
              sx={{
                position: "relative",
                backgroundColor: "white",
                padding: "1rem 0",
                minHeight: "70vh",
                height: "fit-content",
                overflow: "auto",
              }}
            >
              <h3>Heatmap Diagram ({title})</h3>
              {loading ? (
                <div
                  style={{
                    minHeight: "70vh",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <GraphLoader />
                </div>
              ) : (
                <Chart
                  style={{
                    margin: "auto",
                    width: "95%",
                    minWidth: "500px",
                  }}
                  height={"95%"}
                  type="heatmap"
                  options={{
                    chart: {
                      toolbar: {
                        show: false,
                        // show: user?.userLevel !== 1 ? false : true,
                        // tools: {

                        // },
                      },
                    },
                    tooltip: {
                      y: {
                        formatter: (v) => (v == 0 ? v : -v),
                      },
                    },

                    plotOptions: {
                      heatmap: {
                        shadeIntensity: 1,
                        colorScale: {
                          inverse: false,
                          ranges: [
                            {
                              from: -minMax.max || 0,
                              to: 5,
                              name: "low",
                              color: "#00A100",
                            },
                            {
                              from: 6,
                              to: 11,
                              name: "medium",
                              color: "#128FD9",
                            },

                            {
                              from: 12,
                              to: 20,
                              name: "high",
                              color: "#FFB200",
                            },
                            {
                              from: 21,
                              to: -minMax.min || 50,
                              name: "extreme",
                              color: "#FF0000",
                            },
                          ],
                        },
                      },
                    },
                    xaxis: {
                      labels: {
                        style: {
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          fontFamily:
                            "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                        },
                      },
                    },
                    yaxis: {
                      labels: {
                        style: {
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          fontFamily:
                            "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                        },
                      },
                    },
                    grid: {
                      show: true,
                      borderColor: "red",
                    },

                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      showForSingleSeries: true,
                      show: true,
                      position: "top",
                    },
                  }}
                  series={
                    heatmapData?.map((e) => {
                      return {
                        name: e["name"],
                        data: e["data"]?.map((ee) => {
                          return {
                            x: ee["x"],
                            y: ee["y"] == 0 ? 0 : -ee["y"],
                          };
                        }),
                      };
                    }) || []
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default Heatmap;
