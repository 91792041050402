import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import PageURL from "../../constants/PageURL";
import { toast } from "react-toastify";
import { clear_errors } from "../../constants/dataConstants";

/* ##### Protected Route to Protect user Routes  ##### */

const ProtectedRoute = () => {
  const { loading, user, isLogout, error } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: clear_errors });
      dispatch({ type: "USER_LOGOUT" });
    }
    if (isLogout) {
      toast.success("User Logged out sucessfully!");
      dispatch({ type: "USER_LOGOUT" });
      navigate(PageURL.BASE);
    }
  }, [isLogout, error]);

  if (user) {
 

    return <Outlet />;
  }
  if (!user && loading === false) {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
