import "./App.css";
import PageURL from "../src/constants/PageURL";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AuthModule from "../src/modules/auth/AuthModule";
import ProtectedRoute from "./modules/auth/ProtectedRoute";
import CountryModule from "./modules/CountryModule";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadUser } from "./actions/userActions";
import CountryDefault from "./modules/CountryDefault";
import SpinLoader from "./components/SpinLoader";

function App() {
  const { user, error, loadingUser } = useSelector((state) => state.user);
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, []);
  const path = location.pathname;
  return (
    <>
      {loadingUser && <SpinLoader />}
      <div className="App">
        <ToastContainer />
        {location.pathname === PageURL.BASE && user && (
          <Navigate to="/dashboard" />
        )}

        <Routes>
          <Route path={PageURL.BASE} element={<AuthModule />}></Route>
          <Route path={PageURL.SIGN_UP} element={<AuthModule />}></Route>
          <Route
            path={PageURL.FORGOT_PASSWORD}
            element={<AuthModule />}
          ></Route>
          <Route
            path={PageURL.OTP_VERIFICATION}
            element={<AuthModule />}
          ></Route>

          <Route element={<ProtectedRoute />}>
            <Route path={PageURL.COUNTRYDEFAULT} element={<CountryDefault />} />
            <Route path={PageURL.PROJECT_DETAILS} element={<CountryModule />} />
              <Route
                path={PageURL.INDIA_GEN_SUMMARY}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_PRES_MODEL_Loss_Flow}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_PRES_MODEL_Fault_Tree}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_PROJ_OVERVIEW}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_DIAGONISTIC}
                element={<CountryModule />}
              ></Route>
              <Route
                path={PageURL.INDIA_DIAGONISTIC_DETAILED}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_LOSS_FLOW}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_INVERTER_EFFICIENCY}
                element={<CountryModule />}
              />
              <Route
                path={PageURL.INDIA_INVERTER_DEVIATION}
                element={<CountryModule />}
              />

              <Route path={PageURL.SCBSMB_LOSS} element={<CountryModule />} />
              {location.pathname ===
                PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM && (
                <Route path={path} element={<CountryModule />} />
              )}
              <Route
                path={PageURL.PROJECT_ANALYTICS}
                element={<CountryDefault />}
              />
             

            <Route
              path="*"
              element={<Navigate to={PageURL.COUNTRYDEFAULT} />}
            />
          </Route>
          <Route path="*" element={<Navigate to={PageURL.BASE} />} />
        </Routes>
      </div>
    </>
  );
}

export default App;

