import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./CountryHeader2.css";
import { useLocation, useNavigate } from "react-router-dom";
import PageURL from "../../../../constants/PageURL";
import logo from "../../../../fonts and images/newLogo.PNG";
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import {
  inverterDeviationAction,
  inverterEfficiencyAction,
  inverterMappingArrayAction,
  projectDetailAction,
  scbSmbMappingArrayAction,
} from "../../../../actions/energyAction";
import FetchProjectDetailLoader from "../../../../components/Loaders/FetchProjectDetailLoader";
import {
  new_project_detail,
  project_detail_reset,
} from "../../../../constants/dataConstants";

const initialOpenSubHeader = {
  projectDetail: false,
  Diagonistic: false,
  inverterEfficiency: false,
  inverterDeviation: false,
  checkedData: false,
  Prescritive: false,
  string_smb: false,
  correctiveAction: false,
};

const CountryHeader3 = (props) => {
  const {
    openSidebar,
    setOpenSidebar,
    selectedValue,
    setSelectedValue,
    scbsmbDetail,
    setscbsmbDetail,
    selectedLebel,
    databaseStr,
  } = props;

  const { user = {} } = useSelector((state) => state.user);
  const {
    projectDetail,
    availableProject,
    loading: loading2,
    scbSmbMappingArray,
    scbSmbMappingLoader,
    inverterMappingArray,
    inverterMappingLoader,
  } = useSelector((state) => state.projectDetail);
  const [checkedData, setCheckedData] = React.useState(initialOpenSubHeader);

  const [openSelect, setOpenSelect] = useState({
    country: false,
    partnersName: false,
    projectName: false,
    projectDetail: false,
    projectDetail2: false,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const serachParams = new URLSearchParams(location.search);

  const onclickHandler = (name, trueValue1, trueValue2) => {
    let checked = { ...checkedData };
    for (let key in checked) {
      if (key === name) {
        checked[key] = !checked[key];
      } else {
        checked[key] = false;
      }
    }
    if (trueValue1) {
      checked[trueValue1] = true;
    }
    if (trueValue2) {
      checked[trueValue2] = true;
    }

    setCheckedData(checked);
  };

  window.onresize = () => {
    if (openSidebar && window.innerWidth >= 950) {
      setOpenSidebar(false);
    }
  };

  useEffect(() => {
    if (databaseStr) {
      dispatch(projectDetailAction(user?.userName, databaseStr));
    }
  }, [databaseStr]);

  useEffect(() => {
    if (scbSmbMappingArray && checkedData.string_smb) {
      const obj1 = {};
      const stringArray = scbSmbMappingArray || [];
      stringArray.forEach((element) => {
        obj1[element.name] = false;
        element.inverters?.forEach((e) => {
          obj1[element.name + e.name] = false;
        });

        setCheckedData({
          ...checkedData,
          ...obj1,
        });
      });
    }
    if (
      inverterMappingArray &&
      (checkedData.inverterEfficiency || checkedData.inverterDeviation)
    ) {
      const inverterDetail = inverterMappingArray || [];
      const obj2 = {};
      inverterDetail.forEach((element) => {
        obj2[element.name + "Efficiency"] = false;
        obj2[element.name + "Deviation"] = false;
      });
      setCheckedData({
        ...checkedData,
        ...obj2,
      });
    }
  }, [inverterMappingArray, scbSmbMappingArray]);

  useEffect(() => {
    if (availableProject) {
      dispatch({ type: project_detail_reset });
      if (location.pathname !== PageURL.PROJECT_DETAILS) {
        navigate(`${PageURL.PROJECT_DETAILS}?${serachParams.toString()}`);
      }
    }
    if (!projectDetail) {
      setCheckedData(initialOpenSubHeader);
    }
  }, [projectDetail, navigate, availableProject]);

  return (
    <div className="header">
      {/*  ##### GreenEnco Logo Start ##### */}

      <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)}>
        <img src={logo} alt="GreenEnco-Logo" />
      </div>
      {/*  ##### GreenEnco Logo End ##### */}

      <h2>Creating A Better World In A Changing Climate</h2>

      {/*  ##### Code For Header Sidebar In Small Devices Start ##### */}

      <div className="header-open-detail">
        <p
          style={{
            width: "45px",
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <i
            onClick={() => setOpenSidebar((val) => !val)}
            className={`fa-solid ${
              openSidebar ? "fa-bars-staggered" : "fa-bars"
            }`}
          ></i>
        </p>
      </div>
      {/*  */}
      {/*  ##### Code For Header Sidebar In Small Devices End ##### */}

      <div className="sidebar-2" style={{ height: openSidebar ? "60px" : "0" }}>
        <span
          onClick={() =>
            setOpenSelect({ ...openSelect, country: !openSelect.country })
          }
        >
          <FormControl
            variant="filled"
            style={{ color: "white" }}
            sx={{ width: "100%", height: "100%", color: "white" }}
          >
            <InputLabel
              // shrink={
              //   openSelect.country || selectedValue.country !== ""
              //     ? true
              //     : false
              // }
              id="demo-simple-select-filled-label11"
              style={{ color: "white" }}
              sx={{
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Country
            </InputLabel>
            <Select
              open={openSelect.country ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label11"
              id="demo-simple-select-filled11"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  country: e.target.value,
                  partnerName: "",
                  projectName: "",
                  projectDetail: "",
                });

                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              value={selectedValue.country || ""}
              defaultValue={selectedValue.country || ""}
              renderValue={() => selectedValue.country || ""}
            >
              {user?.projects?.country?.length == 0 ||
                (!user?.projects?.country && (
                  <MenuItem value={""}>
                    <em>None</em>{" "}
                  </MenuItem>
                ))}

              {user?.projects?.country?.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                    {user?.projects?.partnersName?.find(
                      (e) => e.country === value
                    )?.partnerName?.length
                      ? ` - [ ${
                          user?.projects?.partnersName?.find(
                            (e) => e.country === value
                          )?.partnerName?.length
                        } ]`
                      : null}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={() =>
            setOpenSelect({
              ...openSelect,
              partnersName: !openSelect.partnersName,
            })
          }
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              // shrink={
              //   openSelect.partnersName || selectedValue.partnerName !== ""
              //     ? true
              //     : false
              // }
              id="demo-simple-select-filled-label1"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Partners Name
            </InputLabel>
            <Select
              open={openSelect.partnersName ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  partnerName: e.target.value,
                  projectName: "",
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              renderValue={() => selectedValue.partnerName || ""}
              value={selectedValue.partnerName || ""}
              defaultValue={selectedValue.partnerName || ""}
            >
              {!selectedValue.country && (
                <MenuItem value={""}>
                  <em>Select a Country</em>{" "}
                </MenuItem>
              )}

              {user?.projects.partnersName
                ?.find((e) => e.country === selectedValue.country)
                ?.partnerName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value}
                      {user?.projects?.projectName?.find(
                        (e) =>
                          e.country === selectedValue.country &&
                          e.partnerName === value
                      )?.projectName?.length
                        ? ` - [ ${
                            user?.projects?.projectName?.find(
                              (e) =>
                                e.country === selectedValue.country &&
                                e.partnerName === value
                            )?.projectName?.length
                          } ]`
                        : null}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={() =>
            setOpenSelect({
              ...openSelect,
              projectName: !openSelect.projectName,
            })
          }
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              // shrink={
              //   openSelect.projectName || selectedValue.projectName !== ""
              //     ? true
              //     : false
              // }
              id="demo-simple-select-filled-label2"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Name
            </InputLabel>
            <Select
              open={openSelect.projectName ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  projectName: e.target.value,
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              value={selectedValue.projectName || ""}
              defaultValue={""}
            >
              {!selectedValue.partnerName && (
                <MenuItem value={""}>
                  <em>Select a Partner Name</em>{" "}
                </MenuItem>
              )}

              {user?.projects?.projectName
                ?.find(
                  (e) =>
                    e.country === selectedValue.country &&
                    e.partnerName === selectedValue.partnerName
                )
                ?.projectName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {`${value.name} (${value.capacity})`}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={(e) => {
            setOpenSelect({
              ...openSelect,
              projectDetail: !openSelect.projectDetail,
            });
          }}
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              shrink={
                openSelect.projectDetail || selectedValue.projectDetail !== ""
                  ? true
                  : false
              }
              id="demo-simple-select-filled-label3"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Detail
            </InputLabel>
            <Select
              open={openSelect.projectDetail ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{
                color: "white",
                fontSize: "1.8vmin",
              }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              // onChange={(e) => {
              //   setSelectedValue({
              //     ...selectedValue,
              //     projectDetail: e.target.value,
              //   });
              // }}
              defaultValue={selectedValue.projectDetail || ""}
              value={selectedValue.projectDetail || ""}
              renderValue={() => selectedValue.projectDetail || ""}
            >
              {!selectedValue?.projectName ? (
                <MenuItem value={""}>
                  <em>Select a Project Name</em>{" "}
                </MenuItem>
              ) : loading2 ? (
                <MenuItem>
                  <div
                    style={{
                      margin: "auto",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span>Fetching Project Detail</span>
                    <FetchProjectDetailLoader />
                  </div>
                </MenuItem>
              ) : (
                !projectDetail && (
                  <MenuItem>
                    <span>Project Not Found</span>
                  </MenuItem>
                )
              )}
              {selectedValue.projectName && projectDetail && (
                <ListSubheader className="SubHeader">
                  <span
                    onClick={(event) => {
                      onclickHandler("projectDetail");
                      event.stopPropagation();
                    }}
                  >
                    Overview Details
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.projectDetail ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData.projectDetail &&
                [
                  {
                    url: PageURL.INDIA_PROJ_OVERVIEW,
                    value: "Project OverView",
                  },
                  {
                    url: PageURL.INDIA_GEN_SUMMARY,
                    value: "Generation Summary",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={(event) => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(`${e.url}?${serachParams.toString()}`);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {selectedValue.projectName && projectDetail && (
                <ListSubheader className="SubHeader">
                  <span
                    onClick={(event) => {
                      onclickHandler("Diagonistic");
                      event.stopPropagation();
                    }}
                  >
                    Diagonistic Model
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Diagonistic ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.Diagonistic &&
                [
                  {
                    url: PageURL.INDIA_DIAGONISTIC_DETAILED,
                    value: "Detailed Summary",
                  },
                  { url: PageURL.INDIA_LOSS_FLOW, value: "Loss Flow Diagram" },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={() => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(`${e.url}?${serachParams.toString()}`);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {selectedValue.projectName &&
                projectDetail &&
                projectDetail?.plantDataAvailability?.inverterEfficiency && (
                  <ListSubheader className="SubHeader">
                    <span
                      onClick={(event) => {
                        onclickHandler("inverterEfficiency");
                        if (!inverterMappingArray) {
                          dispatch(inverterMappingArrayAction(databaseStr));
                        }
                        event.stopPropagation();
                      }}
                    >
                      Inverter Efficiency
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.inverterEfficiency ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}

              {checkedData.inverterEfficiency &&
                (inverterMappingLoader ? (
                  <MenuItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FetchProjectDetailLoader width={"25px"} />
                  </MenuItem>
                ) : !inverterMappingArray ? (
                  <MenuItem>Inverter Data Not Found</MenuItem>
                ) : (
                  inverterMappingArray?.length == 0 && (
                    <MenuItem>Inverter Data Not Available</MenuItem>
                  )
                ))}

              { projectDetail && checkedData.inverterEfficiency &&
                inverterMappingArray?.length > 0 && (
                  <MenuItem
                    value={"All Inverters"}
                    onClick={() => {
                      dispatch(
                        inverterEfficiencyAction(
                          {
                            block: null,
                            inverter: "All Inverters",
                            inverterType: null,
                          },

                          {},
                          databaseStr
                        )
                      );
                      setscbsmbDetail({
                        ...scbsmbDetail,
                        block: null,
                        inverter: "All Inverters",
                        inverterType: null,
                        scbsmb: null,
                        scbsmbType: null,
                      });
                      setSelectedValue({
                        ...selectedValue,
                        projectDetail: "All Inverters",
                      });

                      if (
                        location.pathname !== PageURL.INDIA_INVERTER_EFFICIENCY
                      ) {
                        navigate(
                          `${
                            PageURL.INDIA_INVERTER_EFFICIENCY
                          }?${serachParams.toString()}`
                        );
                      }
                    }}
                  >
                    All Inverters
                  </MenuItem>
                )}
              {checkedData.inverterEfficiency &&
                inverterMappingArray?.map((e, index) => {
                  return (
                    <Box key={index}>
                      <ListSubheader
                        key={e.name + "Efficiency"}
                        className="SubHeader"
                      >
                        <span
                          onClick={(event) => {
                            onclickHandler(
                              e.name + "Efficiency",
                              "inverterEfficiency"
                            );
                            event.stopPropagation();
                          }}
                        >
                          {" "}
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name + "Efficiency"] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {checkedData[e.name + "Efficiency"] && (
                        <>
                          {e?.inverters?.length > 1 && (
                            <MenuItem
                              key={e?.name + "All Inverter"}
                              value={"All Inverter"}
                              onClick={() => {
                                // if (
                                //   selectedValue.projectDetail ===
                                //     "All Inverter" &&
                                //   scbsmbDetail.block === e?.name
                                // )
                                //   return;
                                setSelectedValue({
                                  ...selectedValue,
                                  projectDetail: "All Inverter",
                                });
                                setscbsmbDetail({
                                  ...scbsmbDetail,
                                  block: e?.name,
                                  inverter: "All Inverter",
                                  inverterType: e?.type,
                                  scbsmb: null,
                                  scbsmbType: null,
                                });

                                if (
                                  location.pathname !==
                                  PageURL.INDIA_INVERTER_EFFICIENCY
                                ) {
                                  navigate(
                                    `${
                                      PageURL.INDIA_INVERTER_EFFICIENCY
                                    }?${serachParams.toString()}`
                                  );
                                }
                              }}
                            >
                              All Inverter
                            </MenuItem>
                          )}
                          {e?.inverters?.map((ee, index3) => {
                            return (
                              <MenuItem
                                key={index3}
                                value={ee}
                                onClick={() => {
                                  // if (
                                  //   selectedValue.projectDetail === ee &&
                                  //   scbsmbDetail.block === e?.name
                                  // ) {
                                  //   return;
                                  // }

                                  setSelectedValue({
                                    ...selectedValue,
                                    projectDetail: ee,
                                  });
                                  setscbsmbDetail({
                                    ...scbsmbDetail,
                                    block: e?.name,
                                    inverter: ee,
                                    inverterType: e?.type,
                                    scbsmb: null,
                                    scbsmbType: null,
                                  });

                                  if (
                                    location.pathname !==
                                    PageURL.INDIA_INVERTER_EFFICIENCY
                                  ) {
                                    navigate(
                                      `${
                                        PageURL.INDIA_INVERTER_EFFICIENCY
                                      }?${serachParams.toString()}`
                                    );
                                  }
                                }}
                              >
                                {ee}
                              </MenuItem>
                            );
                          })}
                        </>
                      )}
                    </Box>
                  );
                })}

              {selectedValue.projectName &&
                projectDetail &&
                projectDetail?.plantDataAvailability?.inverterDeviation && (
                  <ListSubheader className="SubHeader">
                    <span
                      onClick={(event) => {
                        if (!inverterMappingArray) {
                          dispatch(inverterMappingArrayAction(databaseStr));
                        }
                        onclickHandler("inverterDeviation");
                        event.stopPropagation();
                      }}
                    >
                      Inverter Deviation
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.inverterDeviation ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}

              {checkedData.inverterDeviation &&
                (inverterMappingLoader ? (
                  <MenuItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FetchProjectDetailLoader width={"25px"} />
                  </MenuItem>
                ) : !inverterMappingArray ? (
                  <MenuItem>Inverter Data Not Found</MenuItem>
                ) : (
                  inverterMappingArray?.length == 0 && (
                    <MenuItem>Inverter Data Not Available</MenuItem>
                  )
                ))}
              {projectDetail && checkedData.inverterDeviation &&
                inverterMappingArray?.length > 0 && (
                  <MenuItem
                    value={"All Inverters"}
                    onClick={() => {
                      // if (selectedValue.projectDetail === "All Inverters")
                      //   return;

                      setscbsmbDetail({
                        ...scbsmbDetail,
                        block: null,
                        inverter: "All Inverters",
                        inverterType: null,
                        scbsmb: null,
                        scbsmbType: null,
                      });
                      setSelectedValue({
                        ...selectedValue,
                        projectDetail: "All Inverters",
                      });

                      if (
                        location.pathname !== PageURL.INDIA_INVERTER_DEVIATION
                      ) {
                        navigate(
                          `${
                            PageURL.INDIA_INVERTER_DEVIATION
                          }?${serachParams.toString()}`
                        );
                      }
                    }}
                  >
                    All Inverters
                  </MenuItem>
                )}
              {checkedData.inverterDeviation &&
                inverterMappingArray?.map((e, index) => {
                  return (
                    <Box key={index}>
                      <ListSubheader
                        key={e.name + "Deviation"}
                        className="SubHeader"
                      >
                        <span
                          onClick={(event) => {
                            onclickHandler(
                              e.name + "Deviation",
                              "inverterDeviation"
                            );

                            event.stopPropagation();
                          }}
                        >
                          {" "}
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name + "Deviation"] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {checkedData[e.name + "Deviation"] && (
                        <>
                          {e?.inverters?.length > 1 && (
                            <MenuItem
                              key={e?.name + "All Inverter"}
                              value={"All Inverter"}
                              onClick={() => {
                                // if (
                                //   selectedValue.projectDetail ===
                                //     "All Inverter" &&
                                //   scbsmbDetail.block === e?.name
                                // )
                                // return;
                                setSelectedValue({
                                  ...selectedValue,
                                  projectDetail: "All Inverter",
                                });
                                setscbsmbDetail({
                                  ...scbsmbDetail,
                                  block: e?.name,
                                  inverter: "All Inverter",
                                  inverterType: e?.type,
                                  scbsmb: null,
                                  scbsmbType: null,
                                });

                                if (
                                  location.pathname !==
                                  PageURL.INDIA_INVERTER_DEVIATION
                                ) {
                                  navigate(
                                    `${
                                      PageURL.INDIA_INVERTER_DEVIATION
                                    }?${serachParams.toString()}`
                                  );
                                }
                              }}
                            >
                              All Inverter
                            </MenuItem>
                          )}

                          {e?.inverters?.map((ee, index3) => {
                            return (
                              <MenuItem
                                key={index3}
                                value={ee}
                                onClick={() => {
                                  // if (
                                  //   selectedValue.projectDetail === ee &&
                                  //   scbsmbDetail.block === e?.name
                                  // )
                                  //   return;
                                  setSelectedValue({
                                    ...selectedValue,
                                    projectDetail: ee,
                                  });
                                  setscbsmbDetail({
                                    ...scbsmbDetail,
                                    block: e?.name,
                                    inverter: ee,
                                    inverterType: e?.type,
                                    scbsmb: null,
                                    scbsmbType: null,
                                  });

                                  if (
                                    location.pathname !==
                                    PageURL.INDIA_INVERTER_DEVIATION
                                  ) {
                                    navigate(
                                      `${
                                        PageURL.INDIA_INVERTER_DEVIATION
                                      }?${serachParams.toString()}`
                                    );
                                  }
                                }}
                              >
                                {ee}
                              </MenuItem>
                            );
                          })}
                        </>
                      )}
                    </Box>
                  );
                })}
              {selectedValue.projectName &&
                projectDetail &&
                projectDetail?.plantDataAvailability?.scbSmbDeviation && (
                  <ListSubheader className="SubHeader">
                    <span
                      onClick={(e) => {
                        onclickHandler("string_smb");
                        if (!scbSmbMappingArray) {
                          dispatch(scbSmbMappingArrayAction(databaseStr));
                        }
                        e.stopPropagation();
                      }}
                    >
                      SCB/SMB
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.string_smb ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}
              {checkedData.string_smb &&
                (scbSmbMappingLoader ? (
                  <MenuItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    <FetchProjectDetailLoader width={"25px"} />
                  </MenuItem>
                ) : !scbSmbMappingArray ? (
                  <MenuItem>
                    {projectDetail?.plantDetail?.string || ""} Data Not Found
                  </MenuItem>
                ) : (
                  scbSmbMappingArray?.length == 0 && (
                    <MenuItem>
                      {projectDetail?.plantDetail?.string || ""} Data Not
                      Available
                    </MenuItem>
                  )
                ))}
              {checkedData.string_smb &&
                scbSmbMappingArray?.map((e, index1) => {
                  return (
                    <Box key={"SubHeader" + e.name + index1}>
                      {/* First Subheader */}
                      <ListSubheader className="SubHeader">
                        <span
                          onClick={(ee) => {
                            onclickHandler(e.name, "string_smb");
                            ee.stopPropagation();
                          }}
                        >
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {/* Conditionally show inverters if checked */}
                      {checkedData[e.name] &&
                        e?.inverters?.map((ee, index2) => {
                          return (
                            <Box key={"SubHeader2" + ee.name + index1 + index2}>
                              <ListSubheader
                                key={ee.name}
                                className="SubHeader"
                              >
                                <span
                                  onClick={(event) => {
                                    onclickHandler(
                                      e.name + ee.name,
                                      "string_smb",
                                      e.name
                                    );
                                    event.stopPropagation();
                                  }}
                                >
                                  {ee.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[e.name + ee.name]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {checkedData[e.name + ee.name] && (
                                <>
                                  {projectDetail?.plantDetail
                                    ?.string != "SMB" &&
                                    ee?.listScbsmb?.length > 1 && (
                                      <MenuItem
                                        key={
                                          e.name +
                                          ee.name +
                                          index1 +
                                          index2 +
                                          `All ${projectDetail?.plantDetail?.string}`
                                        }
                                        value={`All ${projectDetail?.plantDetail?.string}`}
                                        onClick={() => {
                                          // if (selectedValue.projectDetail === "HeatMap" && location.pathname===PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM)
                                          //   return;
                                          setSelectedValue({
                                            ...selectedValue,
                                            projectDetail: `All ${projectDetail?.plantDetail?.string}`,
                                          });

                                          setscbsmbDetail({
                                            ...scbsmbDetail,
                                            block: e?.name,
                                            inverter: ee?.name,
                                            inverterType: ee?.inverterType,
                                            scbsmb: `All ${projectDetail?.plantDetail?.string}`,
                                            scbsmbType: `${projectDetail?.plantDetail?.string}`,
                                          });
                                          if (
                                            location.pathname !==
                                            PageURL.SCBSMB_LOSS
                                          ) {
                                            navigate(
                                              `${
                                                PageURL.SCBSMB_LOSS
                                              }?${serachParams.toString()}`
                                            );
                                          }
                                        }}
                                      >
                                        {`All ${projectDetail?.plantDetail?.string}`}
                                      </MenuItem>
                                    )}

                                  <MenuItem
                                    key={
                                      e.name +
                                      ee.name +
                                      index1 +
                                      index2 +
                                      "HeatMap"
                                    }
                                    value={"HeatMap"}
                                    onClick={() => {
                                      // if (selectedValue.projectDetail === "HeatMap" && location.pathname===PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM)
                                      //   return;
                                      setSelectedValue({
                                        ...selectedValue,
                                        projectDetail: "HeatMap",
                                      });

                                      setscbsmbDetail({
                                        ...scbsmbDetail,
                                        block: e?.name,
                                        inverter: ee?.name,
                                        inverterType: ee?.inverterType,
                                        scbsmb: `All ${projectDetail?.plantDetail?.string}`,
                                        scbsmbType: `${projectDetail?.plantDetail?.string}`,
                                      });
                                      if (
                                        location.pathname !==
                                        PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                      ) {
                                        navigate(
                                          `${
                                            PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                          }?${serachParams.toString()}`
                                        );
                                      }
                                    }}
                                  >
                                    HeatMap
                                  </MenuItem>

                                  {ee?.listScbsmb?.map((eee, index3) => {
                                    return (
                                      <MenuItem
                                        key={
                                          e.name +
                                          ee.name +
                                          index1 +
                                          index2 +
                                          index3
                                        }
                                        value={eee}
                                        onClick={() => {
                                          if (
                                            selectedValue.projectDetail ===
                                              eee &&
                                            scbsmbDetail.block === e?.name &&
                                            scbsmbDetail.inverter === ee?.name
                                          )
                                            return;

                                          setSelectedValue({
                                            ...selectedValue,
                                            projectDetail: eee,
                                          });

                                          setscbsmbDetail({
                                            ...scbsmbDetail,
                                            block: e?.name,
                                            inverter: ee?.name,
                                            inverterType: ee?.inverterType,
                                            scbsmb: eee,
                                            scbsmbType: `${projectDetail?.plantDetail?.string}`,
                                          });

                                          if (
                                            location.pathname !==
                                            PageURL.SCBSMB_LOSS
                                          ) {
                                            navigate(
                                              `${
                                                PageURL.SCBSMB_LOSS
                                              }?${serachParams.toString()}`
                                            );
                                          }
                                          // You can navigate to e.url here
                                        }}
                                      >
                                        {eee}
                                      </MenuItem>
                                    );
                                  })}
                                </>
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}
              {/* { (
                <ListSubheader className="SubHeader last_item">
                  {" "}
                  <span onClick={() => onclickHandler("Prescritive")}>
                    Prescriptive Model{" "}
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Prescritive ? "active" : ""
                      }`}
                    ></i>
                   
                  </span>
                </ListSubheader>
              )}
              {checkedData.Prescritive &&
                [
                  {
                    url: PageURL.INDIA_PRES_MODEL_Fault_Tree,
                    value: "Fault Tree Diagram",
                  },
                  {
                    url: PageURL.INDIA_PRES_MODEL_Loss_Flow,
                    value: "Loss Flow Diagram",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })} */}
            </Select>
          </FormControl>
        </span>
      </div>

      {/*  ##### Code For Header Sidebar In Large Devices Start ##### */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "0vmax",
        }}
        className="detail-link"
      >
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Country
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true,
                }
              }
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  country: e.target.value,
                  partnerName: "",
                  projectName: "",
                  projectDetail: "",
                });

                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
              value={selectedValue.country || ""}
              defaultValue={selectedValue.country || ""}
              renderValue={() => selectedValue.country || ""}
            >
              {user?.projects?.country?.length == 0 ||
                (!user?.projects?.country && (
                  <MenuItem value={""}>
                    <em>None</em>{" "}
                  </MenuItem>
                ))}

              {user?.projects?.country?.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                    {user?.projects?.partnersName?.find(
                      (e) => e.country === value
                    )?.partnerName?.length
                      ? ` - [ ${
                          user?.projects?.partnersName?.find(
                            (e) => e.country === value
                          )?.partnerName?.length
                        } ]`
                      : null}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label1"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Partners Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  partnerName: e.target.value,
                  projectName: "",
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              renderValue={() => selectedValue.partnerName || ""}
              value={selectedValue.partnerName || ""}
              defaultValue={selectedValue.partnerName || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {!selectedValue.country && (
                <MenuItem value={""}>
                  <em>Select a Country</em>{" "}
                </MenuItem>
              )}

              {user?.projects?.partnersName
                ?.find((e) => e.country === selectedValue.country)
                ?.partnerName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value} onClick={() => {}}>
                      {value}
                      {user?.projects?.projectName?.find(
                        (e) =>
                          e.country === selectedValue.country &&
                          e.partnerName === value
                      )?.projectName?.length
                        ? ` - [ ${
                            user?.projects?.projectName?.find(
                              (e) =>
                                e.country === selectedValue.country &&
                                e.partnerName === value
                            )?.projectName?.length
                          } ]`
                        : null}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label2"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  projectName: e.target.value,
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              value={selectedValue.projectName || ""}
              // renderValue={()=>selectedValue.projectName || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {!selectedValue.partnerName && (
                <MenuItem value={""}>
                  <em>Select a Partner Name</em>{" "}
                </MenuItem>
              )}

              {user?.projects?.projectName
                ?.find(
                  (e) =>
                    e.country === selectedValue.country &&
                    e.partnerName === selectedValue.partnerName
                )
                ?.projectName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {`${value.name} (${value.capacity})`}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <div
          className="dropdown"
          onClick={(e) => {
            setOpenSelect({
              ...openSelect,
              projectDetail2: !openSelect.projectDetail2,
            });
          }}
        >
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label3"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Detail
            </InputLabel>
            <Select
              open={openSelect.projectDetail2 ? true : false}
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              // onChange={(e) => {
              //   setSelectedValue({
              //     ...selectedValue,
              //     projectDetail: e.target.value,
              //   });
              // }}
              defaultValue={selectedValue.projectDetail || ""}
              value={selectedValue.projectDetail || ""}
              renderValue={() => selectedValue.projectDetail || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {!selectedValue?.projectName ? (
                <MenuItem value={""}>
                  <em>Select a Project Name</em>{" "}
                </MenuItem>
              ) : loading2 ? (
                <MenuItem>
                  <div
                    style={{
                      margin: "auto",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span>Fetching Project Detail</span>
                    <FetchProjectDetailLoader />
                  </div>
                </MenuItem>
              ) : (
                !projectDetail && (
                  <MenuItem>
                    <span>Project Not Found</span>
                  </MenuItem>
                )
              )}

              {selectedValue.projectName && projectDetail && (
                <ListSubheader className="SubHeader">
                  <span
                    onClick={(event) => {
                      onclickHandler("projectDetail");
                      event.stopPropagation();
                    }}
                  >
                    Overview Details
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.projectDetail ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.projectDetail &&
                [
                  {
                    url: PageURL.INDIA_PROJ_OVERVIEW,
                    value: "Project OverView",
                  },

                  {
                    url: PageURL.INDIA_GEN_SUMMARY,
                    value: "Generation Summary",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={() => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(`${e.url}?${serachParams.toString()}`);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {selectedValue.projectName && projectDetail && (
                <ListSubheader className="SubHeader">
                  <span
                    onClick={(event) => {
                      event.stopPropagation();
                      onclickHandler("Diagonistic");
                    }}
                  >
                    Diagonistic Model
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Diagonistic ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.Diagonistic &&
                [
                  {
                    url: PageURL.INDIA_DIAGONISTIC_DETAILED,
                    value: "Detailed Summary",
                  },
                  { url: PageURL.INDIA_LOSS_FLOW, value: "Loss Flow Diagram" },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={() => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(`${e.url}?${serachParams.toString()}`);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {selectedValue.projectName &&
                projectDetail &&
                projectDetail?.plantDataAvailability?.inverterEfficiency && (
                  <ListSubheader className="SubHeader">
                    <span
                      onClick={(event) => {
                        onclickHandler("inverterEfficiency");
                        if (!inverterMappingArray) {
                          dispatch(inverterMappingArrayAction(databaseStr));
                        }
                        event.stopPropagation();
                      }}
                    >
                      Inverter Efficiency
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.inverterEfficiency ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}
              {checkedData.inverterEfficiency &&
                (inverterMappingLoader ? (
                  <MenuItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FetchProjectDetailLoader width={"25px"} />
                  </MenuItem>
                ) : !inverterMappingArray ? (
                  <MenuItem>Inverter Data Not Found</MenuItem>
                ) : (
                  inverterMappingArray?.length == 0 && (
                    <MenuItem>Inverter Data Not Available</MenuItem>
                  )
                ))}
              {projectDetail && checkedData.inverterEfficiency &&
                inverterMappingArray?.length > 0 && (
                  <MenuItem
                    value={"All Inverters"}
                    onClick={() => {
                      // if (selectedValue.projectDetail === "All Inverters")
                      //   return;

                      setscbsmbDetail({
                        ...scbsmbDetail,
                        block: null,
                        inverter: "All Inverters",
                        inverterType: null,
                        scbsmb: null,
                        scbsmbType: null,
                      });
                      setSelectedValue({
                        ...selectedValue,
                        projectDetail: "All Inverters",
                      });

                      if (
                        location.pathname !== PageURL.INDIA_INVERTER_EFFICIENCY
                      ) {
                        navigate(
                          `${
                            PageURL.INDIA_INVERTER_EFFICIENCY
                          }?${serachParams.toString()}`
                        );
                      }
                    }}
                  >
                    All Inverters
                  </MenuItem>
                )}
              {checkedData.inverterEfficiency &&
                inverterMappingArray?.map((e, index) => {
                  return (
                    <Box key={index}>
                      <ListSubheader
                        key={e.name + "Efficiency"}
                        className="SubHeader"
                      >
                        <span
                          onClick={(event) => {
                            onclickHandler(
                              e.name + "Efficiency",
                              "inverterEfficiency"
                            );

                            event.stopPropagation();
                          }}
                        >
                          {" "}
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name + "Efficiency"] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {checkedData[e.name + "Efficiency"] && (
                        <>
                          {e?.inverters?.length > 1 && (
                            <MenuItem
                              key={e?.name + "All Inverter"}
                              value={"All Inverter"}
                              onClick={() => {
                                // if (
                                //   selectedValue.projectDetail ===
                                //     "All Inverter" &&
                                //   scbsmbDetail.block === e?.name
                                // )
                                //   return;
                                setSelectedValue({
                                  ...selectedValue,
                                  projectDetail: "All Inverter",
                                });
                                setscbsmbDetail({
                                  ...scbsmbDetail,
                                  block: e?.name,
                                  inverter: "All Inverter",
                                  inverterType: e?.type,
                                  scbsmb: null,
                                  scbsmbType: null,
                                });

                                if (
                                  location.pathname !==
                                  PageURL.INDIA_INVERTER_EFFICIENCY
                                ) {
                                  navigate(
                                    `${
                                      PageURL.INDIA_INVERTER_EFFICIENCY
                                    }?${serachParams.toString()}`
                                  );
                                }
                              }}
                            >
                              All Inverter
                            </MenuItem>
                          )}

                          {e?.inverters?.map((ee, index3) => {
                            return (
                              <MenuItem
                                key={index3}
                                value={ee}
                                onClick={() => {
                                  // if (
                                  //   selectedValue.projectDetail === ee &&
                                  //   scbsmbDetail.block === e?.name
                                  // )
                                  //   return;
                                  setSelectedValue({
                                    ...selectedValue,
                                    projectDetail: ee,
                                  });
                                  setscbsmbDetail({
                                    ...scbsmbDetail,
                                    block: e?.name,
                                    inverter: ee,
                                    inverterType: e?.type,
                                    scbsmb: null,
                                    scbsmbType: null,
                                  });

                                  if (
                                    location.pathname !==
                                    PageURL.INDIA_INVERTER_EFFICIENCY
                                  ) {
                                    navigate(
                                      `${
                                        PageURL.INDIA_INVERTER_EFFICIENCY
                                      }?${serachParams.toString()}`
                                    );
                                  }
                                }}
                              >
                                {ee}
                              </MenuItem>
                            );
                          })}
                        </>
                      )}
                    </Box>
                  );
                })}

              {selectedValue.projectName &&
                projectDetail &&
                projectDetail?.plantDataAvailability?.inverterDeviation && (
                  <ListSubheader className="SubHeader">
                    <span
                      onClick={(event) => {
                        onclickHandler("inverterDeviation");
                        if (!inverterMappingArray) {
                          dispatch(inverterMappingArrayAction(databaseStr));
                        }
                        event.stopPropagation();
                      }}
                    >
                      Inverter Deviation
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.inverterDeviation ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}
              {checkedData.inverterDeviation &&
                (inverterMappingLoader ? (
                  <MenuItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FetchProjectDetailLoader width={"25px"} />
                  </MenuItem>
                ) : !inverterMappingArray ? (
                  <MenuItem>Inverter Data Not Found</MenuItem>
                ) : (
                  inverterMappingArray?.length == 0 && (
                    <MenuItem>Inverter Data Not Available</MenuItem>
                  )
                ))}
              {projectDetail && checkedData.inverterDeviation &&
                inverterMappingArray?.length > 0 && (
                  <MenuItem
                    value={"All Inverters"}
                    onClick={() => {
                      // if (selectedValue.projectDetail === "All Inverters")
                      //   return;

                      setscbsmbDetail({
                        ...scbsmbDetail,
                        block: null,
                        inverter: "All Inverters",
                        inverterType: null,
                        scbsmb: null,
                        scbsmbType: null,
                      });
                      setSelectedValue({
                        ...selectedValue,
                        projectDetail: "All Inverters",
                      });
                      if (
                        location.pathname !== PageURL.INDIA_INVERTER_DEVIATION
                      ) {
                        navigate(
                          `${
                            PageURL.INDIA_INVERTER_DEVIATION
                          }?${serachParams.toString()}`
                        );
                      }
                    }}
                  >
                    All Inverters
                  </MenuItem>
                )}
              {checkedData.inverterDeviation &&
                inverterMappingArray?.map((e, index) => {
                  return (
                    <Box key={index}>
                      <ListSubheader
                        key={e.name + "Deviation"}
                        className="SubHeader"
                      >
                        <span
                          onClick={(event) => {
                            onclickHandler(
                              e.name + "Deviation",
                              "inverterDeviation"
                            );

                            event.stopPropagation();
                          }}
                        >
                          {" "}
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name + "Deviation"] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {checkedData[e.name + "Deviation"] && (
                        <>
                          {e?.inverters?.length > 1 && (
                            <MenuItem
                              key={e?.name + "All Inverter"}
                              value={"All Inverter"}
                              onClick={() => {
                                // if (
                                //   selectedValue.projectDetail ===
                                //     "All Inverter" &&
                                //   scbsmbDetail.block === e?.name
                                // )
                                //   return;
                                setSelectedValue({
                                  ...selectedValue,
                                  projectDetail: "All Inverter",
                                });
                                setscbsmbDetail({
                                  ...scbsmbDetail,
                                  block: e?.name,
                                  inverter: "All Inverter",
                                  inverterType: e?.type,
                                  scbsmb: null,
                                  scbsmbType: null,
                                });

                                if (
                                  location.pathname !==
                                  PageURL.INDIA_INVERTER_DEVIATION
                                ) {
                                  navigate(
                                    `${
                                      PageURL.INDIA_INVERTER_DEVIATION
                                    }?${serachParams.toString()}`
                                  );
                                }
                              }}
                            >
                              All Inverter
                            </MenuItem>
                          )}

                          {e?.inverters?.map((ee, index3) => {
                            return (
                              <MenuItem
                                key={index3}
                                value={ee}
                                onClick={() => {
                                  // if (
                                  //   selectedValue.projectDetail === ee &&
                                  //   scbsmbDetail.block === e?.name
                                  // )
                                  //   return;
                                  setSelectedValue({
                                    ...selectedValue,
                                    projectDetail: ee,
                                  });
                                  setscbsmbDetail({
                                    ...scbsmbDetail,
                                    block: e?.name,
                                    inverter: ee,
                                    inverterType: e?.type,
                                    scbsmb: null,
                                    scbsmbType: null,
                                  });

                                  if (
                                    location.pathname !==
                                    PageURL.INDIA_INVERTER_DEVIATION
                                  ) {
                                    navigate(
                                      `${
                                        PageURL.INDIA_INVERTER_DEVIATION
                                      }?${serachParams.toString()}`
                                    );
                                  }
                                }}
                              >
                                {ee}
                              </MenuItem>
                            );
                          })}
                        </>
                      )}
                    </Box>
                  );
                })}
              {selectedValue.projectName &&
                projectDetail &&
                projectDetail?.plantDataAvailability?.scbSmbDeviation && (
                  <ListSubheader className="SubHeader">
                    <span
                      onClick={(e) => {
                        onclickHandler("string_smb");
                        if (!scbSmbMappingArray) {
                          dispatch(scbSmbMappingArrayAction(databaseStr));
                        }
                        e.stopPropagation();
                      }}
                    >
                      SCB/SMB
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.string_smb ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}

              {checkedData.string_smb &&
                (scbSmbMappingLoader ? (
                  <MenuItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    <FetchProjectDetailLoader width={"25px"} />
                  </MenuItem>
                ) : !scbSmbMappingArray ? (
                  <MenuItem>
                    {projectDetail?.plantDetail?.string || ""} Data Not Found
                  </MenuItem>
                ) : (
                  scbSmbMappingArray?.length == 0 && (
                    <MenuItem>
                      {projectDetail?.plantDetail?.string || ""} Data Not
                      Available
                    </MenuItem>
                  )
                ))}
              {checkedData.string_smb &&
                scbSmbMappingArray?.map((e, index1) => {
                  return (
                    <Box key={"SubHeader" + e.name + index1}>
                      {/* First Subheader */}
                      <ListSubheader className="SubHeader">
                        <span
                          onClick={(ee) => {
                            onclickHandler(e.name, "string_smb");
                            ee.stopPropagation();
                          }}
                        >
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {/* Conditionally show inverters if checked */}
                      {checkedData[e.name] &&
                        e?.inverters?.map((ee, index2) => {
                          return (
                            <Box key={"SubHeader2" + ee.name + index1 + index2}>
                              <ListSubheader
                                key={ee.name}
                                className="SubHeader"
                              >
                                <span
                                  onClick={(event) => {
                                    onclickHandler(
                                      e.name + ee.name,
                                      "string_smb",
                                      e.name
                                    );
                                    event.stopPropagation();
                                  }}
                                >
                                  {ee.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[e.name + ee.name]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {checkedData[e.name + ee.name] && (
                                <>
                                  {projectDetail?.plantDetail?.string !=
                                    "SMB" &&
                                    ee?.listScbsmb?.length > 1 && (
                                      <MenuItem
                                        key={
                                          e.name +
                                          ee.name +
                                          index1 +
                                          index2 +
                                          `All ${
                                            projectDetail?.plantDetail
                                              ?.string || ""
                                          }`
                                        }
                                        value={`All ${
                                          projectDetail?.plantDetail?.string ||
                                          ""
                                        }`}
                                        onClick={() => {
                                          // if (selectedValue.projectDetail === "HeatMap" && location.pathname===PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM)
                                          //   return;
                                          setSelectedValue({
                                            ...selectedValue,
                                            projectDetail: `All ${
                                              projectDetail?.plantDetail
                                                ?.string || ""
                                            }`,
                                          });

                                          setscbsmbDetail({
                                            ...scbsmbDetail,
                                            block: e?.name,
                                            inverter: ee?.name,
                                            inverterType: ee?.inverterType,
                                            scbsmb: `All ${
                                              projectDetail?.plantDetail
                                                ?.string || ""
                                            }`,
                                            scbsmbType: `${
                                              projectDetail?.plantDetail
                                                ?.string || ""
                                            }`,
                                          });
                                          if (
                                            location.pathname !==
                                            PageURL.SCBSMB_LOSS
                                          ) {
                                            navigate(
                                              `${
                                                PageURL.SCBSMB_LOSS
                                              }?${serachParams.toString()}`
                                            );
                                          }
                                        }}
                                      >
                                        {`All ${
                                          projectDetail?.plantDetail?.string ||
                                          ""
                                        }`}
                                      </MenuItem>
                                    )}
                                  <MenuItem
                                    key={
                                      e.name +
                                      ee.name +
                                      index1 +
                                      index2 +
                                      "HeatMap"
                                    }
                                    value={"HeatMap"}
                                    onClick={() => {
                                      // if (selectedValue.projectDetail === "HeatMap" && location.pathname===PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM)
                                      //   return;
                                      setSelectedValue({
                                        ...selectedValue,
                                        projectDetail: "HeatMap",
                                      });

                                      setscbsmbDetail({
                                        ...scbsmbDetail,
                                        block: e?.name,
                                        inverter: ee?.name,
                                        inverterType: ee?.inverterType,
                                        scbsmb: `All ${
                                          projectDetail?.plantDetail?.string ||
                                          ""
                                        }`,
                                        scbsmbType: `${
                                          projectDetail?.plantDetail?.string ||
                                          ""
                                        }`,
                                      });
                                      if (
                                        location.pathname !==
                                        PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                      ) {
                                        navigate(
                                          `${
                                            PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                          }?${serachParams.toString()}`
                                        );
                                      }
                                    }}
                                  >
                                    HeatMap
                                  </MenuItem>
                                  {ee?.listScbsmb?.map((eee, index3) => (
                                    <MenuItem
                                      key={
                                        e.name +
                                        ee.name +
                                        index1 +
                                        index2 +
                                        index3
                                      }
                                      value={eee}
                                      onClick={() => {
                                        if (
                                          selectedValue.projectDetail === eee &&
                                          scbsmbDetail.block === e?.name &&
                                          scbsmbDetail.inverter === ee?.name
                                        )
                                          return;
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: eee,
                                        });

                                        setscbsmbDetail({
                                          ...scbsmbDetail,
                                          block: e?.name,
                                          inverter: ee?.name,
                                          inverterType: ee?.inverterType,
                                          scbsmb: eee,
                                          scbsmbType: `${
                                            projectDetail?.plantDetail
                                              ?.string || ""
                                          }`,
                                        });
                                        if (
                                          location.pathname !==
                                          PageURL.SCBSMB_LOSS
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.SCBSMB_LOSS
                                            }?${serachParams.toString()}`
                                          );
                                        }

                                        // You can navigate to e.url here
                                      }}
                                    >
                                      {eee}
                                    </MenuItem>
                                  ))}
                                </>
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}

              {/* { (
                <ListSubheader className="SubHeader last_item">
                  {" "}
                  <span onClick={() => onclickHandler("Prescritive")}>
                    Prescriptive Model{" "}
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Prescritive ? "active" : ""
                      }`}
                    ></i>
                   
                  </span>
                </ListSubheader>
              )}
              {checkedData.Prescritive &&
                [
                  {
                    url: PageURL.INDIA_PRES_MODEL_Fault_Tree,
                    value: "Fault Tree Diagram",
                  },
                  {
                    url: PageURL.INDIA_PRES_MODEL_Loss_Flow,
                    value: "Loss Flow Diagram",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })} */}
            </Select>
          </FormControl>
        </div>
      </div>

      {/*  ##### Code For Header Sidebar In Large Devices End ##### */}
    </div>
  );
};

export default CountryHeader3;
