import React, { useEffect, useState } from "react";
import CountryHeader from "../components/Headers/CountryHeader";
import "./CountryDefault.css";
import AdminDashBoard from "../components/Admin/AdminDashBoard";
import { useSelector } from "react-redux";
import ProjectDetailBody from "./ProjectDetails/ProjectDetailBody";
import Footer from "../../../components/Footer";
import { useLocation } from "react-router-dom";
import PageURL from "../../../constants/PageURL";

const CountryDefault = () => {

  const [isAdminDashBoard, setIsAdminDashBoard] = useState(false);
  const { user={} } = useSelector(state => state.user)
  const location = useLocation();

  useEffect(() => {

  }, [user])

  return (
    <>
      <div className="main-body-1"  >
            <CountryHeader a={6} setDashBoard={setIsAdminDashBoard} />
        {!isAdminDashBoard && location.pathname !== PageURL.PROJECT_ANALYTICS && (
          <ProjectDetailBody />
        )}
        {isAdminDashBoard && (
          <div className="country-body">
            <AdminDashBoard />
          </div>
        )}

      </div>
      <Footer />

    </>
  );
};

export default CountryDefault;
