// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top */

.recharts-tooltip-wrapper-top {
  pointer-events: all !important;
}
.custom-tooltip-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.2rem;
  color: black;
  opacity: 1.3;
  box-shadow: 2px 2px 3px black;
  max-height: 150px;
  max-width: 260px;
  overflow: auto;
  padding: 0 0.6rem;
  padding-bottom: 0.6rem;
}
.custom-tooltip-container > :first-child {
  background-color: #fff;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0.6rem;
}

.graph-legend {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  flex-wrap: wrap;
  width: 100%;
  color: black;
  margin: auto;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  z-index: 1;
  position: sticky;
  left: 0;
  bottom: 0;

}
.graph-legend>:first-child{
    position: sticky;
    left: 0;
    bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/LegendTooltip.css"],"names":[],"mappings":"AAAA,yFAAyF;;AAEzF;EACE,8BAA8B;AAChC;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,MAAM;EACN,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,wBAAmB;EAAnB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,OAAO;EACP,SAAS;;AAEX;AACA;IACI,gBAAgB;IAChB,OAAO;IACP,SAAS;AACb","sourcesContent":["/* recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top */\r\n\r\n.recharts-tooltip-wrapper-top {\r\n  pointer-events: all !important;\r\n}\r\n.custom-tooltip-container {\r\n  background-color: #fff;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n  align-items: flex-start;\r\n  gap: 0.2rem;\r\n  color: black;\r\n  opacity: 1.3;\r\n  box-shadow: 2px 2px 3px black;\r\n  max-height: 150px;\r\n  max-width: 260px;\r\n  overflow: auto;\r\n  padding: 0 0.6rem;\r\n  padding-bottom: 0.6rem;\r\n}\r\n.custom-tooltip-container > :first-child {\r\n  background-color: #fff;\r\n  width: 100%;\r\n  position: sticky;\r\n  top: 0;\r\n  padding: 0.6rem;\r\n}\r\n\r\n.graph-legend {\r\n  text-align: center;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  background-color: #fff;\r\n  flex-wrap: wrap;\r\n  width: 100%;\r\n  color: black;\r\n  margin: auto;\r\n  height: fit-content;\r\n  position: relative;\r\n  z-index: 1;\r\n  position: sticky;\r\n  left: 0;\r\n  bottom: 0;\r\n\r\n}\r\n.graph-legend>:first-child{\r\n    position: sticky;\r\n    left: 0;\r\n    bottom: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
