import React, { useEffect, useMemo, useRef, useState } from "react";
import Printer2 from "../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../constants/Utils";
import SpeedChart from "../../components/SpeedChart";
import { saveAs } from "file-saver";
import SelectOptions from "./SelectOptions";
import { Grid } from "@mui/material";

const NetContractual = ({
  netContractualEnergyShortfall,
  loading,
  showPVsystEnergy,
}) => {
  const graphRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const minMaxRange = useMemo(() => {
    if (netContractualEnergyShortfall?.length > 0) {
      setSelectedDate(netContractualEnergyShortfall[0]?.Date);
      return {
        min: netContractualEnergyShortfall?.reduce(
          (acc, curr, index) =>
            Math.min(acc, curr["Net Energy"], curr["Contractual Energy"]),
          Infinity
        ),
        max: netContractualEnergyShortfall?.reduce(
          (acc, curr, index) =>
            Math.max(acc, curr["Net Energy"], curr["Contractual Energy"]),
          -Infinity
        ),
      };
    }
    return {};
  }, [netContractualEnergyShortfall]);
  const [PVsystContractual, setPVsystContractual] = useState({
    fileName: "Net Contractual Energy",
    name: "Contractual Energy",
    dataKey: "Contractual Energy",
  });

  useEffect(() => {
    if (showPVsystEnergy) {
      setPVsystContractual({
        ...PVsystContractual,
        fileName: "Net Pvsyst Energy",
        name: "PVsyst Energy",
        dataKey: "PVsyst Energy",
      });
    }
  }, [showPVsystEnergy]);

  return (
    <Grid
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      ref={graphRef}
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        padding: "1vmax",
        overflow: "auto",
      }}
    >
      <div
        style={{
          padding: "0 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <div
          style={{
            padding: "0 1.5rem",
            position: "sticky",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <select
            name="level"
            // id="level"
            style={{
              padding: "1vmin 1.5vmin",
              textAlign: "center",
              fontWeight: "bolder",
              borderStyle: "solid",
              borderColor: "#ED7D31",
              borderWidth: "3px",
              fontSize: "1rem",
              backgroundColor: "#edeaea",
            }}
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
          >
            {/* ###  Monthly Value component for Gaugemeter ### */}

            <SelectOptions
              dataArray={
                netContractualEnergyShortfall?.map((e) => e.Date) || []
              }
            />
          </select>
        </div>

        <Printer2
          dataSetDetail={{
            data:
              netContractualEnergyShortfall?.reduce((acc, curr) => {
                acc.push({
                  Date: curr.Date,
                  NetEnergy: curr["Net Energy"],
                  [PVsystContractual.name]: curr[PVsystContractual.dataKey],
                });
                return acc;
              }, []) || [],
            name: PVsystContractual.fileName || "data",
            ref: graphRef,
            refCurrent: graphRef.current,
          }}
        />
      </div>
      <div className="speed-chart-container">
        <SpeedChart
          title={`Net Energy (KWh)`}
          // minValue={minMaxRange.min&&minMaxRange?minMaxRange.min-(minMaxRange.min+minMaxRange.max)/2:0}
          // maxValue={minMaxRange.min&&minMaxRange?minMaxRange.max+(minMaxRange.min+minMaxRange.max)/2:0}
          minValue={0}
          // minValue={minMaxRange.min ||0}
          maxValue={minMaxRange.max || 0}
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.Date === selectedDate
            )?.["Net Energy"] || 0
          }
        />
        <SpeedChart
          title={`${PVsystContractual.name} (KWh)`}
          // minValue={minMaxRange.min&&minMaxRange?minMaxRange.min-(minMaxRange.min+minMaxRange.max)/2:0}
          // maxValue={minMaxRange.min&&minMaxRange?minMaxRange.max+(minMaxRange.min+minMaxRange.max)/2:0}
          minValue={0}
          // minValue={minMaxRange.min ||0}
          maxValue={minMaxRange.max || 0}
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.Date === selectedDate
            )?.[PVsystContractual.dataKey]
          }
        />
      </div>
      <div className="watermark-text">© GreenEnco</div>
      
    </Grid>
  );
};

export default NetContractual;
