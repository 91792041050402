import React, { useState } from "react";
import trasmission_tower from "../../fonts and images/transmission.png";
import amrMeter from "../../fonts and images/amr_meter.jpeg";
import transformer from "../../fonts and images/transformer.jpg";
import inverter2 from "../../fonts and images/inverter.png";
import scada from "../../fonts and images/scada.jpg";
import "./LossFlow.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import LossflowTable from "./LossflowTable";
import { Grid } from "@mui/material";

const LossFlow = () => {
  const [showCheckBox, setShowCheckBox] = useState({
    Module: false,
    Soiling: false,
    Shadow: false,
    Resistance: false,
    Overheating: false,
  });

  return (
    <Grid container sx={{ flex: 1 }}>
      <Grid container lg={12} className="loss-flow-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "auto",
              overflow: "auto",
              padding: "1vmin 0  5vmin 0",
              display: "flex",
              flexDirection: "column",
              //   alignItems: "center",
              gap: "15.2rem",
              border: "3px solid black",
            }}
          >
            <div className="animate-block-1">
              <div className="transmission-tower">
                <img
                  src={trasmission_tower}
                  alt="Transmission Tower"
                  title="Transmission Tower"
                />
                <span>Transmission Tower</span>
              </div>
              <ArrowBackIcon
                sx={{ fontSize: "1.9rem" }}
                className="direction-arrow"
              />
              <div className="amrmeter">
                <img src={amrMeter} alt="Ammeter" title="Ammeter" />
                <span>Ammeter</span>
              </div>
              <ArrowBackIcon
                sx={{ fontSize: "1.9rem" }}
                className="direction-arrow"
              />
              <div className="transformer">
                <img src={transformer} alt="Transformer" title="Transformer" />
                <span>Transformer</span>
              </div>
              <ArrowBackIcon
                sx={{ fontSize: "1.9rem", visibility: "hidden" }}
                className="direction-arrow"
              />
              <div className="inverter">
                <img src={inverter2} alt="Inverter" title="Inverter" />
                <span>Inverter</span>
                <div
                  style={{
                    background: showCheckBox.Overheating ? "blue" : "red",
                  }}
                >
                  <div
                    style={{
                      background: showCheckBox.Overheating ? " blue" : "red",
                    }}
                  >
                    <div
                      style={{
                        background: showCheckBox.Overheating ? "blue" : "red",
                      }}
                    >
                      <div>SMB Box</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "2%",
                    right: "93%",
                    width: "105%",
                    height: "0.15rem",
                    background: "red",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "7%",
                    right: "93%",
                    width: "105%",
                    height: "0.15rem",
                    background: "yellow",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "11.5%",
                    right: "93%",
                    width: "105%",
                    height: "0.15rem",
                    background: "blue",
                  }}
                ></div>

                <div
                  style={{
                    background: showCheckBox.Resistance
                      ? "7px dashed red"
                      : "7px dashed black",
                  }}
                >
                  <div
                    style={{
                      background: showCheckBox.Resistance
                        ? "7px dashed red"
                        : "7px dashed black",
                    }}
                  >
                    <div
                      style={{
                        background: showCheckBox.Resistance
                          ? "7px dashed red"
                          : "7px dashed black",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              {/* <ArrowBackIcon className="direction-arrow" /> */}
              <span></span>
              <div className="scada">
                <img alt="SCADA" src={scada} title="SCADA" />
                <span>SCADA</span>
              </div>
            </div>
            <div className="animate-block-2">
              <div>
                <WbSunnyIcon
                  sx={{
                    color: "#FDB813",
                    fontSize: "5rem",
                  }}
                />
                <span>Sun</span>
              </div>
              <div className="solar-box-1">
                <div
                  style={{
                    position: "absolute",
                    top: "-40%",
                    left: "20%",
                    height: "3rem",
                    borderLeft: showCheckBox.Resistance
                      ? "7px dashed red"
                      : "7px dashed black",
                  }}
                  className="left-border-connnector"
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "4rem",
                      borderTop: showCheckBox.Resistance
                        ? "7px dashed red"
                        : "7px dashed black",
                    }}
                    className="top-border-connnector"
                  >
                    <i
                      className="fa-solid fa-y y-connector"
                      style={{
                        position: "absolute",
                        fontSize: "3rem",
                        fontWeight: "bolder",
                        bottom: "-0.15rem",
                        right: "-47%",
                        rotate: "180deg",
                        color: showCheckBox.Resistance ? "red" : "black",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "95%",
                          left: "40%",
                          height: "1rem",
                          borderLeft: showCheckBox.Resistance
                            ? "7px dashed red"
                            : "7px dashed black",
                        }}
                        className="left-border-connnector"
                      ></div>
                    </i>
                  </div>
                </div>

                <div className="solar-string-box-1">
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-1"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-2"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-3"
                  >
                    solar panel{" "}
                  </div>
                </div>
                <div className="solar-string-box-2">
                  <div
                    style={{
                      position: "absolute",
                      top: "-40%",
                      left: "50%",
                      height: "3rem",
                      borderLeft: showCheckBox.Resistance
                        ? "7px dashed red"
                        : "7px dashed black",
                    }}
                    className="left-border-connnector"
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        width: "4rem",
                        borderTop: showCheckBox.Resistance
                          ? "7px dashed red"
                          : "7px dashed black",
                      }}
                      className="top-border-connnector"
                    ></div>
                  </div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-1"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-2"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-3"
                  >
                    solar panel
                  </div>
                </div>
              </div>
              <div className="solar-box-2">
                <div
                  style={{
                    position: "absolute",
                    top: "-40%",
                    left: "20%",
                    height: "3rem",
                    borderLeft: showCheckBox.Resistance
                      ? "7px dashed red"
                      : "7px dashed black",
                  }}
                  className="left-border-connnector"
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "4rem",
                      borderTop: showCheckBox.Resistance
                        ? "7px dashed red"
                        : "7px dashed black",
                    }}
                    className="top-border-connnector"
                  >
                    <i
                      className="fa-solid fa-y y-connector"
                      style={{
                        position: "absolute",
                        fontSize: "3rem",
                        fontWeight: "bolder",
                        bottom: "-0.15rem",
                        right: "-47%",
                        rotate: "180deg",
                        color: showCheckBox.Resistance ? "red" : "black",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "95%",
                          left: "40%",
                          height: "1rem",
                          borderLeft: showCheckBox.Resistance
                            ? "7px dashed red"
                            : "7px dashed black",
                        }}
                        className="left-border-connnector"
                      ></div>
                    </i>
                  </div>
                </div>

                <div className="solar-string-box-3">
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-1"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-2"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-3"
                  >
                    solar panel
                  </div>
                </div>
                <div className="solar-string-box-4">
                  <div
                    style={{
                      position: "absolute",
                      top: "-40%",
                      left: "45%",
                      height: "3rem",
                      borderLeft: showCheckBox.Resistance
                        ? "7px dashed red"
                        : "7px dashed black",
                    }}
                    className="left-border-connnector"
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        width: "3.6rem",
                        borderTop: showCheckBox.Resistance
                          ? "7px dashed red"
                          : "7px dashed black",
                      }}
                      className="top-border-connnector"
                    ></div>
                  </div>

                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-1"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-2"
                  ></div>
                  <div
                    style={{
                      border:
                        showCheckBox.Module ||
                        showCheckBox.Shadow ||
                        showCheckBox.Soiling
                          ? "5px solid red"
                          : "2px solid rgb(67, 176, 239)",
                    }}
                    className="solar-panel-3"
                  >
                    solar panel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LossflowTable
          setShowCheckBox={setShowCheckBox}
          showCheckBox={showCheckBox}
        />
      </Grid>
    </Grid>
  );
};

export default LossFlow;
