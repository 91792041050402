import React, { useEffect } from "react";
// import "../CountryHeader.css";
import "../Headers/CountryHeader.css";
import map from "../../fonts and images/map.png";

const ProjectDetailBody = (props) => {

    useEffect(() => {
    }, [])
    return (
        <>
        <div className="country-body">
            <img src={map} alt="Country-Image1" />
        </div>
      </>

    );
};

export default ProjectDetailBody;
