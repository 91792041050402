import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./CountryHeader2.css";
import { useLocation, useNavigate } from "react-router-dom";
import PageURL from "../../constants/PageURL";
import logo from "../../fonts and images/newLogo.PNG";
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import {
  inverterMappingArrayAction,
  scbSmbMappingArrayAction,
} from "../../actions/energyAction";
import FetchProjectDetailLoader from "../../components/Loaders/FetchProjectDetailLoader";

const ProjectDetailHeader2 = (props) => {
  const {
    selectedValue,
    setSelectedValue,
    scbsmbDetail,
    setscbsmbDetail,
    databaseStr,
    onclickHandler,
    checkedData,
  } = props;

  const {
    projectDetail,
    availableProject,
    loading: loading2,
    scbSmbMappingArray,
    scbSmbMappingLoader,
    inverterMappingArray,
    inverterMappingLoader,
  } = useSelector((state) => state.projectDetail);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const serachParams = new URLSearchParams(location.search);

  return (
    <>
      {!selectedValue?.projectName ? (
        <MenuItem value={""}>
          <em>Select a Project Name</em>{" "}
        </MenuItem>
      ) : loading2 ? (
        <MenuItem>
          <div
            style={{
              margin: "auto",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span>Fetching Project Detail</span>
            <FetchProjectDetailLoader />
          </div>
        </MenuItem>
      ) : (
        !projectDetail && (
          <MenuItem>
            <span>Project Not Found</span>
          </MenuItem>
        )
      )}

      {selectedValue.projectName && projectDetail && (
        <ListSubheader className="SubHeader">
          <span
            onClick={(event) => {
              onclickHandler("projectDetail");
              event.stopPropagation();
            }}
          >
            Overview Details
            <i
              className={`fa-solid fa-angle-down ${
                checkedData.projectDetail ? "active" : ""
              }`}
            ></i>
          </span>
        </ListSubheader>
      )}
      {checkedData.projectDetail &&
        [
          {
            url: PageURL.INDIA_PROJ_OVERVIEW,
            value: "Project OverView",
          },

          {
            url: PageURL.INDIA_GEN_SUMMARY,
            value: "Generation Summary",
          },
        ].map((e, index) => {
          return (
            <MenuItem
              key={index}
              value={e.value}
              onClick={() => {
                setSelectedValue({
                  ...selectedValue,
                  projectDetail: e.value,
                });
                navigate(`${e.url}?${serachParams.toString()}`);
              }}
            >
              {e.value}
            </MenuItem>
          );
        })}
      {selectedValue.projectName && projectDetail && (
        <ListSubheader className="SubHeader">
          <span
            onClick={(event) => {
              event.stopPropagation();
              onclickHandler("Diagonistic");
            }}
          >
            Diagonistic Model
            <i
              className={`fa-solid fa-angle-down ${
                checkedData.Diagonistic ? "active" : ""
              }`}
            ></i>
          </span>
        </ListSubheader>
      )}
      {checkedData.Diagonistic &&
        [
          {
            url: PageURL.INDIA_DIAGONISTIC_DETAILED,
            value: "Detailed Summary",
            show: true,
          },
          {
            url: PageURL.INDIA_LOSS_FLOW,
            value: "Loss Flow Diagram",
            show: projectDetail?.plantDataAvailability?.showLossFlowDiagram
              ? true
              : false,
          },
        ].map((e, index) => {
          if (!e.show) return null;
          return (
            <MenuItem
              key={index}
              value={e.value}
              onClick={() => {
                setSelectedValue({
                  ...selectedValue,
                  projectDetail: e.value,
                });
                navigate(`${e.url}?${serachParams.toString()}`);
              }}
            >
              {e.value}
            </MenuItem>
          );
        })}
      {selectedValue.projectName &&
        projectDetail &&
        projectDetail?.plantDataAvailability?.inverterEfficiency && (
          <ListSubheader className="SubHeader">
            <span
              onClick={(event) => {
                onclickHandler("inverterEfficiency");
                if (!inverterMappingArray) {
                  dispatch(inverterMappingArrayAction(databaseStr));
                }
                event.stopPropagation();
              }}
            >
              Inverter Efficiency
              <i
                className={`fa-solid fa-angle-down ${
                  checkedData.inverterEfficiency ? "active" : ""
                }`}
              ></i>
            </span>
          </ListSubheader>
        )}
      {checkedData.inverterEfficiency &&
        (inverterMappingLoader ? (
          <MenuItem
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FetchProjectDetailLoader width={"25px"} />
          </MenuItem>
        ) : !inverterMappingArray ? (
          <MenuItem>Inverter Data Not Found</MenuItem>
        ) : (
          inverterMappingArray?.length == 0 && (
            <MenuItem>Inverter Data Not Available</MenuItem>
          )
        ))}
      {projectDetail &&
        checkedData.inverterEfficiency &&
        inverterMappingArray?.length > 0 && (
          <MenuItem
            value={"All Inverters"}
            onClick={() => {
              // if (selectedValue.projectDetail === "All Inverters")
              //   return;

              setscbsmbDetail({
                block: null,
                sub_block: null,
                inverter: null,
                inverterType: null,
                stack: null,
                scbsmb: null,
                scbsmbType: null,
              });
              setSelectedValue({
                ...selectedValue,
                projectDetail: "All Inverters",
              });

              if (location.pathname !== PageURL.INDIA_INVERTER_EFFICIENCY) {
                navigate(
                  `${
                    PageURL.INDIA_INVERTER_EFFICIENCY
                  }?${serachParams.toString()}`
                );
              }
            }}
          >
            All Inverters
          </MenuItem>
        )}
      {checkedData.inverterEfficiency &&
        inverterMappingArray?.map((e, index) => {
          return (
            <Box key={index}>
              <ListSubheader className="SubHeader">
                <span
                  onClick={(event) => {
                    onclickHandler(
                      e?.name + "Efficiency",
                      "inverterEfficiency"
                    );
                    event.stopPropagation();
                  }}
                >
                  {e?.name}
                  <i
                    className={`fa-solid fa-angle-down ${
                      checkedData[e?.name + "Efficiency"] ? "active" : ""
                    }`}
                  ></i>
                </span>
              </ListSubheader>

              {/* Check if subBlocks exist */}
              {e?.subBlocks && checkedData[e?.name + "Efficiency"] ? (
                e?.subBlocks?.map((subBlock, subIndex) => (
                  <Box key={subIndex}>
                    <ListSubheader className="SubHeader">
                      <span
                        onClick={(event) => {
                          onclickHandler(
                            e?.name + subBlock?.name + "Efficiency",
                            "inverterEfficiency",
                            e?.name + "Efficiency"
                          );
                          event.stopPropagation();
                        }}
                      >
                        {subBlock?.name}
                        <i
                          className={`fa-solid fa-angle-down ${
                            checkedData[e?.name + subBlock?.name + "Efficiency"]
                              ? "active"
                              : ""
                          }`}
                        ></i>
                      </span>
                    </ListSubheader>

                    {/* If subBlock contains stacks, show dropdowns */}
                    {!subBlock?.stacks &&
                    checkedData[e?.name + subBlock?.name + "Efficiency"] ? (
                      <>
                        {subBlock?.inverters?.length > 1 && (
                          <MenuItem
                            key={subBlock?.name + "All Inverter"}
                            value={"All Inverter"}
                            onClick={() => {
                              setSelectedValue({
                                ...selectedValue,
                                projectDetail: "All Inverter",
                              });
                              setscbsmbDetail({
                                block: e?.name,
                                sub_block: subBlock?.name,
                                inverter: null,
                                inverterType: subBlock?.type,
                                stack: null,
                                scbsmb: null,
                                scbsmbType: null,
                              });

                              if (
                                location.pathname !==
                                PageURL.INDIA_INVERTER_EFFICIENCY
                              ) {
                                navigate(
                                  `${
                                    PageURL.INDIA_INVERTER_EFFICIENCY
                                  }?${serachParams.toString()}`
                                );
                              }
                            }}
                          >
                            All Inverter
                          </MenuItem>
                        )}

                        {subBlock?.inverters?.map((inverter, inverterIndex) => (
                          <MenuItem
                            key={inverterIndex}
                            value={inverter}
                            onClick={() => {
                              setSelectedValue({
                                ...selectedValue,
                                projectDetail: inverter,
                              });
                              setscbsmbDetail({
                                block: e?.name,
                                sub_block: subBlock?.name,
                                inverter: inverter,
                                inverterType: subBlock?.type,
                                stack: null,
                                scbsmb: null,
                                scbsmbType: null,
                              });

                              if (
                                location.pathname !==
                                PageURL.INDIA_INVERTER_EFFICIENCY
                              ) {
                                navigate(
                                  `${
                                    PageURL.INDIA_INVERTER_EFFICIENCY
                                  }?${serachParams.toString()}`
                                );
                              }
                            }}
                          >
                            {inverter}
                          </MenuItem>
                        ))}
                      </>
                    ) : (
                      <>
                        {checkedData[e?.name + subBlock?.name + "Efficiency"] &&
                          subBlock?.inverters?.map((inverter, invIndex) => (
                            <Box key={invIndex}>
                              <ListSubheader className="SubHeader">
                                <span
                                  onClick={(event) => {
                                    onclickHandler(
                                      e?.name +
                                        subBlock?.name +
                                        inverter?.name +
                                        "Efficiency",
                                      "inverterEfficiency",
                                      e?.name + subBlock?.name + "Efficiency"
                                    );
                                    event.stopPropagation();
                                  }}
                                >
                                  {inverter?.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[
                                        e?.name +
                                          subBlock?.name +
                                          inverter?.name +
                                          "Efficiency"
                                      ]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Show SCBs if checked */}
                              {checkedData[
                                e?.name +
                                  subBlock?.name +
                                  inverter?.name +
                                  "Efficiency"
                              ] && (
                                <>
                                  {inverter?.stacks?.length > 1 && (
                                    <MenuItem
                                      value={"All Inverter"}
                                      onClick={() => {
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: "All Inverter",
                                        });
                                        setscbsmbDetail({
                                          block: e?.name,
                                          sub_block: subBlock?.name,
                                          inverter: inverter?.name,
                                          inverterType: inverter?.type,
                                          stack: null,
                                          scbsmb: null,
                                          scbsmbType: null,
                                        });

                                        if (
                                          location.pathname !==
                                          PageURL.INDIA_INVERTER_EFFICIENCY
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.INDIA_INVERTER_EFFICIENCY
                                            }?${serachParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      All Inverter
                                    </MenuItem>
                                  )}

                                  {inverter?.stacks?.map(
                                    (stack, stackIndex) => (
                                      <MenuItem
                                        key={stackIndex}
                                        value={stack}
                                        onClick={() => {
                                          setSelectedValue({
                                            ...selectedValue,
                                            projectDetail: stack,
                                          });

                                          setscbsmbDetail({
                                            block: e?.name,
                                            sub_block: subBlock?.name,
                                            inverter: inverter?.name,
                                            stack: stack,
                                            inverterType: inverter?.type,
                                          });

                                          if (
                                            location.pathname !==
                                            PageURL.INDIA_INVERTER_EFFICIENCY
                                          ) {
                                            navigate(
                                              `${
                                                PageURL.INDIA_INVERTER_EFFICIENCY
                                              }?${serachParams.toString()}`
                                            );
                                          }
                                        }}
                                      >
                                        {stack}
                                      </MenuItem>
                                    )
                                  )}
                                </>
                              )}
                            </Box>
                          ))}
                      </>
                    )}
                  </Box>
                ))
              ) : (
                // If no subBlocks, render existing inverter logic
                <>
                  {!e?.inverters?.["0"]?.stacks &&
                  checkedData[e?.name + "Efficiency"] ? (
                    <>
                      {e?.inverters?.length > 1 && (
                        <MenuItem
                          key={e?.name + "All Inverter"}
                          value={"All Inverter"}
                          onClick={() => {
                            // if (
                            //   selectedValue.projectDetail ===
                            //     "All Inverter" &&
                            //   scbsmbDetail.block === e?.name
                            // )
                            //   return;
                            setSelectedValue({
                              ...selectedValue,
                              projectDetail: "All Inverter",
                            });
                            setscbsmbDetail({
                              block: e?.name,
                              inverter: null,
                              inverterType: e?.type,
                              stack: null,
                              scbsmb: null,
                              scbsmbType: null,
                            });

                            if (
                              location.pathname !==
                              PageURL.INDIA_INVERTER_EFFICIENCY
                            ) {
                              navigate(
                                `${
                                  PageURL.INDIA_INVERTER_EFFICIENCY
                                }?${serachParams.toString()}`
                              );
                            }
                          }}
                        >
                          All Inverter
                        </MenuItem>
                      )}

                      {e?.inverters?.map((ee, index3) => {
                        return (
                          <MenuItem
                            key={index3}
                            value={ee}
                            onClick={() => {
                              // if (
                              //   selectedValue.projectDetail === ee &&
                              //   scbsmbDetail.block === e?.name
                              // )
                              //   return;
                              setSelectedValue({
                                ...selectedValue,
                                projectDetail: ee,
                              });
                              setscbsmbDetail({
                                block: e?.name,
                                inverter: ee,
                                inverterType: e?.type,
                                stack: null,
                                scbsmb: null,
                                scbsmbType: null,
                              });

                              if (
                                location.pathname !==
                                PageURL.INDIA_INVERTER_EFFICIENCY
                              ) {
                                navigate(
                                  `${
                                    PageURL.INDIA_INVERTER_EFFICIENCY
                                  }?${serachParams.toString()}`
                                );
                              }
                            }}
                          >
                            {ee}
                          </MenuItem>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {checkedData[e?.name + "Efficiency"] &&
                        e?.inverters?.map((ee, index2) => {
                          return (
                            <Box key={index2}>
                              <ListSubheader className="SubHeader">
                                <span
                                  onClick={(event) => {
                                    onclickHandler(
                                      e?.name + ee?.name + "Efficiency",
                                      "inverterEfficiency",
                                      e?.name + "Efficiency"
                                    );
                                    event.stopPropagation();
                                  }}
                                >
                                  {ee?.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[
                                        e?.name + ee?.name + "Efficiency"
                                      ]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {checkedData[
                                e?.name + ee?.name + "Efficiency"
                              ] && (
                                <>
                                  {ee?.stacks?.length > 1 && (
                                    <MenuItem
                                      value={"All Inverter"}
                                      onClick={() => {
                                        // if (
                                        //   selectedValue.projectDetail ===
                                        //     "All Inverter" &&
                                        //   scbsmbDetail.block === e?.name
                                        // )
                                        //   return;
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: "All Inverter",
                                        });
                                        setscbsmbDetail({
                                          block: e?.name,
                                          inverter: ee?.name,
                                          stack: null,
                                          inverterType: ee?.type,
                                          scbsmb: null,
                                          scbsmbType: null,
                                        });

                                        if (
                                          location.pathname !==
                                          PageURL.INDIA_INVERTER_EFFICIENCY
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.INDIA_INVERTER_EFFICIENCY
                                            }?${serachParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      All Inverter
                                    </MenuItem>
                                  )}

                                  {ee?.stacks?.map((eee, index3) => (
                                    <MenuItem
                                      key={index3}
                                      value={eee}
                                      onClick={() => {
                                        if (
                                          selectedValue.projectDetail === eee &&
                                          scbsmbDetail.block === e?.name &&
                                          scbsmbDetail.inverter === ee?.name
                                        )
                                          return;
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: eee,
                                        });

                                        setscbsmbDetail({
                                          block: e?.name,
                                          stack: eee,
                                          inverterType: ee?.type,
                                          inverter: ee?.name,
                                        });
                                        if (
                                          location.pathname !==
                                          PageURL.INDIA_INVERTER_EFFICIENCY
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.INDIA_INVERTER_EFFICIENCY
                                            }?${serachParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      {eee}
                                    </MenuItem>
                                  ))}
                                </>
                              )}
                            </Box>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </Box>
          );
        })}
      {selectedValue.projectName &&
        projectDetail &&
        projectDetail?.plantDataAvailability?.inverterDeviation && (
          <ListSubheader className="SubHeader">
            <span
              onClick={(event) => {
                onclickHandler("inverterDeviation");
                if (!inverterMappingArray) {
                  dispatch(inverterMappingArrayAction(databaseStr));
                }
                event.stopPropagation();
              }}
            >
              Inverter Deviation
              <i
                className={`fa-solid fa-angle-down ${
                  checkedData.inverterDeviation ? "active" : ""
                }`}
              ></i>
            </span>
          </ListSubheader>
        )}
      {checkedData.inverterDeviation &&
        (inverterMappingLoader ? (
          <MenuItem
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FetchProjectDetailLoader width={"25px"} />
          </MenuItem>
        ) : !inverterMappingArray ? (
          <MenuItem>Inverter Data Not Found</MenuItem>
        ) : (
          inverterMappingArray?.length == 0 && (
            <MenuItem>Inverter Data Not Available</MenuItem>
          )
        ))}
      {projectDetail &&
        checkedData.inverterDeviation &&
        inverterMappingArray?.length > 0 && (
          <MenuItem
            value={"All Inverters"}
            onClick={() => {
              // if (selectedValue.projectDetail === "All Inverters")
              //   return;

              setscbsmbDetail({
                block: null,
                inverter: null,
                inverterType: null,
                stack: null,
                scbsmb: null,
                scbsmbType: null,
              });
              setSelectedValue({
                ...selectedValue,
                projectDetail: "All Inverters",
              });

              if (location.pathname !== PageURL.INDIA_INVERTER_DEVIATION) {
                navigate(
                  `${
                    PageURL.INDIA_INVERTER_DEVIATION
                  }?${serachParams.toString()}`
                );
              }
            }}
          >
            All Inverters
          </MenuItem>
        )}

      {checkedData.inverterDeviation &&
        inverterMappingArray?.map((e, index) => {
          return (
            <Box key={index}>
              <ListSubheader className="SubHeader">
                <span
                  onClick={(event) => {
                    onclickHandler(e?.name + "Deviation", "inverterDeviation");
                    event.stopPropagation();
                  }}
                >
                  {e?.name}
                  <i
                    className={`fa-solid fa-angle-down ${
                      checkedData[e?.name + "Deviation"] ? "active" : ""
                    }`}
                  ></i>
                </span>
              </ListSubheader>

              {/* Check if subBlocks exist */}
              {e?.subBlocks && checkedData[e?.name + "Deviation"] ? (
                e?.subBlocks?.map((subBlock, subIndex) => (
                  <Box key={subIndex}>
                    <ListSubheader className="SubHeader">
                      <span
                        onClick={(event) => {
                          onclickHandler(
                            e?.name + subBlock?.name + "Deviation",
                            "inverterDeviation",
                            e?.name + "Deviation"
                          );
                          event.stopPropagation();
                        }}
                      >
                        {subBlock?.name}
                        <i
                          className={`fa-solid fa-angle-down ${
                            checkedData[e?.name + subBlock?.name + "Deviation"]
                              ? "active"
                              : ""
                          }`}
                        ></i>
                      </span>
                    </ListSubheader>

                    {/* If subBlock contains stacks, show dropdowns */}
                    {!subBlock?.stacks &&
                    checkedData[e?.name + subBlock?.name + "Deviation"] ? (
                      <>
                        {subBlock?.inverters?.length > 1 && (
                          <MenuItem
                            key={subBlock?.name + "All Inverter"}
                            value={"All Inverter"}
                            onClick={() => {
                              setSelectedValue({
                                ...selectedValue,
                                projectDetail: "All Inverter",
                              });
                              setscbsmbDetail({
                                block: e?.name,
                                sub_block: subBlock?.name,
                                inverter: null,
                                inverterType: subBlock?.type,
                                stack: null,
                                scbsmb: null,
                                scbsmbType: null,
                              });

                              if (
                                location.pathname !==
                                PageURL.INDIA_INVERTER_DEVIATION
                              ) {
                                navigate(
                                  `${
                                    PageURL.INDIA_INVERTER_DEVIATION
                                  }?${serachParams.toString()}`
                                );
                              }
                            }}
                          >
                            All Inverter
                          </MenuItem>
                        )}

                        {subBlock?.inverters?.map((inverter, inverterIndex) => (
                          <MenuItem
                            key={inverterIndex}
                            value={inverter}
                            onClick={() => {
                              setSelectedValue({
                                ...selectedValue,
                                projectDetail: inverter,
                              });
                              setscbsmbDetail({
                                block: e?.name,
                                sub_block: subBlock?.name,
                                inverter: inverter,
                                inverterType: subBlock?.type,
                                stack: null,
                                scbsmb: null,
                                scbsmbType: null,
                              });

                              if (
                                location.pathname !==
                                PageURL.INDIA_INVERTER_DEVIATION
                              ) {
                                navigate(
                                  `${
                                    PageURL.INDIA_INVERTER_DEVIATION
                                  }?${serachParams.toString()}`
                                );
                              }
                            }}
                          >
                            {inverter}
                          </MenuItem>
                        ))}
                      </>
                    ) : (
                      <>
                        {checkedData[e?.name + subBlock?.name + "Deviation"] &&
                          subBlock?.inverters?.map((inverter, invIndex) => (
                            <Box key={invIndex}>
                              <ListSubheader className="SubHeader">
                                <span
                                  onClick={(event) => {
                                    onclickHandler(
                                      e?.name +
                                        subBlock?.name +
                                        inverter?.name +
                                        "Deviation",
                                      "inverterDeviation",
                                      e?.name + subBlock?.name + "Deviation"
                                    );
                                    event.stopPropagation();
                                  }}
                                >
                                  {inverter?.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[
                                        e?.name +
                                          subBlock?.name +
                                          inverter?.name +
                                          "Deviation"
                                      ]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Show SCBs if checked */}
                              {checkedData[
                                e?.name +
                                  subBlock?.name +
                                  inverter?.name +
                                  "Deviation"
                              ] && (
                                <>
                                  {inverter?.stacks?.length > 1 && (
                                    <MenuItem
                                      value={"All Inverter"}
                                      onClick={() => {
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: "All Inverter",
                                        });
                                        setscbsmbDetail({
                                          block: e?.name,
                                          sub_block: subBlock?.name,
                                          inverter: inverter?.name,
                                          inverterType: inverter?.type,
                                          stack: null,
                                          scbsmb: null,
                                          scbsmbType: null,
                                        });

                                        if (
                                          location.pathname !==
                                          PageURL.INDIA_INVERTER_DEVIATION
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.INDIA_INVERTER_DEVIATION
                                            }?${serachParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      All Inverter
                                    </MenuItem>
                                  )}

                                  {inverter?.stacks?.map(
                                    (stack, stackIndex) => (
                                      <MenuItem
                                        key={stackIndex}
                                        value={stack}
                                        onClick={() => {
                                          setSelectedValue({
                                            ...selectedValue,
                                            projectDetail: stack,
                                          });

                                          setscbsmbDetail({
                                            block: e?.name,
                                            sub_block: subBlock?.name,
                                            inverter: inverter?.name,
                                            stack: stack,
                                            inverterType: inverter?.type,
                                          });

                                          if (
                                            location.pathname !==
                                            PageURL.INDIA_INVERTER_DEVIATION
                                          ) {
                                            navigate(
                                              `${
                                                PageURL.INDIA_INVERTER_DEVIATION
                                              }?${serachParams.toString()}`
                                            );
                                          }
                                        }}
                                      >
                                        {stack}
                                      </MenuItem>
                                    )
                                  )}
                                </>
                              )}
                            </Box>
                          ))}
                      </>
                    )}
                  </Box>
                ))
              ) : (
                // If no subBlocks, render existing inverter logic
                <>
                  {!e?.inverters?.["0"]?.stacks &&
                  checkedData[e?.name + "Deviation"] ? (
                    <>
                      {e?.inverters?.length > 1 && (
                        <MenuItem
                          key={e?.name + "All Inverter"}
                          value={"All Inverter"}
                          onClick={() => {
                            setSelectedValue({
                              ...selectedValue,
                              projectDetail: "All Inverter",
                            });
                            setscbsmbDetail({
                              block: e?.name,
                              inverter: null,
                              inverterType: e?.type,
                              stack: null,
                              scbsmb: null,
                              scbsmbType: null,
                            });

                            if (
                              location.pathname !==
                              PageURL.INDIA_INVERTER_DEVIATION
                            ) {
                              navigate(
                                `${
                                  PageURL.INDIA_INVERTER_DEVIATION
                                }?${serachParams.toString()}`
                              );
                            }
                          }}
                        >
                          All Inverter
                        </MenuItem>
                      )}

                      {e?.inverters?.map((ee, index3) => {
                        return (
                          <MenuItem
                            key={index3}
                            value={ee}
                            onClick={() => {
                              // if (
                              //   selectedValue.projectDetail === ee &&
                              //   scbsmbDetail.block === e?.name
                              // )
                              //   return;
                              setSelectedValue({
                                ...selectedValue,
                                projectDetail: ee,
                              });
                              setscbsmbDetail({
                                block: e?.name,
                                inverter: ee,
                                inverterType: e?.type,
                                stack: null,
                                scbsmb: null,
                                scbsmbType: null,
                              });

                              if (
                                location.pathname !==
                                PageURL.INDIA_INVERTER_DEVIATION
                              ) {
                                navigate(
                                  `${
                                    PageURL.INDIA_INVERTER_DEVIATION
                                  }?${serachParams.toString()}`
                                );
                              }
                            }}
                          >
                            {ee}
                          </MenuItem>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {checkedData[e?.name + "Deviation"] &&
                        e?.inverters?.map((ee, index2) => {
                          return (
                            <Box key={index2}>
                              <ListSubheader className="SubHeader">
                                <span
                                  onClick={(event) => {
                                    onclickHandler(
                                      e?.name + ee?.name + "Deviation",
                                      "inverterDeviation",
                                      e?.name + "Deviation"
                                    );
                                    event.stopPropagation();
                                  }}
                                >
                                  {ee?.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[
                                        e?.name + ee?.name + "Deviation"
                                      ]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {checkedData[
                                e?.name + ee?.name + "Deviation"
                              ] && (
                                <>
                                  {ee?.stacks?.length > 1 && (
                                    <MenuItem
                                      value={"All Inverter"}
                                      onClick={() => {
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: "All Inverter",
                                        });
                                        setscbsmbDetail({
                                          block: e?.name,
                                          inverter: ee?.name,
                                          stack: null,
                                          inverterType: ee?.type,
                                          scbsmb: null,
                                          scbsmbType: null,
                                        });

                                        if (
                                          location.pathname !==
                                          PageURL.INDIA_INVERTER_DEVIATION
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.INDIA_INVERTER_DEVIATION
                                            }?${serachParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      All Inverter
                                    </MenuItem>
                                  )}

                                  {ee?.stacks?.map((eee, index3) => (
                                    <MenuItem
                                      key={index3}
                                      value={eee}
                                      onClick={() => {
                                        if (
                                          selectedValue.projectDetail === eee &&
                                          scbsmbDetail.block === e?.name &&
                                          scbsmbDetail.inverter === ee?.name
                                        )
                                          return;
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: eee,
                                        });

                                        setscbsmbDetail({
                                          block: e?.name,
                                          stack: eee,
                                          inverterType: ee?.type,
                                          inverter: ee?.name,
                                        });
                                        if (
                                          location.pathname !==
                                          PageURL.INDIA_INVERTER_DEVIATION
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.INDIA_INVERTER_DEVIATION
                                            }?${serachParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      {eee}
                                    </MenuItem>
                                  ))}
                                </>
                              )}
                            </Box>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </Box>
          );
        })}

      {selectedValue.projectName &&
        projectDetail &&
        projectDetail?.plantDataAvailability?.scbSmbDeviation && (
          <ListSubheader className="SubHeader">
            <span
              onClick={(e) => {
                onclickHandler("string_smb");
                if (!scbSmbMappingArray) {
                  dispatch(scbSmbMappingArrayAction(databaseStr));
                }
                e.stopPropagation();
              }}
            >
              SCB/SMB/STRING
              <i
                className={`fa-solid fa-angle-down ${
                  checkedData.string_smb ? "active" : ""
                }`}
              ></i>
            </span>
          </ListSubheader>
        )}

      {checkedData.string_smb &&
        (scbSmbMappingLoader ? (
          <MenuItem
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <FetchProjectDetailLoader width={"25px"} />
          </MenuItem>
        ) : !scbSmbMappingArray ? (
          <MenuItem>
            {projectDetail?.plantDetail?.string || ""} Data Not Found
          </MenuItem>
        ) : (
          scbSmbMappingArray?.length == 0 && (
            <MenuItem>
              {projectDetail?.plantDetail?.string || ""} Data Not Available
            </MenuItem>
          )
        ))}
      {checkedData.string_smb &&
        scbSmbMappingArray?.map((e, index1) => {
          return (
            <Box key={"SubHeader" + e?.name + index1}>
              {/* First Subheader for Blocks */}
              <ListSubheader className="SubHeader">
                <span
                  onClick={(ee) => {
                    onclickHandler(e?.name, "string_smb");
                    ee.stopPropagation();
                  }}
                >
                  {e?.name}
                  <i
                    className={`fa-solid fa-angle-down ${
                      checkedData[e?.name] ? "active" : ""
                    }`}
                  ></i>
                </span>
              </ListSubheader>

              {/* Check if subBlocks exist */}
              {e?.subBlocks && checkedData[e?.name]
                ? e?.subBlocks?.map((subBlock, subIndex) => (
                    <Box key={subIndex}>
                      <ListSubheader className="SubHeader">
                        <span
                          onClick={(event) => {
                            onclickHandler(
                              e?.name + subBlock?.name,
                              "string_smb",
                              e?.name
                            );
                            event.stopPropagation();
                          }}
                        >
                          {subBlock?.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e?.name + subBlock?.name]
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {checkedData[e?.name + subBlock?.name] && (
                        <>
                          <MenuItem
                            onClick={() => {
                              setSelectedValue({
                                ...selectedValue,
                                projectDetail: "All STRING Deviation",
                              });

                              setscbsmbDetail({
                                block: e?.name,
                                sub_block: subBlock?.name,
                                inverter: null,
                                inverterType: null,
                                stack: null,
                                scbsmb: null,
                                scbsmbType: `${
                                  projectDetail?.plantDetail?.string || ""
                                }`,
                              });

                              if (location.pathname !== PageURL.SCBSMB_LOSS) {
                                navigate(
                                  `${
                                    PageURL.SCBSMB_LOSS
                                  }?${serachParams.toString()}`
                                );
                              }
                            }}
                          >
                            All STRING Deviation
                          </MenuItem>
                          {subBlock?.inverters?.map((ee, index2) => (
                            <Box key={index2}>
                              <ListSubheader
                                key={ee?.name}
                                className="SubHeader"
                              >
                                <span
                                  onClick={(event) => {
                                    onclickHandler(
                                      e?.name + subBlock?.name + ee?.name,
                                      "string_smb",
                                      e?.name,
                                      e?.name + subBlock?.name
                                    );
                                    event.stopPropagation();
                                  }}
                                >
                                  {ee?.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[
                                        e?.name + subBlock?.name + ee?.name
                                      ]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {ee?.listScbsmb &&
                              checkedData[
                                e?.name + subBlock?.name + ee?.name
                              ] ? (
                                <>
                                  {projectDetail?.plantDetail?.string !==
                                    "SMB" &&
                                    ee?.listScbsmb?.length > 1 && (
                                      <MenuItem
                                        key={`${e?.name}${subBlock?.name}${ee?.name}All`}
                                        value={`All ${
                                          projectDetail?.plantDetail?.string ||
                                          ""
                                        }`}
                                        onClick={() => {
                                          setSelectedValue({
                                            ...selectedValue,
                                            projectDetail: `All ${
                                              projectDetail?.plantDetail
                                                ?.string || ""
                                            }`,
                                          });

                                          setscbsmbDetail({
                                            block: e?.name,
                                            sub_block: subBlock?.name,
                                            inverter: ee?.name,
                                            inverterType: ee?.inverterType,
                                            stack: null,
                                            scbsmb: null,
                                            scbsmbType: `${
                                              projectDetail?.plantDetail
                                                ?.string || ""
                                            }`,
                                          });

                                          if (
                                            location.pathname !==
                                            PageURL.SCBSMB_LOSS
                                          ) {
                                            navigate(
                                              `${
                                                PageURL.SCBSMB_LOSS
                                              }?${serachParams.toString()}`
                                            );
                                          }
                                        }}
                                      >
                                        {`All ${
                                          projectDetail?.plantDetail?.string ||
                                          ""
                                        }`}
                                      </MenuItem>
                                    )}

                                  <MenuItem
                                    key={`${e?.name}${subBlock?.name}${ee?.name}HeatMap`}
                                    value={"HeatMap"}
                                    onClick={() => {
                                      setSelectedValue({
                                        ...selectedValue,
                                        projectDetail: "HeatMap",
                                      });

                                      setscbsmbDetail({
                                        block: e?.name,
                                        sub_block: subBlock?.name,
                                        inverter: ee?.name,
                                        inverterType: ee?.inverterType,
                                        stack: null,
                                        scbsmb: null,
                                        heatmap: true,
                                        scbsmbType: `${
                                          projectDetail?.plantDetail?.string ||
                                          ""
                                        }`,
                                      });

                                      if (
                                        location.pathname !==
                                        PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                      ) {
                                        navigate(
                                          `${
                                            PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                          }?${serachParams.toString()}`
                                        );
                                      }
                                    }}
                                  >
                                    HeatMap
                                  </MenuItem>

                                  {ee?.listScbsmb?.map((eee, index3) => (
                                    <MenuItem
                                      key={`${e?.name}${subBlock?.name}${ee?.name}${eee}`}
                                      value={eee}
                                      onClick={() => {
                                        if (
                                          selectedValue.projectDetail === eee &&
                                          scbsmbDetail.block === e?.name &&
                                          scbsmbDetail.subBlock ===
                                            subBlock?.name &&
                                          scbsmbDetail.inverter === ee?.name
                                        )
                                          return;
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: eee,
                                        });

                                        setscbsmbDetail({
                                          block: e?.name,
                                          sub_block: subBlock?.name,
                                          inverter: ee?.name,
                                          inverterType: ee?.inverterType,
                                          stack: null,
                                          scbsmb: eee,
                                          scbsmbType: `${
                                            projectDetail?.plantDetail
                                              ?.string || ""
                                          }`,
                                        });

                                        if (
                                          location.pathname !==
                                          PageURL.SCBSMB_LOSS
                                        ) {
                                          navigate(
                                            `${
                                              PageURL.SCBSMB_LOSS
                                            }?${serachParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      {eee}
                                    </MenuItem>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {checkedData[
                                    e?.name + subBlock?.name + ee?.name
                                  ] &&
                                    ee?.stacks?.map((eee, index3) => (
                                      <Box key={index3}>
                                        <ListSubheader className="SubHeader">
                                          <span
                                            onClick={(event) => {
                                              onclickHandler(
                                                e?.name +
                                                  subBlock?.name +
                                                  ee?.name +
                                                  eee?.name,
                                                "string_smb",
                                                e?.name + subBlock?.name,
                                                e?.name +
                                                  subBlock?.name +
                                                  ee?.name
                                              );
                                              event.stopPropagation();
                                            }}
                                          >
                                            {eee?.name}
                                            <i
                                              className={`fa-solid fa-angle-down ${
                                                checkedData[
                                                  e?.name +
                                                    subBlock?.name +
                                                    ee?.name +
                                                    eee?.name
                                                ]
                                                  ? "active"
                                                  : ""
                                              }`}
                                            ></i>
                                          </span>
                                        </ListSubheader>

                                        {eee?.listScbsmb &&
                                          checkedData[
                                            e?.name +
                                              subBlock?.name +
                                              ee?.name +
                                              eee?.name
                                          ] &&
                                          eee?.listScbsmb?.map(
                                            (eeee, index4) => (
                                              <MenuItem
                                                key={index4}
                                                value={eeee}
                                                onClick={() => {
                                                  setSelectedValue({
                                                    ...selectedValue,
                                                    projectDetail: eeee,
                                                  });

                                                  setscbsmbDetail({
                                                    block: e?.name,
                                                    sub_block: subBlock?.name,
                                                    inverter: ee?.name,
                                                    inverterType:
                                                      ee?.inverterType,
                                                    stack: eee?.name,
                                                    scbsmb: eeee,
                                                    scbsmbType: `${
                                                      projectDetail?.plantDetail
                                                        ?.string || ""
                                                    }`,
                                                  });

                                                  if (
                                                    location.pathname !==
                                                    PageURL.SCBSMB_LOSS
                                                  ) {
                                                    navigate(
                                                      `${
                                                        PageURL.SCBSMB_LOSS
                                                      }?${serachParams.toString()}`
                                                    );
                                                  }
                                                }}
                                              >
                                                {eeee}
                                              </MenuItem>
                                            )
                                          )}
                                      </Box>
                                    ))}
                                </>
                              )}
                            </Box>
                          ))}
                        </>
                      )}
                    </Box>
                  ))
                : checkedData[e?.name] &&
                  e?.inverters?.map((ee, index2) => {
                    return (
                      <Box key={index2}>
                        <ListSubheader key={ee?.name} className="SubHeader">
                          <span
                            onClick={(event) => {
                              onclickHandler(
                                e?.name + ee?.name,
                                "string_smb",
                                e?.name
                              );
                              event.stopPropagation();
                            }}
                          >
                            {ee?.name}
                            <i
                              className={`fa-solid fa-angle-down ${
                                checkedData[e?.name + ee?.name] ? "active" : ""
                              }`}
                            ></i>
                          </span>
                        </ListSubheader>

                        {/* Conditionally show SCBs if checked */}
                        {ee?.listScbsmb && checkedData[e?.name + ee?.name] ? (
                          <>
                            {projectDetail?.plantDetail?.string != "SMB" &&
                              ee?.listScbsmb?.length > 1 && (
                                <MenuItem
                                  key={
                                    e?.name +
                                    ee?.name +
                                    index1 +
                                    index2 +
                                    `All ${
                                      projectDetail?.plantDetail?.string || ""
                                    }`
                                  }
                                  value={`All ${
                                    projectDetail?.plantDetail?.string || ""
                                  }`}
                                  onClick={() => {
                                    setSelectedValue({
                                      ...selectedValue,
                                      projectDetail: `All ${
                                        projectDetail?.plantDetail?.string || ""
                                      }`,
                                    });

                                    setscbsmbDetail({
                                      block: e?.name,
                                      inverter: ee?.name,
                                      inverterType: ee?.inverterType,
                                      stack: null,
                                      scbsmb: null,
                                      scbsmbType: `${
                                        projectDetail?.plantDetail?.string || ""
                                      }`,
                                    });
                                    if (
                                      location.pathname !== PageURL.SCBSMB_LOSS
                                    ) {
                                      navigate(
                                        `${
                                          PageURL.SCBSMB_LOSS
                                        }?${serachParams.toString()}`
                                      );
                                    }
                                  }}
                                >
                                  {`All ${
                                    projectDetail?.plantDetail?.string || ""
                                  }`}
                                </MenuItem>
                              )}
                            <MenuItem
                              key={
                                e?.name + ee?.name + index1 + index2 + "HeatMap"
                              }
                              value={"HeatMap"}
                              onClick={() => {
                                setSelectedValue({
                                  ...selectedValue,
                                  projectDetail: "HeatMap",
                                });

                                setscbsmbDetail({
                                  block: e?.name,
                                  inverter: ee?.name,
                                  inverterType: ee?.inverterType,
                                  stack: null,
                                  scbsmb: null,
                                  heatmap: true,
                                  scbsmbType: `${
                                    projectDetail?.plantDetail?.string || ""
                                  }`,
                                });
                                if (
                                  location.pathname !==
                                  PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                ) {
                                  navigate(
                                    `${
                                      PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                    }?${serachParams.toString()}`
                                  );
                                }
                              }}
                            >
                              HeatMap
                            </MenuItem>
                            {ee?.listScbsmb?.map((eee, index3) => (
                              <MenuItem
                                key={
                                  e?.name + ee?.name + index1 + index2 + index3
                                }
                                value={eee}
                                onClick={() => {
                                  if (
                                    selectedValue.projectDetail === eee &&
                                    scbsmbDetail.block === e?.name &&
                                    scbsmbDetail.inverter === ee?.name
                                  )
                                    return;
                                  setSelectedValue({
                                    ...selectedValue,
                                    projectDetail: eee,
                                  });

                                  setscbsmbDetail({
                                    block: e?.name,
                                    inverter: ee?.name,
                                    inverterType: ee?.inverterType,
                                    stack: null,
                                    scbsmb: eee,
                                    scbsmbType: `${
                                      projectDetail?.plantDetail?.string || ""
                                    }`,
                                  });
                                  if (
                                    location.pathname !== PageURL.SCBSMB_LOSS
                                  ) {
                                    navigate(
                                      `${
                                        PageURL.SCBSMB_LOSS
                                      }?${serachParams.toString()}`
                                    );
                                  }

                                  // You can navigate to e.url here
                                }}
                              >
                                {eee}
                              </MenuItem>
                            ))}
                          </>
                        ) : (
                          <>
                            {checkedData[e?.name + ee?.name] &&
                              ee?.stacks?.map((eee, index3) => {
                                return (
                                  <Box key={index3}>
                                    <ListSubheader className="SubHeader">
                                      <span
                                        onClick={(event) => {
                                          onclickHandler(
                                            e?.name + ee?.name + eee?.name,
                                            "string_smb",
                                            e?.name,
                                            e?.name + ee?.name
                                          );
                                          event.stopPropagation();
                                        }}
                                      >
                                        {eee?.name}
                                        <i
                                          className={`fa-solid fa-angle-down ${
                                            checkedData[
                                              e?.name + ee?.name + eee?.name
                                            ]
                                              ? "active"
                                              : ""
                                          }`}
                                        ></i>
                                      </span>
                                    </ListSubheader>

                                    {/* Conditionally show SCBs if checked */}
                                    {eee?.listScbsmb &&
                                      checkedData[
                                        e?.name + ee?.name + eee?.name
                                      ] && (
                                        <>
                                          {projectDetail?.plantDetail?.string !=
                                            "SMB" &&
                                            eee?.listScbsmb?.length > 1 && (
                                              <MenuItem
                                                value={`All ${
                                                  projectDetail?.plantDetail
                                                    ?.string || ""
                                                }`}
                                                onClick={() => {
                                                  setSelectedValue({
                                                    ...selectedValue,
                                                    projectDetail: `All ${
                                                      projectDetail?.plantDetail
                                                        ?.string || ""
                                                    }`,
                                                  });

                                                  setscbsmbDetail({
                                                    block: e?.name,
                                                    inverter: ee?.name,
                                                    stack: eee?.name,
                                                    inverterType:
                                                      ee?.inverterType,
                                                    scbsmb: null,
                                                    scbsmbType: `${
                                                      projectDetail?.plantDetail
                                                        ?.string || ""
                                                    }`,
                                                  });
                                                  if (
                                                    location.pathname !==
                                                    PageURL.SCBSMB_LOSS
                                                  ) {
                                                    navigate(
                                                      `${
                                                        PageURL.SCBSMB_LOSS
                                                      }?${serachParams.toString()}`
                                                    );
                                                  }
                                                }}
                                              >
                                                {`All ${
                                                  projectDetail?.plantDetail
                                                    ?.string || ""
                                                }`}
                                              </MenuItem>
                                            )}
                                          <MenuItem
                                            value={"HeatMap"}
                                            onClick={() => {
                                              setSelectedValue({
                                                ...selectedValue,
                                                projectDetail: "HeatMap",
                                              });

                                              setscbsmbDetail({
                                                block: e?.name,
                                                inverter: ee?.name,
                                                inverterType: ee?.inverterType,
                                                stack: eee?.name,
                                                scbsmb: null,
                                                heatmap: true,
                                                scbsmbType: `${
                                                  projectDetail?.plantDetail
                                                    ?.string || ""
                                                }`,
                                              });
                                              if (
                                                location.pathname !==
                                                PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                              ) {
                                                navigate(
                                                  `${
                                                    PageURL.INDIA_INVERTER_HEATMAP_DIAGRAM
                                                  }?${serachParams.toString()}`
                                                );
                                              }
                                            }}
                                          >
                                            HeatMap
                                          </MenuItem>
                                          {eee?.listScbsmb?.map(
                                            (eeee, index4) => (
                                              <MenuItem
                                                key={index4}
                                                value={eeee}
                                                onClick={() => {
                                                  if (
                                                    selectedValue.projectDetail ===
                                                      eee &&
                                                    scbsmbDetail.block ===
                                                      e?.name &&
                                                    scbsmbDetail.inverter ===
                                                      ee?.name &&
                                                    scbsmbDetail?.stack ===
                                                      eee?.name
                                                  )
                                                    return;
                                                  setSelectedValue({
                                                    ...selectedValue,
                                                    projectDetail: eeee,
                                                  });

                                                  setscbsmbDetail({
                                                    block: e?.name,
                                                    inverter: ee?.name,
                                                    inverterType:
                                                      ee?.inverterType,
                                                    stack: eee?.name,
                                                    scbsmb: eeee,
                                                    scbsmbType: `${
                                                      projectDetail?.plantDetail
                                                        ?.string || ""
                                                    }`,
                                                  });
                                                  if (
                                                    location.pathname !==
                                                    PageURL.SCBSMB_LOSS
                                                  ) {
                                                    navigate(
                                                      `${
                                                        PageURL.SCBSMB_LOSS
                                                      }?${serachParams.toString()}`
                                                    );
                                                  }

                                                  // You can navigate to e.url here
                                                }}
                                              >
                                                {eeee}
                                              </MenuItem>
                                            )
                                          )}
                                        </>
                                      )}
                                  </Box>
                                );
                              })}
                          </>
                        )}
                      </Box>
                    );
                  })}
            </Box>
          );
        })}

      {selectedValue.projectName &&
        projectDetail &&
        projectDetail?.plantDataAvailability?.showLossFlowDiagram && (
          <ListSubheader className="SubHeader last_item">
            {" "}
            <span
              onClick={(event) => {
                event.stopPropagation();
                onclickHandler("Prescritive");
              }}
            >
              Prescriptive Model{" "}
              <i
                className={`fa-solid fa-angle-down ${
                  checkedData.Prescritive ? "active" : ""
                }`}
              ></i>
            </span>
          </ListSubheader>
        )}
      {checkedData.Prescritive &&
        [
          {
            url: PageURL.INDIA_PRES_MODEL_Fault_Tree,
            value: "Fault Tree Diagram",
          },
          {
            url: PageURL.INDIA_PRES_MODEL_Loss_Flow,
            value: "Loss Flow Diagram",
          },
        ].map((e, index) => {
          return (
            <MenuItem
              key={index}
              value={e.url}
              onClick={() => {
                setSelectedValue({
                  ...selectedValue,
                  projectDetail: e.value,
                });
                navigate(`${e.url}?${serachParams.toString()}`);
              }}
            >
              {e.value}
            </MenuItem>
          );
        })}
    </>
  );
};

export default ProjectDetailHeader2;
