import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import SpeedChart from "../../../../components/SpeedChart";
import { saveAs } from "file-saver";
import SelectOptions from "./SelectOptions";
import { Grid } from "@mui/material";

const ActualPrACLineLoss = ({acLineLossActualPr, loading2}) => {
    const  csvDownloadRef=useRef(null)
    const graphRef=useRef(null)

    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
      if (
        acLineLossActualPr &&
        acLineLossActualPr?.length > 0
      ) {
        setSelectedDate(acLineLossActualPr[0]["name"]);
      }
    },[ acLineLossActualPr]);
  return (
    <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    position: "relative",
                    backgroundColor: "#fff",
                    padding: "1vmax",
                    overflow: "auto",
                  }}
                  ref={graphRef}
                >
    <CSVLink
    data={
      acLineLossActualPr?.reduce((acc, curr) => {
      acc.push({
        Date: curr["name"],
        ActualPR: curr["actualPr"],
        AcLoss: curr["acLineLoss"],
      });
      return acc;
    }, []) ||
     []}
    filename="data.csv"
    className="hidden"
    ref={csvDownloadRef}
    target="_blank"
  />
  <div
    style={{
      padding: "0 1.5rem",
      position: "sticky",
      left: "0",
      right: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      zIndex: "1",
    }}
  >
     <div
          style={{
            padding: "0 1.5rem",
            position: "sticky",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <select
            name="level"
            // id="level"
            style={{
              padding: "1vmin 1.5vmin",
              textAlign: "center",
              fontWeight: "bolder",
              borderStyle: "solid",
              borderColor: "#ED7D31",
              borderWidth: "3px",
              fontSize: "1rem",
              backgroundColor: "#edeaea",
            }}
            onChange={(e) => {
            
              setSelectedDate(e.target.value);
            }}
          >
            {/* ###  Monthly Value component for Gaugemeter ### */}

            <SelectOptions
            selectedDate={selectedDate}
              dataArray={
                acLineLossActualPr?.map((e) => e.name) || []
              }
            />
          </select>
        </div>
    <Printer2
      clickhandler={() => csvDownloadRef.current.link.click()}
      jpgDownload={() => {
        if(!graphRef.current)return null
        document.getElementById("hide_content").style.display =
          "none";
        exportComponentAsJPEG(
          graphRef,
          (document.getElementById("greenenco3").style.display =
            "block")
        );
        document.getElementById("greenenco3").style.display =
          "none";
      }}
      svgDownload={async () => {
        if(!graphRef.current) return null
        document.getElementById("hide_content").style.display =
          "none";
        const svgData = await saveToSvg(
          graphRef.current,
          (document.getElementById("greenenco3").style.display =
            "block")
        );
        document.getElementById("greenenco3").style.display =
          "none";

        saveAs(svgData, "gaugemeter.svg");
      }}
    />

   
  </div>
  <div className="speed-chart-container">
    <SpeedChart
      title={`Actual PR (%)`}
      minValue={
       Math.floor( acLineLossActualPr?.reduce((acc,curr)=>
          Math.min(acc,curr["actualPr"]),
          Infinity
        ) )-1
  
      }
      maxValue={
        Math.ceil( acLineLossActualPr?.reduce((acc,curr)=>
          
            Math.max(acc, curr["actualPr"]),
          -Infinity
        ) )+1
        
      }
      value={
       Number( acLineLossActualPr?.find(
          (e) => e.name === selectedDate
        )?.["actualPr"] || 0)
      }
    />
    <SpeedChart
      title={`AC Line Loss (%)`}
      minValue={Math.floor(
        acLineLossActualPr?.reduce(
          (acc, curr, index) =>
             Math.min(acc, curr["acLineLoss"]),
          Infinity
        )
      )-1}
      maxValue={Math.ceil(
        acLineLossActualPr?.reduce(
          (acc, curr, index) =>
             Math.max(acc, curr["acLineLoss"]),
          -Infinity
        )
      )+1}
      value={
       acLineLossActualPr?.find(
          (e) => e.name ===selectedDate
        )?.["acLineLoss"] || 0
      }
    />
  </div>
  <div
    id="greenenco3"
    style={{ position: "relative", display: "none" }}
  >
    <div
      style={{
        position: "absolute",
        bottom: "0",
        right: "0",
        padding: "0 0.5rem 0.5rem 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ fontSize: "1.2rem" }}>&#169;</span>
      <span>GreenEnco</span>
    </div>
  </div></Grid>
  )
}

export default ActualPrACLineLoss