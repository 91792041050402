import React, { useEffect, useRef, useState } from "react";
import { span } from "react-router-dom";
import "./printer.css";
import { useSelector } from "react-redux";

/* ##### Printer Component Which is used to Download Graph  ##### */
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { saveToSvg } from "../constants/Utils";
import { exportComponentAsJPEG } from "react-component-export-image";

const exportToCSV = (data) => {
  const dataSet = data?.data || [],
    name = data?.name || "data";
  if (!dataSet || dataSet.length === 0) return;

  // Collect all unique headers from the dataset
  const headers = Array.from(
    new Set(dataSet.flatMap((item) => Object.keys(item)))
  );

  // Create CSV content
  const csvRows = [];
  csvRows.push(headers.join(",")); // Add headers

  // Add data rows, ensuring all columns are present
  dataSet.forEach((row) => {
    const values = headers.map((header) => row[header] ?? ""); // Fill missing values with an empty string
    csvRows.push(values.join(","));
  });

  // Convert to CSV format
  const csvContent = csvRows.join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `${name}.csv`);
};

const exportToExcel = (data = {}) => {
  const dataSet = data?.data || [],
    name = data?.name || "data";

  if (!dataSet || dataSet.length === 0) return;

  const worksheet = XLSX.utils.json_to_sheet(dataSet);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, name?.substr(0, 30));

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${name}.xlsx`);
};

const exportToJPG = async (data, name) => {
  const fileName = name || "data";
  if (!data) return null;

  // Select all watermarks
  const watermarks = document.querySelectorAll(".watermark-text");
  watermarks.forEach((watermark) => (watermark.style.display = "block")); // Show all watermarks

  const downloadTooltips = document.querySelectorAll(".download-tooltip");
  downloadTooltips.forEach((tooltip) => (tooltip.style.display = "none"));

  exportComponentAsJPEG(data, { fileName: fileName });

  // Hide all watermarks after export
  watermarks.forEach((watermark) => (watermark.style.display = "none"));
  downloadTooltips.forEach((tooltip) => (tooltip.style.display = "block"));
};

const exportToSVG = async (data, name) => {
  const fileName = name || "data";
  if (!data) return null;
  const downloadTooltips = document.querySelectorAll(".download-tooltip");
  downloadTooltips.forEach((tooltip) => (tooltip.style.display = "none"));
  // Select all watermarks
  const watermarks = document.querySelectorAll(".watermark-text");
  watermarks.forEach((watermark) => {
    watermark.style.display = "block";
    watermark.style.position = "absolute";
    watermark.style.bottom = 0;
    watermark.style.right = 0;
    watermark.style.zIndex = 2;
    watermark.style.padding = "0.1rem";
    watermark.style.fontSize = "2vmin";
    watermark.style.fontWeight = "bold";
    watermark.style.color = "rgba(0, 0, 0, 0.6)";
    watermark.style.background = "rgba(255, 255, 255, 0.7)";
    watermark.style.borderRadius = "4px";
  }); // Show all watermarks
  const svgData = await saveToSvg(data || null);

  // Hide all watermarks after export
  watermarks.forEach((watermark) => (watermark.style.display = "none"));
  downloadTooltips.forEach((tooltip) => (tooltip.style.display = "block"));

  saveAs(svgData, `${fileName}.svg`);
};

const Printer2 = ({
  clickhandler,
  jpgDownload,
  svgDownload,
  dataSetDetail,
}) => {
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);

  window.onclick = function () {
    if (open) setOpen(false);
  };

  if (user?.userLevel !== 1) return;

  return (
    <div
      style={{
        position: "absolute",
        right: "0.2rem",
        zIndex: 1,
      }}
      className="download-tooltip"
    >
      <div
        className="print_icon_div"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <i
          style={{
            paddingLeft: "0.3rem",
            marginTop: "0.3rem",
            fontSize: "1.1rem",
            fontWeight: "700",
            cursor: "pointer",
            color: open ? "red" : "black",
          }}
          className="fa-solid fa-bars"
          // className="fa-solid fa-download"
        ></i>
      </div>

      {open && (
        <div className="links_div" onClick={(e) => e.stopPropagation()}>
          <span
            onClick={() => {
              setOpen(false);
              // jpgDownload && jpgDownload();
              exportToJPG(dataSetDetail?.ref, dataSetDetail?.name);
            }}
          >
            Download JPG
          </span>
          <span
            onClick={() => {
              setOpen(false);
              // svgDownload && svgDownload();
              exportToSVG(dataSetDetail?.refCurrent, dataSetDetail?.name);
            }}
          >
            Download SVG
          </span>
          <span
            onClick={() => {
              setOpen(false);
              exportToCSV(dataSetDetail);
            }}
          >
            Download CSV
          </span>
          <span
            onClick={() => {
              setOpen(false);
              exportToExcel(dataSetDetail);
            }}
          >
            Download Excel
          </span>
        </div>
      )}
    </div>
  );
};

export default Printer2;
