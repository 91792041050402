import { Grid } from "@mui/material";
import "./FaultTree.css";
import { useSelector } from "react-redux";
const layerColors = ["#fbdb22", "#ffa907", "#fbd1af", "#befafb", "#8be28b"]; // Define colors for each layer depth
/* ##### FaultTree component  ##### */

const FaultTree = () => {
  const { projectDetail = {} } = useSelector((state) => state.projectDetail);

  return (
    <Grid
      container
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      textAlign={"center"}
    >
      <Grid
        sx={{ boxShadow: 2 }}
        item
        xl={11.8}
        lg={11.8}
        md={11.8}
        sm={11.8}
        xs={11.8}
        style={{
          boxSizing: "border-box",
          borderWidth: "3px",
          // borderRadius: "8px",
          margin: "0.5vmax auto",
          padding: "1vmax",
          background:
            "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          lg={12}
          sx={{
            background: "white",
            padding: "1vmax",
            maxWidth: "100%",
            overflow: "auto",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              color: "black",
              position: "sticky",
              top: "0",
              left: "0",
              right: "0",
            }}
          >
            Fault Tree Analysis
          </h2>
          <div className="scroll-tree-container">
            <div className="tree">
              {treeRendering(projectDetail?.faultTree || [])}
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
const treeRendering = (treeData, depth = 0) => {
  return (
    <>
      <ul>
        {treeData.map((item) => (
          <li className={item.text + item.id}>
            <div
              style={{
                backgroundColor: layerColors[depth % layerColors.length],

                padding: "0.7vmax 0.1vmax",
                width: "5.5vmax",
                height: "3.5vmax",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  fontSize: "0.8vmax",
                }}
              >
                {item.text}
              </span>{" "}
            </div>
            {item.children &&
              item.children.length &&
              treeRendering(item.children, depth + 1)}
          </li>
        ))}
      </ul>
    </>
  );
};

export default FaultTree;
