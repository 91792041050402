import React, { useEffect, useState } from "react";
import "./ProjOverView.css";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAsbuiltDocuments,
  getAsbuiltDocuments,
} from "../../../../actions/energyAction";
import GraphLoader from "../../../../components/GraphLoader";
import axios from "axios";
import {
  clear_errors,
  download_asbuilt_document_reset,
} from "../../../../constants/dataConstants";
import DownloadLoader from "../../../../components/DownloadLoader";
import { toast } from "react-toastify";
import PageURL from "../../../../constants/PageURL";
import { useNavigate } from "react-router-dom";

/* ###  General Details Component  ### */

const AsBuiltDetails = ({ databaseStr }) => {
  const { asbuiltDocument, loading, loading2, downloadAsbuiltDocument, error } =
    useSelector((state) => state.asbuiltDocument);
  const [showModule, setShowModule] = useState(false);
  const [showSLD, setShowSLD] = useState(false);
  const [showCables, setShowCables] = useState(false);

  const [documentDetail, setDocumentDetail] = useState({
    category: "Datasheet",
    name: "Modules",
  });



  /* ###  States to highlight lis  ### */

  const [checkedData, setCheckedData] = useState({
    Datasheet: true,
    Cables: false,
    SLD: false,
    String_Configuration: false,
    Warranty: false,
    PVsyst: false,
    OM_Contract: false,
  });

  const dispatch = useDispatch();
  const navigate=useNavigate()

  /* ###  Change Document path Handler  ### */

  const selectPathHandler = (category, name) => {
    if (name != documentDetail.name) {
      dispatch(getAsbuiltDocuments(category, name, databaseStr));
    }
    setDocumentDetail({ ...documentDetail, category: category, name: name });
  };

  /* ###  To  highlight open lis Handler    ### */

  const changeliHandler = (name) => {
    let newdata = {};
    for (let i in checkedData) {
      if (name === i) newdata[name] = true;
      else newdata[i] = false;
    }
    setCheckedData(newdata);
  };

  /* ###  To  Download Document Handler    ### */

  const downloadDocument = async (fileName) => {
    dispatch(
      downloadAsbuiltDocuments({
        projectName: databaseStr,
        category: documentDetail.category,
        name: documentDetail.name,
        fileName,
        databaseStr: databaseStr,
      })
    );
  };

  useEffect(() => {
    if (databaseStr && !asbuiltDocument) {
      dispatch(
        getAsbuiltDocuments(
          documentDetail.category,
          documentDetail.name,
          databaseStr
        )
      );
    }

    if (downloadAsbuiltDocument) {
      const blob = new Blob([downloadAsbuiltDocument?.data], {
        type: "application/pdf",
      });
      const fileURL = window.URL.createObjectURL(blob);
      const ali = document.createElement("a");
      ali.href = fileURL;
      ali.download = downloadAsbuiltDocument?.fileName || "download.pdf"; // Specify the file name for the download
      document.body.appendChild(ali); // Append anchor to body
      ali.click(); // Trigger download
      document.body.removeChild(ali); // Remove anchor after download
      window.URL.revokeObjectURL(fileURL);
      dispatch({ type: download_asbuilt_document_reset });
    }
    
  }, [downloadAsbuiltDocument, dispatch, databaseStr]);
  return (
    <div className="asbuilt_div">
      <div>
        <span>GreenEnco</span>
        <span>Creating A Better World In A Changing Climate</span>
      </div>
      {loading2 && (
        <div
          style={{
            background: "transparent",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "0",
            bottom: "0",
            zIndex: 1,
          }}
        >
          <DownloadLoader />
        </div>
      )}
      <div style={{ opacity: loading2 ? 0.5 : 1 }}>
        <div>
          <p className="categories">Categories</p>
          <ul>
            <li
              onClick={() => changeliHandler("Datasheet")}
              style={{ color: checkedData.Datasheet ? "red" : "black" }}
              onMouseOver={() => setShowModule(true)}
              onMouseOut={() => setShowModule(false)}
            >
              Datasheet
              <div
                className="module_div"
                style={{
                  width: showModule ? "120%" : "0",
                  padding: showModule ? "0.5vmax 0.8vmax" : "0",
                  boxShadow: showModule ? "1px 1px 1px 2px black" : "none",
                }}
              >
                <li
                  onClick={() => {
                    selectPathHandler("Datasheet", "Modules");
                  }}
                >
                  Modules
                </li>
                <li
                  onClick={() => {
                    selectPathHandler("Datasheet", "Inverter");
                  }}
                >
                  Inverter
                </li>
                <li
                  onClick={() => {
                    selectPathHandler("Datasheet", "Transformer");
                  }}
                >
                  Transformer
                </li>
              </div>
            </li>
            <li
              onClick={() => {
                changeliHandler("Cables");
              }}
              style={{ color: checkedData.Cables ? "red" : "black" }}
              onMouseOver={() => setShowCables(true)}
              onMouseOut={() => setShowCables(false)}
            >
              Cables
              <div
                className="sld_div"
                style={{
                  width: showCables ? "150%" : "0",
                  padding: showCables ? "0.5vmax 1.5vmax" : "0",
                  boxShadow: showCables ? "1px 1px 1px 2px black" : "none",
                }}
              >
                <li
                  onClick={() => {
                    selectPathHandler("Cables", "DC Cables");
                  }}
                >
                  DC SLD
                </li>
                <li
                  onClick={() => {
                    selectPathHandler("Cables", "AC Cables");
                  }}
                >
                  AC SLD
                </li>
              </div>
            </li>
            <li
              onClick={() => changeliHandler("SLD")}
              style={{ color: checkedData.SLD ? "red" : "black" }}
              onMouseOver={() => setShowSLD(true)}
              onMouseOut={() => setShowSLD(false)}
            >
              SLD
              <div
                className="sld_div"
                style={{
                  width: showSLD ? "150%" : "0",
                  padding: showSLD ? "0.5vmax 1.5vmax" : "0",
                  boxShadow: showSLD ? "1px 1px 1px 2px black" : "none",
                }}
              >
                <li
                  onClick={() => {
                    selectPathHandler("SLD", "DC SLD");
                  }}
                >
                  DC SLD
                </li>
                <li
                  onClick={() => {
                    selectPathHandler("SLD", "AC SLD");
                  }}
                >
                  AC SLD
                </li>
              </div>
            </li>
            <li
              onClick={() => {
                selectPathHandler(
                  "String Configuration",
                  "String Configuration"
                );
                changeliHandler("String_Configuration");
              }}
              style={{
                color: checkedData.String_Configuration ? "red" : "black",
              }}
            >
              String Configuration
            </li>
            <li
              style={{ color: checkedData.Warranty ? "red" : "black" }}
              onClick={() => {
                selectPathHandler("Warranty", "Warranty");
                changeliHandler("Warranty");
              }}
            >
              Warranty
            </li>
            <li
              style={{ color: checkedData.PVsyst ? "red" : "black" }}
              onClick={() => {
                selectPathHandler("PVsyst", "PVsyst");

                changeliHandler("PVsyst");
              }}
            >
              PVsyst
            </li>
            <li
              style={{ color: checkedData.OM_Contract ? "red" : "black" }}
              onClick={() => {
                selectPathHandler("OM Contract", "OM Contract");
                changeliHandler("OM_Contract");
              }}
            >
              O&M Contract
            </li>
          </ul>
        </div>
        <div>
          {loading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : asbuiltDocument && asbuiltDocument.length > 0 ? (
            asbuiltDocument.map((el, i) => {
              return (
                <div key={i} style={{ position: "relative" }}>
                  <div>
                    <div style={{ height: "100%", width: "100%" }}>
                      <span style={{ fontSize: "1vmax", fontWeight: "600" }}>
                        Document Type :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {el?.category}
                        </span>{" "}
                      </span>
                      <span style={{ fontSize: "1vmax", fontWeight: "600" }}>
                        Document Name :{" "}
                      </span>
                      <span
                        style={{
                          wordBreak: "break-word",
                          textAlign: "center",
                          fontSize: "1vmax",
                          padding: "0 0.5vmax",
                          fontWeight: "lighter",
                        }}
                      >
                        {el.fileName}
                      </span>
                    </div>
                    <div
                      id={el.fileType != "pdf" ? "excel_button" : "pdf_button"}
                      style={{
                        background:
                          el.fileType != "pdf" ? "#05c059" : "rgb(248, 95, 65)",
                      }}
                      onClick={() => downloadDocument(el?.fileName)}
                    >
                      <li style={{ color: "black", listStyle: "none" }}>
                        Download
                      </li>{" "}
                      In
                      <i
                        className={`fa-solid fa-file-${
                          el.fileType != "pdf" ? "excel" : "pdf"
                        }`}
                      ></i>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <h1 style={{ fontSize: "1.5vmax" }}> Data Not Available </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AsBuiltDetails;
