import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allowUser,
  deleteUser,
  registredUsers,
} from "../../../../actions/userActions";
import SpinLoader from "../../../../components/SpinLoader";
import {
  allow_user_reset,
  clear_errors,
  delete_user_reset,
} from "../../../../constants/dataConstants";
import { toast } from "react-toastify";
import "./AdminDashboard.css";

/* ### Admin Component ### */

const AdminDashBoard = () => {
  const { registred_users=[], loading, error } = useSelector(
    (state) => state.registred_users
  );
  const { isAllowed, isDeleted, loading2 } = useSelector(
    (state) => state.allow_user
  );
  const dispatch = useDispatch();

  /* ### Allow Registred User Handler ### */

  const SentEmailTouser = (email) => {
    dispatch(allowUser(email));
  };

  /* ### Delete Registred User Handler ### */

  const DeleteUser = (email) => {
    dispatch(deleteUser(email));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: clear_errors });
    }
    if (isAllowed) {
      dispatch({ type: allow_user_reset });

      toast.success("User allowed and mail sent to the user regarding login");
    dispatch(registredUsers());

      return () => undefined;
    }
    if (isDeleted) {
      dispatch({ type: delete_user_reset });
      toast.success("User deleted sucessfully!");
    dispatch(registredUsers());

      return () => undefined;

    }
    
  }, [dispatch, isAllowed, isDeleted, error]);
  
  useEffect(()=>{
    if(!registred_users){
      dispatch(registredUsers());
      }

  },[registred_users])

  return (
    <>
      {
      loading 
      ? 
      (
        <SpinLoader />
      ) :
       (
        <div
          style={{
            height: "100%",
            padding: "2vmax 1vmax",
            gap: "1vmax",
            width: "100%",
            overflow:"auto",
          }}
        >
          <h3 style={{ textAlign: "center" }}>All Registered Users</h3>
          { registred_users?.length > 0 ? (
            <table
              className="user-table"
              style={{
                backgroundColor: loading2 ? "whitesmoke" : "white",
                opacity: loading2 ? 0.3 : 1,
                width: "100%"
              }}
            >
              <tr>
                <th>SL. Number</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email id</th>
                <th> Mobile Number</th>
                <th>Level Requested</th>
                <th> Action</th>
              </tr>
              {registred_users.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: !(index & 1) ? "whitesmoke" : "white",
                      padding: "5vmax",
                    }}
                  >
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>{item.FirstName}</td>

                    <td style={{ textAlign: "center" }}>{item.LastName}</td>

                    <td style={{ textAlign: "center" }}>{item.EmailID}</td>
                    <td style={{ textAlign: "center" }}>{item.MobileNumber}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.LevelPermission}
                    </td>
                    <td>
                      <div style={{}} className="user-action-buttons">
                        <span onClick={() => SentEmailTouser(item.EmailID)}>
                          Accept
                        </span>
                        <span onClick={() => DeleteUser(item.EmailID)}>
                          Reject
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : (
            <h2 style={{textAlign:"center"}}>No User Registred Yet</h2>
          )}
        </div>
      )}
    </>
  );
};

export default AdminDashBoard;
