import {
  allow_user_fail,
  allow_user_request,
  allow_user_reset,
  allow_user_sucess,
  clear_errors,
  delete_user_fail,
  delete_user_request,
  delete_user_reset,
  delete_user_sucess,
  get_user_fail,
  get_user_request,
  get_user_reset,
  get_user_sucess,
  inverter_mappingData_fail,
  inverter_mappingData_request,
  inverter_mappingData_sucess,
  inverter_specification_fail,
  inverter_specification_request,
  inverter_specification_sucess,
  load_user_fail,
  load_user_request,
  load_user_sucess,
  project_detail_fail,
  project_detail_request,
  project_detail_reset,
  project_detail_sucess,
  redirect_user_to_homepage,
  registerd_user_fail,
  registerd_user_request,
  registerd_user_sucess,
  resend_otp_fail,
  resend_otp_request,
  resend_otp_reset,
  resend_otp_sucess,
  scbSmb_currentDetail_fail,
  scbSmb_currentDetail_request,
  scbSmb_currentDetail_sucess,
  scbSmb_mappingData_fail,
  scbSmb_mappingData_request,
  scbSmb_mappingData_sucess,
  send_otp_fail,
  send_otp_request,
  send_otp_reset,
  send_otp_sucess,
  user_login_fail,
  user_login_request,
  user_login_reset,
  user_login_sucess,
  user_logout_fail,
  user_logout_request,
  user_logout_reset,
  user_logout_sucess,
  user_session_expired,
  user_signup_fail,
  user_signup_request,
  user_signup_reset,
  user_signup_sucess,
  verify_otp_fail,
  verify_otp_request,
  verify_otp_reset,
  verify_otp_sucess,
} from "../constants/dataConstants";

export const user = (state = { user: null }, action) => {
  switch (action.type) {
    case user_signup_request:
    case user_login_request:
    case user_logout_request:
    case send_otp_request:
    case verify_otp_request:
    case resend_otp_request:
      return {
        ...state,
        loading: true,
      };
    case get_user_request:
      return {
        ...state,
        loading3: true,
        existUser: false,
      };
    case load_user_request:
      return {
        ...state,
        loadingUser: true,
      };

    case send_otp_sucess: {
      return {
        ...state,
        loading: false,
        isSendOtp: action.payload,
      };
    }
    case resend_otp_sucess: {
      return {
        ...state,
        loading: false,
        isResendOtp: action.payload,
      };
    }
    case verify_otp_sucess: {
      return {
        ...state,
        loading: false,
        isVerifiedOtp: action.payload,
      };
    }
    case user_signup_sucess:
      return {
        ...state,
        loading: false,
        sucess: action.payload,
      };

    case user_logout_sucess:
      return {
        ...state,
        loading: false,
        isLogout: action.payload,
      };
    case load_user_sucess:
      return {
        ...state,
        user: action.payload,
        loading: false,
        loadingUser: false,
      };

    case get_user_sucess:
      return {
        ...state,
        loading: false,
        user: action.payload,
        loading3: false,
        existUser: true,
      };

    case user_login_sucess:
      return {
        ...state,
        loading: false,
        isLogin: action.payload,
      };
    case user_session_expired:
      return {
        ...state,
        user: null,
        error: action.payload,
        loading: false,
      };
    case user_signup_fail:
    case user_login_fail:
    case user_logout_fail:
    case send_otp_fail:
    case verify_otp_fail:
    case resend_otp_fail:
      return {
        ...state,
        user: null,
        loading: false,
        isLogin: false,
        error: action.payload,
      };

    case get_user_fail:
      return {
        ...state,
        user: null,
        loading3: false,
        existUser: false,
        error: action.payload,
      };

    case load_user_fail:
      return {
        ...state,
        user: null,
        error: null,
        loading: false,
        loadingUser: false,
      };
    //  case redirect_user_to_homepage:
    //   return{
    //     ...state,
    //       user:null,
    //       error:null,
    //   }
    case user_signup_reset:
      return {
        ...state,
        loading: false,
        sucess: false,
      };
    case user_login_reset:
      return {
        ...state,
        loading: false,
        isLogin: false,
      };
    case get_user_reset:
      return {
        ...state,
        loading3: false,
        existUser: false,
      };
    case user_logout_reset:
      return {
        ...state,
        loading: false,
        isLogout: false,
      };
    case send_otp_reset: {
      return {
        ...state,
        loading: false,
        isSendOtp: false,
      };
    }

    case resend_otp_reset: {
      return {
        ...state,
        loading: false,
        isResendOtp: false,
      };
    }

    case verify_otp_reset: {
      return {
        ...state,
        loading: false,
        isVerifiedOtp: false,
      };
    }

    case clear_errors:
      return {
        ...state,
        loading: false,
        loading2: false,
        error2: null,
        error: null,
      };
    default:
      return state;
  }
};

export const projectDetail = (
  state = {
    projectDetail: null,
    scbSmbMappingArray: null,
    inverterMappingArray: null,
    scbSmbCurrentArray: null,
    inverterSpecificationArray: null,
  },
  action
) => {
  switch (action.type) {
    case project_detail_request:
      return {
        ...state,
        loading: true,
        availableProject: false,
      };

    case inverter_mappingData_request:
      return {
        ...state,
        inverterMappingLoader: true,
      };

    case scbSmb_mappingData_request:
      return {
        ...state,
        scbSmbMappingLoader: true,
      };

    case scbSmb_currentDetail_request:
      return {
        ...state,
        scbSmbCurrentLoader: true,
      };

    case inverter_specification_request:
      return {
        ...state,
        inverterSpecificationLoader: true,
      };

    case project_detail_sucess:
      return {
        ...state,
        projectDetail: action.payload,
        loading: false,
        availableProject: true,
      };

    case inverter_mappingData_sucess:
      return {
        ...state,
        inverterMappingLoader: false,
        inverterMappingArray: action.payload,
      };
    case scbSmb_mappingData_sucess:
      return {
        ...state,
        scbSmbMappingArray: action.payload,
        scbSmbMappingLoader: false,
      };
    case scbSmb_currentDetail_sucess:
      return {
        ...state,
        scbSmbCurrentArray: action.payload,
        scbSmbCurrentLoader: false,
      };

    case inverter_specification_sucess:
      return {
        ...state,
        inverterSpecificationLoader: false,
        inverterSpecificationArray: action.payload,
      };
    case project_detail_fail:
      return {
        ...state,
        projectDetail: null,
        error2: action.payload,
        loading: false,
        availableProject: false,
      };
    case inverter_mappingData_fail:
      return {
        ...state,
        inverterMappingArray: null,
        inverterMappingLoader: false,
        inverterMappingArrayError: action.payload,
      };
    case scbSmb_mappingData_fail:
      return {
        ...state,
        scbSmbMappingArray: null,
        scbSmbMappingArrayError: action.payload,
        inverterMappingLoader: false,
      };

    case scbSmb_currentDetail_fail:
      return {
        ...state,
        scbSmbCurrentArray: null,
        scbSmbCurrentArrayError: action.payload,
        scbSmbCurrentLoader: false,
      };
    case inverter_specification_fail:
      return {
        ...state,
        inverterSpecificationLoader: false,
        inverterSpecificationArray: null,
        inverterSpecificationArrayError: action.payload,
      };

    case project_detail_reset:
      return {
        ...state,
        availableProject: false,
      };
    case clear_errors:
      return {
        ...state,
        loading: false,
        inverterMappingLoader: false,
        scbSmbMappingArrayLoader: false,
        error: null,
        inverterMappingArrayError: null,
        scbSmbMappingArrayError: null,
      };
    default:
      return state;
  }
};

export const registred_users = (state = { registred_users: null }, action) => {
  switch (action.type) {
    case registerd_user_request:
      return {
        ...state,
        loading: true,
      };
    case registerd_user_sucess:
      return {
        ...state,
        loading: false,
        registred_users: action.payload,
      };

    case registerd_user_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case clear_errors:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const allow_user = (state = { allow_user: null }, action) => {
  switch (action.type) {
    case allow_user_request:
    case delete_user_request:
      return {
        ...state,
        loading2: true,
      };
    case allow_user_sucess:
      return {
        ...state,
        loading2: false,
        isAllowed: action.payload,
      };
    case delete_user_sucess:
      return {
        ...state,
        loading2: false,
        isDeleted: action.payload,
      };

    case allow_user_fail:
    case delete_user_fail:
      return {
        ...state,
        loading2: false,
        error: action.payload,
      };
    case allow_user_reset:
      return {
        ...state,
        loading2: false,
        isAllowed: false,
      };

    case delete_user_reset:
      return {
        ...state,
        loading2: false,
        isDeleted: false,
      };
    case clear_errors:
      return {
        ...state,
        loading2: false,
        error: null,
      };
    default:
      return state;
  }
};
