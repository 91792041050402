import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";


import { useSelector } from "react-redux";
import SmbStringLoss from "./SmbStringLoss";
import ScbSmbLoss from "./ScbSmbLoss";

const SCBSMB = ({
  selectedValue,
  setSelectedValue,
  selectedLebel,
  scbsmbDetail,
  setscbsmbDetail,
  sestSelectedLebel,
  databaseStr
}) => {
  const [title, setTitle] = useState(null);


  return (
    <Grid
      container
      xl={12}
      
    >
      <Grid
        item
        xl={11.8}
        lg={11.8}
        md={11.8}
        sm={11.8}
        xs={11.8}
       
        // borderRadius={"14px"}
        style={{
          background:
            "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          margin: "0vmax auto 1vmax",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1vmax",
        }}
      >
        {/*  ###   SMB (String) Average Yearly Loss Graph Container   ### */}

        <Grid
        item
      
        xs={12}
          style={{
            background: "white",
            padding: "0.5vmax 1vmax",
            position: "relative",
            overflow: "auto"
          }}
        >
          <ScbSmbLoss
          databaseStr={databaseStr}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            selectedLebel={selectedLebel}
            scbsmbDetail={scbsmbDetail}
            sestSelectedLebel={sestSelectedLebel}
            title={title}
            setTitle={setTitle}
          />
        </Grid>
      </Grid>
      {/*  ###   SMB (String) Average Monthly Loss Graph Container   ### */}
{
  scbsmbDetail?.scbsmbType==="SMB" &&
      <Grid
        item
        xl={11.8}
        lg={11.8}
        md={11.8}
        sm={11.8}
        xs={11.8}
        // borderRadius={"14px"}
        style={{
          background:
            "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          margin: "0vmax auto 1vmax",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1vmax",
        }}
      >
        <Grid
        item
          lg={12}
          style={{
            background: "white",
            // borderRadius: "14px",
            padding: "0.5vmax 1vmax",
            position: "relative",
            width: "100%",
            overflow: "auto",
          }}
        >
          <SmbStringLoss scbsmbDetail={scbsmbDetail} title={title} />
        </Grid>
      </Grid>
}
    </Grid>
  );
};

export default SCBSMB;
